import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MdDashboard, MdManageAccounts } from "react-icons/md";
import { AiOutlineFileSearch } from "react-icons/ai";
import { BsClipboardCheck } from "react-icons/bs";
import { FaUsers } from "react-icons/fa6";
import { FaListCheck, FaUserTie } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Tooltip } from 'antd';
import { MdOutlineMailOutline } from "react-icons/md";
import { GiProgression } from "react-icons/gi";

export const Sidebar = ({ empPosition, toggleDrawer, isRegistered, isDrawerOpen }) => {
  const location = useLocation();

  return (
    <aside
      className={
        "left-0 top-0 z-[9999] flex h-screen flex-col overflow-y-hidden duration-300 ease-linear dark:bg-boxdark static translate-x-0"
      } style={{ backgroundColor: '#6c5295' }}
    >
      <header className="gap-2 px-4 py-6">
        <div className={`${isDrawerOpen ? 'block' : 'hidden'} `}>
          <div className="flex items-center justify-between">
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '28px',
            }}>
              <img className='w-32' src='/jetz-logo.png' /></div>
            <IoIosArrowBack
              className="text-xl font-semibold text-white cursor-pointer"
              onClick={toggleDrawer}
            />
          </div>
        </div>
        <div className={`${!isDrawerOpen ? 'block' : 'hidden'} `}>
          <div className="flex items-center justify-between">
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '28px',
            }}>
              <img className='w-[2.5rem]' src='/rhombus-jetz.png' /></div>
            <IoIosArrowForward
              className="text-xl font-semibold text-white cursor-pointer"
              onClick={toggleDrawer}
            />
          </div>
        </div>
      </header>
      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        <nav className="py-4 px-4 lg:px-2">
          <div>
            <div className={`${isDrawerOpen ? 'block' : 'hidden'} `}>
              <ul className="mb-6 flex flex-col gap-1.5">
                <li className="text-white">
                  <NavLink
                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/dashboard") ? "bg-graydark" : ""
                      }`}
                    to="/dashboard"
                  >
                    <MdDashboard />
                    <span style={{ fontSize: '14px' }}>Dashboard</span>
                  </NavLink>
                </li>
                {!!empPosition && (empPosition === "Talent Team" || empPosition === "Management Team") &&
                  <li className="text-white">
                    <NavLink
                      className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/taskboard") ? "bg-graydark" : ""
                        }`}
                      to="/taskboard"
                    >
                      <BsClipboardCheck />
                      <span style={{ fontSize: '14px' }}> Task Board</span>
                    </NavLink>
                  </li>
                }
                <li className="text-white">
                  <NavLink
                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/requirement") ? "bg-graydark" : ""
                      }`}
                    to="/requirementdashboard"
                  >
                    <FaListCheck />
                    <span style={{ fontSize: '14px' }}>Requirement</span>
                  </NavLink>
                </li>
                <li className="text-white">
                  <NavLink
                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/candidate") ? "bg-graydark" : ""
                      }`}
                    to="/candidatedashboard"
                  >
                    <FaUsers />
                    <span style={{ fontSize: '14px' }}>Candidate</span>
                  </NavLink>
                </li>
                {!!empPosition && empPosition === "Management Team" &&
                  <li className="text-white">
                    <NavLink
                      className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/employeedashboard") ? "bg-graydark" : ""
                        }`}
                      to="/employeedashboard"
                    >
                      <MdManageAccounts />
                      <span style={{ fontSize: '14px' }}>Employees</span>
                    </NavLink>
                  </li>
                }
                {/* <li className="text-white">
                  <NavLink
                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${!isRegistered ? '' : 'hidden'} ${location.pathname.includes("/employee") ? "bg-graydark" : ""
                      }`}
                    to="/employee"
                  >
                    <FaUserTie />
                    <span style={{ fontSize: '14px' }}>Employee</span>
                  </NavLink>
                </li> */}
                <li className="text-white">
                  <NavLink
                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/reports") ? "bg-graydark" : ""
                      }`}
                    to="/reports"
                  >
                    <AiOutlineFileSearch />
                    <span style={{ fontSize: '14px' }}>Reports</span>
                  </NavLink>
                </li>
                <li className="text-white">
                  <NavLink
                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/email") ? "bg-graydark" : ""
                      }`}
                    to="/email"
                  >
                    <MdOutlineMailOutline />
                    <span style={{ fontSize: '14px' }}>Email</span>
                  </NavLink>
                </li>
                {!!empPosition && empPosition === "Management Team" &&
                  <li className="text-white">
                    <NavLink
                      className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/dailyTracker") ? "bg-graydark" : ""
                        }`}
                      to="/dailyTracker"
                    >
                      <GiProgression />
                      <span style={{ fontSize: '14px' }}>Daily Tracker</span>
                    </NavLink>
                  </li>
                }
              </ul>
            </div>
            <div className={`${!isDrawerOpen ? 'block' : 'hidden'} `}>
              <ul className="mb-6 flex flex-col gap-1.5">
                <li className="text-white">
                  <Tooltip title="Dashboard" placement="right">
                    <NavLink
                      className={`justify-center group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/dashboard") ? "bg-graydark" : ""
                        }`}
                      to="/dashboard"
                    >
                      <MdDashboard style={{ fontSize: '20px' }} />
                    </NavLink>
                  </Tooltip>
                </li>
                {!!empPosition && (empPosition === "Talent Team" || empPosition === "Management Team") &&
                  <li className="text-white">
                    <Tooltip title="Task Board" placement="right">
                      <NavLink
                        className={`justify-center group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/taskboard") ? "bg-graydark" : ""
                          }`}
                        to="/taskboard"
                      >
                        <BsClipboardCheck style={{ fontSize: '20px' }} />
                      </NavLink>
                    </Tooltip>
                  </li>
                }
                <li className="text-white">
                  <Tooltip title="Requirement" placement="right">
                    <NavLink
                      className={`justify-center group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/requirement") ? "bg-graydark" : ""
                        }`}
                      to="/requirementdashboard"
                    >
                      <FaListCheck style={{ fontSize: '20px' }} />
                    </NavLink>
                  </Tooltip>
                </li>
                <li className="text-white">
                  <Tooltip title="Candidate" placement="right">
                    <NavLink
                      className={`justify-center group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/candidate") ? "bg-graydark" : ""
                        }`}
                      to="/candidatedashboard"
                    >
                      <FaUsers style={{ fontSize: '20px' }} />
                    </NavLink>
                  </Tooltip>
                </li>
                {!!empPosition && empPosition === "Management Team" &&
                  <li className="text-white">
                    <Tooltip title="Employees" placement="right">
                      <NavLink
                        className={`justify-center group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/employeedashboard") ? "bg-graydark" : ""
                          }`}
                        to="/employeedashboard"
                      >
                        <MdManageAccounts style={{ fontSize: '20px' }} />
                      </NavLink>
                    </Tooltip>
                  </li>
                }
                <li className="text-white">
                  <Tooltip title="Employee" placement="right">
                    <NavLink
                      className={`justify-center group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${!isRegistered ? '' : 'hidden'} ${location.pathname.includes("/employee") ? "bg-graydark" : ""
                        }`}
                      to="/employee"
                    >
                      <FaUserTie style={{ fontSize: '20px' }} />
                    </NavLink>
                  </Tooltip>
                </li>
                <li className="text-white">
                  <Tooltip title="Reports" placement="right">
                    <NavLink
                      className={`justify-center group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/reports") ? "bg-graydark" : ""
                        }`}
                      to="/reports"
                    >
                      <AiOutlineFileSearch style={{ fontSize: '20px' }} />
                    </NavLink>
                  </Tooltip>
                </li>
                <li className="text-white">
                  <Tooltip title="Email" placement="right">
                    <NavLink
                      className={`justify-center group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/email") ? "bg-graydark" : ""
                        }`}
                      to="/email"
                    >
                      <MdOutlineMailOutline style={{ fontSize: '20px' }} />
                    </NavLink>
                  </Tooltip>
                </li>
                {!!empPosition && empPosition === "Management Team" &&
                  <li className="text-white">
                    <Tooltip title="Logs" placement="right">
                      <NavLink
                        className={`justify-center group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${isRegistered ? '' : 'hidden'} ${location.pathname.includes("/dailyTracker") ? "bg-graydark" : ""
                          }`}
                        to="/dailyTracker"
                      >
                        <GiProgression style={{ fontSize: '20px' }} />
                      </NavLink>
                    </Tooltip>
                  </li>
                }
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </aside>
  );
};
