import React, { useState, useEffect } from "react";
import { Button, notification } from "antd";
import { IoMdPersonAdd } from "react-icons/io";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { FaListCheck } from "react-icons/fa6";
import { BsFillClipboard2DataFill } from "react-icons/bs";
import { GiDiscussion } from "react-icons/gi";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { TbDiscountCheckFilled } from "react-icons/tb";
import { MdOutlineRefresh } from "react-icons/md";
import { FaPaperPlane } from "react-icons/fa";
import { staffingUrl } from "../interceptor";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";

const initialDateRange = {
  startDate: null,
  endDate: null,
};

export const Dashboard = () => {
  const [api, contextHolder] = notification.useNotification();
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();

  const [isloading, setIsloading] = useState(false);
  const [count, setCount] = useState([]);
  const [unseenNotification, setUnseenNotification] = useState([]);
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [isBtnClicked, setIsBtnClicked] = useState("all");
  const [vendorCount, setvendorCount] = useState([]);
  const [openingsVendorCount,setOpeningsVendorCount] = useState([]);

  const GetTableData = async (startDate, endDate) => {
    setIsloading(true);
    await staffingUrl
      .get(`api/StaffMgnt/getDashboardInfo/0/${!!startDate ? startDate : "all"}/${!!endDate ? endDate : "all"}`)
      .then((res) => {
        setCount(res.data);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });

    await staffingUrl
      .get(`api/StaffMgnt/getBillableCandidatesAndVendors`)
      .then((res) => {
        setvendorCount(res.data);
      })
      .catch((err)=> {
        console.log(err);
      })

    await staffingUrl
    .get(`api/StaffMgnt/getVendorBasedOpeningsCount`)
    .then((res)=> {
      setOpeningsVendorCount(res.data);
    })
    .catch((err)=> {
      console.log(err);
    })
  };

  const GetEmployeeNotification = async (empid) => {
    //passing value for empid,comment_statuss as "sent" and req_id as 0 to only get notification.
    await staffingUrl
      .get(`api/StaffMgnt/getEmployeeDiscussionsonRequirement/${empid}/sent/0`)
      .then((res) => {
        setUnseenNotification(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
        setUnseenNotification([]);
      });
  };

  const filterRequirement = (value, key, fromKey) => {
    navigate("/requirementdashboard", {
      state: {
        filterValue: value,
        filterKey: key,
        dateRange,
        fromKey
      },
    });
  };

  const filterCandidate = (value, key, fromKey) => {
    navigate("/candidatedashboard", {
      state: {
        filterValue: value,
        filterKey: key,
        dateRange,
        fromKey
      },
    });
  };

  const Loader = <TailSpin visible={true} height="30" width="50" radius="1" />;

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setDateRange({
        startDate: dateStrings[0],
        endDate: dateStrings[1]
      });
    } else {
      setDateRange(initialDateRange);
    }
  };

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    GetTableData(startDate, endDate);
  }, [dateRange]);

  return (
    <div className="pt-5">
      {contextHolder}
      <h4 class="text-[17px] font-normal">Dashboard</h4>
      <div className="flex items-center py-3 gap-3 justify-between">
        <div className="flex gap-2 items-center">
          <Button
            className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'today' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
            onClick={() => {
              onRangeChange(
                [dayjs().startOf("day"), dayjs().endOf("day")],
                [
                  dayjs().startOf("day").format("YYYY-MM-DD"),
                  dayjs().endOf("day").format("YYYY-MM-DD"),
                ]
              );
              setIsBtnClicked('today');
            }}
          >
            Today
          </Button>
          <Button
            className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'yesterday' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
            onClick={() => {
              onRangeChange(
                [
                  dayjs().subtract(1, "day").startOf("day"),
                  dayjs().subtract(1, "day").endOf("day"),
                ],
                [
                  dayjs()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("YYYY-MM-DD"),
                  dayjs().subtract(1, "day").endOf("day").format("YYYY-MM-DD"),
                ]
              );
              setIsBtnClicked('yesterday');
            }}
          >
            Yesterday
          </Button>
          <Button
            className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'thisweek' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
            onClick={() => {
              onRangeChange(
                [dayjs().startOf("week"), dayjs().endOf("day")],
                [
                  dayjs().startOf("week").format("YYYY-MM-DD"),
                  dayjs().endOf("day").format("YYYY-MM-DD"),
                ]
              );
              setIsBtnClicked('thisweek');
            }}
          >
            This Week
          </Button>
          <Button
            className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'lastweek' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
            onClick={() => {
              onRangeChange(
                [
                  dayjs().subtract(1, "week").startOf("week"),
                  dayjs().subtract(1, "week").endOf("week"),
                ],
                [
                  dayjs()
                    .subtract(1, "week")
                    .startOf("week")
                    .format("YYYY-MM-DD"),
                  dayjs()
                    .subtract(1, "week")
                    .endOf("week")
                    .format("YYYY-MM-DD"),
                ]
              );
              setIsBtnClicked('lastweek');
            }}
          >
            Last Week
          </Button>
          <Button
            className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'thismonth' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
            onClick={() => {
              onRangeChange(
                [dayjs().startOf("month"), dayjs().endOf("day")],
                [
                  dayjs().startOf("month").format("YYYY-MM-DD"),
                  dayjs().endOf("day").format("YYYY-MM-DD"),
                ]
              );
              setIsBtnClicked('thismonth');
            }}
          >
            This Month
          </Button>
          <Button
            className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'lastmonth' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
            onClick={() => {
              onRangeChange(
                [
                  dayjs().subtract(1, "month").startOf("month"),
                  dayjs().subtract(1, "month").endOf("month"),
                ],
                [
                  dayjs()
                    .subtract(1, "month")
                    .startOf("month")
                    .format("YYYY-MM-DD"),
                  dayjs()
                    .subtract(1, "month")
                    .endOf("month")
                    .format("YYYY-MM-DD"),
                ]
              );
              setIsBtnClicked('lastmonth');
            }}
          >
            Last Month
          </Button>
          <Button
            className={`text-[12px] font-normal text-[#fff] pt-[3px] rounded-none ${isBtnClicked !== 'all' ? 'bg-[#6c5295]' : ' bg-[white] text-[black]'}`}
            onClick={() => {
              setDateRange(initialDateRange);
              setIsBtnClicked("all");
            }}
          >
            All
            {/* <MdOutlineRefresh /> */}
          </Button>
        </div>
        <div>
          <Space direction="vertical" size={12}>
            <RangePicker
              defaultValue={[null]}
              allowClear={false}
              value={[
                !!dateRange.startDate
                  ? dayjs(dateRange.startDate)
                  : null,
                !!dateRange.endDate
                  ? dayjs(dateRange.endDate)
                  : null
              ]}
              onChange={onRangeChange}
            // presets={rangePresets}
            //className="rounded-full"
            />
          </Space>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-3 mb-5">
        <div>
          <div className="grid grid-cols-3 mb-2 items-center"
            style={{ border: "1px solid #ddd", cursor: "pointer" }}
            onClick={() => {
              filterRequirement("", "", "Openings");
            }}
          >
            <div className="col-span-2">
              <div className="flex justify-between ps-5	pe-5 bg-[#fff] items-center py-1" style={{ borderRight: '1px solid #ddd' }}>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#67748e",
                    textTransform: "Capitalize",
                    fontWeight: "400",
                  }}
                >
                  Openings
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    color: "#344767",
                    fontWeight: "600",
                    margin: "0",
                  }}
                >
                  {isloading
                    ? Loader
                    :  (count?.requirement_details?.req_count || 0)
                  }
                </p>
              </div>
              <table className="openings-count-table">
                <thead>
                  <tr>
                    {openingsVendorCount.vendorBasedOpeningsCount?.map((item, index) => (
                      index < 7 ? 
                      (<th key={index}>{item.vendor?.slice(0, 3)?.toUpperCase()}</th>) :("")
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr style={{textAlign:'center'}}>
                    {openingsVendorCount.vendorBasedOpeningsCount?.map((item, index) => (
                       index < 7 ? 
                      (<td key={index}>{item.count}</td>) : ("")
                    ))}
                  </tr>
                </tbody>
              </table>

            </div>
            <div className="flex items-center gap-1 top-[15px] left-[10px] relative" style={{ border: '1px solid #0e890e', borderRadius: '5px', padding: '5px 8px 3px', background: ' #82f28245', marginBottom: '28px', width: 'max-content' }}>
              <p className="text-[11px] text-[#0e890e]">High priority</p>
              <span className="text-[11px] text-[#0e890e]">:</span>
              <p className="text-[11px] text-[#0e890e]">{isloading
                ? Loader
                : (count?.highPriorityDetails?.high_priority_req_count || 0)
              }</p>
            </div>

          </div>
          <div
            className="grid grid-cols-2 gap-0"
            style={{ boxShadow: "0 1px 20px 0 rgba(69, 90, 100, 0.08)" }}
          >
            <div className="flex flex-col gap-0">
              {/* <div
                className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterRequirement(
                    count?.highPriorityDetails?.high_priority_req,
                    "high_priority_req",
                    "High priority"
                  );
                }}
              >
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    High priority
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.highPriorityDetails?.high_priority_req_count || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.highPriorityDetails?.high_priority_req_count /
                        count?.requirement_details?.req_count
                      ) * 100
                        ? 0
                        : (
                          (count?.highPriorityDetails
                            ?.high_priority_req_count /
                            count?.requirement_details?.req_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div> */}
              <div
                className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterRequirement(
                    count?.requirement_details.ongoing_req,
                    "ongoing_req",
                    "Ongoing"
                  );
                }}
              >
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    On going
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.ongoing_count || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details?.ongoing_count /
                        count?.requirement_details?.req_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details?.ongoing_count /
                            count?.requirement_details?.req_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterRequirement(
                    count?.requirement_details.hold_count_req,
                    "hold_count_req",
                    "Hold"
                  );
                }}
              >
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    Hold
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.hold_count || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details?.hold_count /
                        count?.requirement_details?.req_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details?.hold_count /
                            count?.requirement_details?.req_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-0">
              <div
                className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterRequirement(
                    count?.requirement_details.yettostart_req,
                    "yettostart_req",
                    "Not started"
                  );
                }}
              >
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    Not started
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.yettostart_count || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details?.yettostart_count /
                        count?.requirement_details?.req_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details?.yettostart_count /
                            count?.requirement_details?.req_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterRequirement(
                    count?.requirement_details.hold_count_req,
                    "hold_count_req",
                    "Hold"
                  );
                }}
              >
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    Closed
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.closed_count || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details?.hold_count /
                        count?.requirement_details?.req_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details?.hold_count /
                            count?.requirement_details?.req_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="flex justify-between bg-[#fff] items-center mb-2"
            style={{ padding: "25px 20px", border: "1px solid #ddd", cursor: "pointer" }}
            onClick={() => {
              filterCandidate(
                count?.requirement_details.submission_req,
                "submitted_req",
                "Submission"
              );
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#67748e",
                textTransform: "Capitalize",
                fontWeight: "400",
              }}
            >
              Submission
            </p>
            <p
              style={{
                fontSize: "20px",
                color: "#344767",
                fontWeight: "600",
                margin: "0",
              }}
            >
              {isloading
                ? Loader
                : (count?.requirement_details?.submission_count || 0)
              }
            </p>
          </div>
          <div
            className="grid grid-cols-2 gap-0"
            style={{ boxShadow: "0 1px 20px 0 rgba(69, 90, 100, 0.08)" }}
          >
            <div className="flex flex-col gap-0">
              <div
                className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterCandidate(
                    count?.requirement_details.interview_req,
                    "interview_req",
                    "Interview"
                  );
                }}
              >
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    Interviewed
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.interview_count || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details?.interview_count /
                        count?.requirement_details?.submission_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details?.interview_count /
                            count?.requirement_details?.submission_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterCandidate(
                    count?.requirement_details.onboard_req,
                    "onboard_req",
                    "Onboard"
                  );
                }}
              >
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    Onboarded
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.onboard_count || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details?.onboard_count /
                        count?.requirement_details?.submission_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details?.onboard_count /
                            count?.requirement_details?.submission_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-0">
              <div
                className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterCandidate(
                    count?.requirement_details.selected_req,
                    "selected_req",
                    "Selected"
                  );
                }}
              >
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    Selected
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.selected_count || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details?.selected_count /
                        count?.requirement_details?.submission_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details?.selected_count /
                            count?.requirement_details?.submission_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterCandidate(
                    count?.requirement_details?.rejected_req,
                    "rejected_req",
                    "Rejected"
                  );
                }}
              >
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    Rejected
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.rejected_count || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details?.rejected_count /
                        count?.requirement_details?.submission_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details?.rejected_count /
                            count?.requirement_details?.submission_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="grid grid-cols-3 items-center bg-[#fff]" style={{ border: "1px solid #ddd", cursor: "pointer" }}
            onClick={() => {
              filterCandidate(
                count?.requirement_details.billable_candidate_req,
                "billable_candidate_req",
                "Billings"
              );
            }}
          >
            <div className="col-span-2">
              <div className="flex justify-between ps-5	pe-5 bg-[#fff] items-center py-1" style={{ borderRight: '1px solid #ddd' }}>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#67748e",
                    textTransform: "Capitalize",
                    fontWeight: "400",
                  }}
                >
                  Billings
                </p>

                <p
                  style={{
                    fontSize: "20px",
                    color: "#344767",
                    fontWeight: "600",
                    margin: "0",
                  }}
                >
                  {isloading
                    ? Loader
                    : (count?.candidateDetails?.Billable_candidate_count || 0)
                  }
                </p>
              </div>
              <table className="billing-count-table">
                <thead>
                  <tr>
                    <th>1 M</th>
                    <th>3 M</th>
                    <th>6 M</th>
                    <th>9 M</th>
                    <th>1 Y</th>
                    <th>1+ Y</th>
                  </tr>
                </thead>
                <tbody>
                  {!!vendorCount.duration &&
                    <tr style={{textAlign:'center'}}>
                      <td>{vendorCount.duration["1_Month"]}</td>
                      <td>{vendorCount.duration["3_Month"]}</td>
                      <td>{vendorCount.duration["6_Month"]}</td>
                      <td>{vendorCount.duration["9_Month"]}</td>
                      <td>{vendorCount.duration["1_Year"]}</td>
                      <td>{vendorCount.duration["1_Plus_Year"]}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            <div >
              <div className="text-center" style={{ borderBottom: '1px solid #ddd' }}>
                <p className="text-[13px] text-[#67748e] p-[8.5px]">Expiry</p>
              </div>
              <div className="grid grid-cols-2" style={{ borderBottom: '1px solid #ddd' }}>
                <p className="text-[13px] text-[#67748e] text-center p-[0px]" style={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd' }}><span className="text-[12px]">30</span> days</p>
                <p className="text-[13px] text-[#67748e] text-center p-[0px]"><span className="text-[12px]">60</span> days</p>
              </div>
              <div className="grid grid-cols-2">
                <p className="text-[13px] text-[#67748e] text-center p-[0px]" style={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd' }}>  {isloading
                  ? Loader
                  : (count?.requirement_details?.candidatebillexpiredin_30days || 0)
                }</p>
                <p className="text-[13px] text-[#67748e] text-center p-[0px]"> {isloading
                  ? Loader
                  : (count?.requirement_details?.candidatebillexpiredin_60days || 0)
                }</p>
              </div>
            </div>

          </div>
          {/* <div
            className="grid grid-cols-2 gap-0"
            style={{ boxShadow: "0 1px 20px 0 rgba(69, 90, 100, 0.08)",marginTop:'10px' }}
          >
            <div className="flex flex-col gap-0">
              <div
                className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterCandidate(
                    count?.requirement_details.candidatebillexpiredin_30days_req,
                    "candidatebillexpiredin_30days_req",
                    "Expiry 30 days"
                  );
                }}
              >
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    Expiry 30 days
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.candidatebillexpiredin_30days || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details
                          ?.candidatebillexpiredin_30days /
                        count?.candidateDetails?.Billable_candidate_count
                      ) * 100
                        ? 0
                        : count
                          ?.candidateDetails
                          ?.Billable_candidate_count === 0
                          ? 0
                          : (
                            (count?.requirement_details
                              ?.candidatebillexpiredin_30days /
                              count?.candidateDetails
                                ?.Billable_candidate_count) *
                            100
                          ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-0">
            
              <div
                className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterCandidate(
                    count?.requirement_details.candidatebillexpiredin_60days_req,
                    "candidatebillexpiredin_60days_req",
                    "Expiry 60 days"
                  );
                }}
              >
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    Expiry 60 days
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.candidatebillexpiredin_60days || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details
                          ?.candidatebillexpiredin_60days /
                        count?.candidateDetails?.Billable_candidate_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details
                            ?.candidatebillexpiredin_60days /
                            count?.candidateDetails
                              ?.Billable_candidate_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="mt-2 vendor_table_wrapper">
            <div>
              <table className="vendor_table">
                <thead>
                  <tr>
                    <th>Vendor</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {vendorCount.candidateDetails?.map((value, index) => (
                    <tr key={index}>
                      <td>{value.vendor}</td>
                      <td>{value.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div
            className="flex justify-between bg-[#fff] items-center mb-2"
            style={{ padding: "25px 20px", border: "1px solid #ddd", cursor: "pointer" }}
            onClick={() => {
              filterCandidate("", "", "Talents");
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#67748e",
                textTransform: "Capitalize",
                fontWeight: "400",
              }}
            >
              Talents
            </p>
            <p
              style={{
                fontSize: "20px",
                color: "#344767",
                fontWeight: "600",
                margin: "0",
              }}
            >
              {isloading
                ? Loader
                : (count?.candidateDetails?.candidate_count || 0)
              }
            </p>
          </div>
          <div
            className="grid grid-cols-2 gap-0"
            style={{ boxShadow: "0 1px 20px 0 rgba(69, 90, 100, 0.08)" }}
          >
            <div className="flex flex-col gap-0">
              <div className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterCandidate(
                    count?.requirement_details.candidate_internal_req, "candidate_internal_req",
                    "Internal"
                  );
                }}>
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    Internal
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.candidate_internal || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details?.candidate_internal /
                        count?.candidateDetails?.candidate_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details?.candidate_internal /
                            count?.candidateDetails?.candidate_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
              <div className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterCandidate(
                    count?.requirement_details.candidate_subcontract_req,
                    "candidate_subcontract_req",
                    "Sub-contract"
                  );
                }}>
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    Sub-contract
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.candidate_subcontract || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details?.candidate_subcontract /
                        count?.candidateDetails?.candidate_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details?.candidate_subcontract /
                            count?.candidateDetails?.candidate_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-0">
              <div className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterCandidate(
                    count?.requirement_details.candidate_external_req,
                    "candidate_external_req",
                    "External"
                  );
                }}>
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    External
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.candidate_external || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details?.candidate_external /
                        count?.candidateDetails?.candidate_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details?.candidate_external /
                            count?.candidateDetails?.candidate_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
              <div className="Main-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  filterCandidate(
                    count?.requirement_details.candidate_fresher_req,
                    "candidate_fresher_req",
                    "Fresher"
                  );
                }}>
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      color: "#67748e",
                      textTransform: "Capitalize",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    Fresher
                  </h6>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#344767",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {isloading
                      ? Loader
                      : (count?.requirement_details?.candidate_fresher || 0)
                    }
                  </p>
                  <div
                    className="percentage-wrap"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p className="percentage-text">
                      {isNaN(
                        count?.requirement_details?.candidate_fresher /
                        count?.candidateDetails?.candidate_count
                      ) * 100
                        ? 0
                        : (
                          (count?.requirement_details?.candidate_fresher /
                            count?.candidateDetails?.candidate_count) *
                          100
                        ).toFixed(0)}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <div
            className="flex justify-between bg-[#fff] items-center mb-2 w-[49.3%]"
            style={{ padding: "25px 20px", border: "1px solid #ddd", cursor: "pointer" }}
            onClick={() => {
              filterCandidate(
                count?.requirement_details.priortize_candidate_req,
                "priortize_candidate_req",
                "Ready to Marketing"
              );
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#67748e",
                textTransform: "Capitalize",
                fontWeight: "400",
              }}
            >
              Ready to Market
            </p>
            <p
              style={{
                fontSize: "20px",
                color: "#344767",
                fontWeight: "600",
                margin: "0",
              }}
            >
              {isloading
                ? Loader
                : (count?.requirement_details?.priortize_candidate_count || 0)
              }
            </p>
          </div>
          <div
            className="flex flex-col gap-0"
            style={{ boxShadow: "0 1px 20px 0 rgba(69, 90, 100, 0.08)" }}
          >
            <div className="Job-table_wraper">
              <table className="Job-table">
                <thead>
                  <tr className="job">
                    <th>TECHNOLOGY</th>
                    <th >0-3 y</th>
                    <th >4-6 y</th>
                    <th >7-9 y</th>
                    <th >10+ y</th>
                  </tr>
                </thead>
                <tbody>
                  {isloading
                    ? (
                      <tr>
                        <td colSpan={5}>
                          <div className="flex justify-center">
                            {Loader}
                          </div>
                        </td>
                      </tr>
                    )
                    : (
                      count?.SkillsSummary?.map((skill, index) => (
                        <tr className="job" key={index}>
                          <td>{skill.skill}</td>
                          <td>{skill.yoe_0_3_years}</td>
                          <td>{skill.yoe_4_6_years}</td>
                          <td>{skill.yoe_7_9_years}</td>
                          <td>{skill.yoe_10_plus_years}</td>
                        </tr>
                      ))
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
