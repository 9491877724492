import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  InputNumber,
  DatePicker,
  Form,
  Button,
  Upload,
  notification,
  Breadcrumb,
  Divider,
  Space,
  message,
  Checkbox,
} from "antd";
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import { Modal, Typography } from "@mui/material";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { staffingUrl } from "../interceptor";
import { Country, State, City } from "country-state-city";
import { useMsal } from "@azure/msal-react";
import "../index.css";
import { logger } from "../utils/logger";
import { FiPlusCircle } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Tooltip } from 'antd'
import axios from "axios";
import { ImCancelCircle } from "react-icons/im";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const work_mode = [
  {
    value: "On-Site",
    label: "On-Site",
  },
  {
    value: "Hybrid",
    label: "Hybrid",
  },
  {
    value: "Remote",
    label: "Remote",
  },
];

const Source = [
  {
    value: "Internal",
    label: "Internal",
  },
  {
    value: "WalkIn",
    label: "WalkIn",
  },
  {
    value: "LinkedIn",
    label: "LinkedIn",
  },
  {
    value: "Naukri",
    label: "Naukri",
  },
  {
    value: "Reference",
    label: "Reference",
  },
];

dayjs.extend(customParseFormat);

export default function Candidate() {
  const [api, contextHolder] = notification.useNotification();
  const { instance } = useMsal();
  const userName = instance.getActiveAccount()["username"];
  const name = instance.getActiveAccount()["name"];
  const navigate = useNavigate();
  const { id } = useParams();
  const locations = useLocation();

  const { candidateEmails, resumeResponseObj, } = locations.state || {};

  const [CandidateForm] = Form.useForm();
  const [addSkillForm] = Form.useForm();
  const [addCustomerForm] = Form.useForm();

  const [employeeType, setEmployeeType] = useState([]);
  const [employeeStatusList, setEmployeeStatusList] = useState([]);
  const [employeeStatusOptions, setEmployeeStatusOptions] = useState([]);
  const [location, setLocation] = useState([]);
  const [skillSet, setSkillset] = useState([]);
  const [noticePeriod, setNoticePeriod] = useState("");
  const [resumefromResponse, setResumefromResponse] = useState("");
  const [resumeURL, setResumeURL] = useState("");
  const [isUploading, setIsUploading] = useState(false)
  const [country, setCountry] = useState(Country.getAllCountries());
  const [state, setState] = useState(State.getAllStates());
  const [city, setCity] = useState(City.getAllCities());
  const [stateFilterd, setFilterStates] = useState([]);
  const [cityFilterd, setFilterCity] = useState([]);
  const [prefStateFilterd, setPrefFilterStates] = useState([]);
  const [prefCityFilterd, setprefFilterCity] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeStatus, setEmployeeStatus] = useState(null);
  const [employeeTypeField, setEmployeeTypeField] = useState("");
  const [customerName, setCustomerName] = useState([]);
  const [idFromDB, setIdFromDB] = useState(null);
  const [isPrioritized, setIsPrioritized] = useState(false);
  const [openToRelocate, setOpenToRelocate] = useState(false);
  const [addBtnClicked, setAddBtnClicked] = useState(false);
  const [rows, setRows] = useState([]);
  const [comparisonData, setComparisonData] = useState({});
  const [requirementList, setRequirementList] = useState([]);
  const [country_code, setCountry_Code] = useState("+91");
  const [phoneNumLength, setPhoneNumLength] = useState(10);
  const [startDateSelected, setStartDateSelected] = useState({});
  const [isShowUploadFile, setIsShowUploadFile] = useState(false);

  const countryCode = ["+91", "+1", "+44", "+52", "+86", "+61"];

  const openNotificationWithIcon = (req) => {
    api[req?.color]({
      message: req?.Title,
      description: req?.description,
      duration: req?.duration,
    });
  };

  const modifyKeys = (key) => {
    return key === "fname"
      ? "First Name"
      : key === "lname"
        ? "Last Name"
        : key === "email_id"
          ? "E-mail"
          : key === "contact_no"
            ? "Contact Number"
            : key === "Aadhaar_id"
              ? "Aadhar ID"
              : key === "country"
                ? "Country"
                : key === "state"
                  ? "State"
                  : key === "city"
                    ? "City"
                    : key === "year" || key === "month"
                      ? "YOE"
                      : key === "current_ctc"
                        ? "Current CTC"
                        : key === "expected_ctc"
                          ? "Expected CTC"
                          : key === "Skill_set"
                            ? "Skill Set"
                            : key === "work_mode"
                              ? "Work Mode"
                              : key === "source"
                                ? "Source"
                                : key === "Preferredcountry"
                                  ? "Preferred Country"
                                  : key === "Preferredstate"
                                    ? "Preferred State"
                                    : key === "Preferredcity"
                                      ? "Preferred City"
                                      : key === "Employee_Type"
                                        ? "Employee Type"
                                        : key === "employee_status"
                                          ? "Employee Status"
                                          : key === "is_priority"
                                            ? "Priority"
                                            : key === "Previous_Company_details"
                                              ? "Previous Company Details"
                                              : key === "billable_in_req"
                                                ? "Billable in Req"
                                                : key === "open_to_relocate"
                                                  ? "Open To Relocate"
                                                  : key === "vendor"
                                                    ? "Vendor"
                                                    : key === "end_client"
                                                      ? "End Client"
                                                      : key === "invoice_date"
                                                        ? "Invoice Date"
                                                        : key === "end_date"
                                                          ? "End Date"
                                                          : key === "ratecard"
                                                            ? "Rate Card"
                                                            : key === "non_billable_type"
                                                              ? "Non Billable Type"
                                                              : key === "release_date"
                                                                ? "Release Date"
                                                                : key === "is_enabled"
                                                                  ? "Active Status"
                                                                  : key
  }

  const getCustomer = async () => {
    await staffingUrl
      .get(`api/StaffMgnt/getcustomer`)
      .then((res) => {
        setCustomerName(res.data);
      })
      .catch((err) => console.error(err));
  };

  const addCustomer = async (value) => {
    await staffingUrl
      .post(
        `api/StaffMgnt/addCustomer?customer=${value.customerName}&username=${userName}`
      )
      .then((res) => {
        if (res.data.status == 1) {
          openNotificationWithIcon({
            color: "success",
            Title: "Success",
            description: "The Customer is Added successfully",
            duration: 2,
          });
          addCustomerForm.resetFields();
          getCustomer();
        } else if (res.data.status == 2) {
          addCustomerForm.resetFields();
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: `${value.customerName} is already exist`,
            duration: 2,
          });
        } else {
          addCustomerForm.resetFields();
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "The Customer is not Added",
            duration: 2,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  const addNewCustomer = (menu) => (
    <>
      {menu}
      <Divider
        style={{
          margin: "8px 0",
        }}
      />
      <Space
        style={{
          padding: "0 8px 4px",
        }}
      >
        <Form form={addCustomerForm} onFinish={addCustomer}>
          <Form.Item
            name="customerName"
            rules={[
              {
                required: true,
                message: "Please enter your customer name",
              },
            ]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Input
                placeholder="Please enter item"
                className="w-full"
                size="middle"
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button htmlType="submit" icon={<PlusOutlined />}>
                Add customer
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Space>
    </>
  );

  const FilterState = (val, key) => {
    let countryname = country.filter((a) =>
      typeof val === "string"
        ? val.split("-")[0] === a.name
        : val?.map((a) => a.split("-")[0]).includes(a.name)
    );
    let temp = state.filter((a) =>
      countryname.some((b) => b.isoCode === a?.countryCode)
    );
    key === "location" ? setFilterStates(temp) : setPrefFilterStates(temp);
    if (key === "location") setFilterCity([]);

    let countryField = CandidateForm.getFieldValue('Preferredcountry')
    if (countryField?.length === 0) {
      CandidateForm.setFieldValue('Preferredstate', null)
      CandidateForm.setFieldValue('Preferredcity', null)
    }
  };

  const FilterCities = (val, key) => {
    let statename = state.filter((a) =>
      typeof val === "string"
        ? val?.split("-")[0] === a.name
        : val?.map((a) => a.split("-")[0]).includes(a.name)
    );
    let temp = city.filter((a) =>
      statename.some(
        (b) => b.isoCode === a?.stateCode && b.countryCode === a?.countryCode
      )
    );
    key === "location" ? setFilterCity(temp) : setprefFilterCity(temp);

    let stateField = CandidateForm.getFieldValue('Preferredstate')
    if (stateField?.length === 0) {
      CandidateForm.setFieldValue('Preferredcity', null)
    }
  };

  const getData = async () => {
    await staffingUrl
      .get(`api/StaffMgnt/getEmployeeType`)
      .then((res) => {
        setEmployeeType(res.data);
      })
      .catch((err) => console.error(err));
    await staffingUrl
      .get(`api/StaffMgnt/getEmployeeStatus`)
      .then((res) => {
        setEmployeeStatusList(res.data);
      })
      .catch((err) => console.error(err));
    await staffingUrl
      .get(`api/StaffMgnt/getLocationList`)
      .then((res) => {
        setLocation(res.data);
      })
      .catch((err) => console.error(err));
    getSkillset();
  };

  const getCandidateInfo = async (id) => {
    await staffingUrl
      .get(`api/StaffMgnt/getCandidateInfo?candidate_id=${id}`)
      .then(async (res) => {
        await staffingUrl
          .get(`api/StaffMgnt/getVendorAssociatedRequirements/${res?.data?.vendor?.split("-")[0]}`)
          .then((res) => {
            setRequirementList(res.data.requirements);
          })
          .catch((err) => console.error(err));

        if (res.data?.Previous_Company_Details?.length > 0) {
          setAddBtnClicked(true);
          res?.data?.Previous_Company_Details?.forEach((data, index) => {
            CandidateForm.setFieldsValue({
              [`prev_company_name_${index + 1}`]: data.prev_comp_name,
              [`prev_role_${index + 1}`]: data.prev_role,
              [`prev_start_date_${index + 1}`]: dayjs(data.prev_comp_startdate),
              [`prev_end_date_${index + 1}`]: dayjs(data.prev_comp_enddate),
              [`prev_yoe_${index + 1}`]: data.prev_yoe,
              [`prev_id_${index + 1}`]: data?.id
            });
            setStartDateSelected(prevState => ({
              ...prevState,
              [index + 1]: !!data.prev_comp_startdate
            }));
            setRows(prevState => [...prevState, index + 1]);
          });
        }

        let test = res?.data?.year_of_exp.split(" ");
        let yoe_start = undefined;
        let yoe_end = undefined;

        if (test.length == 4) {
          yoe_start = `${res?.data?.year_of_exp.split(" ")[0]} ${res?.data?.year_of_exp.split(" ")[1]
            }`;
          yoe_end = `${res?.data?.year_of_exp.split(" ")[2]} ${res?.data?.year_of_exp.split(" ")[3]
            }`;
        } else if (test[1].includes("Year")) {
          yoe_start = `${res?.data?.year_of_exp.split(" ")[0]} ${res?.data?.year_of_exp.split(" ")[1]
            }`;
        } else if (test[1].includes("Month")) {
          yoe_end = `${res?.data?.year_of_exp.split(" ")[0]} ${res?.data?.year_of_exp.split(" ")[1]
            }`;
        }

        const contactNo =
          res?.data?.contact_no.split(countryCode.filter(item => res?.data?.contact_no.startsWith(item))[0]).length > 1
            ? res?.data?.contact_no.split(countryCode.filter(item => res?.data?.contact_no.startsWith(item))[0])[1]
            : res?.data?.contact_no.split(countryCode.filter(item => res?.data?.contact_no.startsWith(item))[0])[0];

        CandidateForm.setFieldValue("fname", res?.data?.firstname);
        CandidateForm.setFieldValue("lname", res?.data?.lastname);
        CandidateForm.setFieldValue("Aadhaar_id", res?.data?.Aadhaar_id);
        CandidateForm.setFieldValue("email_id", res?.data?.email_id);
        CandidateForm.setFieldValue("contact_no", contactNo);
        CandidateForm.setFieldValue("city", res?.data?.city || null);
        CandidateForm.setFieldValue("state", res?.data?.state || null);
        CandidateForm.setFieldValue("country", res?.data?.country || null);
        CandidateForm.setFieldValue("Employee_Type", res?.data?.employee_type);
        CandidateForm.setFieldValue(
          "employee_status",
          res?.data?.employee_status
        );
        CandidateForm.setFieldValue("vendor", res?.data?.vendor);
        CandidateForm.setFieldValue("end_client", res?.data?.end_client);
        CandidateForm.setFieldValue(
          "invoice_date",
          res?.data?.invoice_date !== null
            ? dayjs(res?.data?.invoice_date)
            : undefined
        );
        CandidateForm.setFieldValue(
          "end_date",
          res?.data?.end_date !== null ? dayjs(res?.data?.end_date) : undefined
        );
        CandidateForm.setFieldValue("ratecard", res?.data?.ratecard);
        CandidateForm.setFieldValue(
          "non_billable_type",
          res?.data?.non_billable_type
        );
        CandidateForm.setFieldValue(
          "release_date",
          res?.data?.release_date !== null
            ? dayjs(res?.data?.release_date)
            : undefined
        );
        CandidateForm.setFieldValue(
          "current_ctc",
          res?.data?.current_ctc === "-" ? "-" : res?.data?.current_ctc
        );
        CandidateForm.setFieldValue("expected_ctc", res?.data?.expected_ctc);
        CandidateForm.setFieldValue("source", res?.data?.source);
        CandidateForm.setFieldValue("year", yoe_start);
        CandidateForm.setFieldValue("month", yoe_end);
        CandidateForm.setFieldValue("work_mode", res?.data?.work_mode);
        CandidateForm.setFieldValue("is_active", res?.data?.is_enabled)
        CandidateForm.setFieldValue("is_priority", res?.data?.is_priority === 1 ? true : false);
        CandidateForm.setFieldValue("open_to_relocate", res?.data?.open_to_relocate === 1 ? true : false);
        CandidateForm.setFieldValue("notice_period", res?.data?.notice_period);
        CandidateForm.setFieldValue(
          "lastworkingday",
          res?.data?.lwd != null ? dayjs(res?.data?.lwd) : undefined
        );
        CandidateForm.setFieldValue(
          "Preferredcity",
          res?.data?.preferred_city?.split(",").map((a) => a)
        );
        CandidateForm.setFieldValue(
          "Preferredstate",
          res?.data?.preferred_state?.split(",").map((a) => a)
        );
        CandidateForm.setFieldValue(
          "Preferredcountry",
          res?.data?.preferred_country?.split(",").map((a) => a)
        );
        CandidateForm.setFieldValue(
          "Skill_set",
          res?.data?.skill_set?.split(",")
            .filter((skill) => skill.trim() !== "").length > 0 ? (res?.data?.skill_set?.split(",")?.map((a) => a)) : undefined
        );
        CandidateForm.setFieldValue(
          "openings",
          res?.data?.billable_in_req !== '0-'
            ? res?.data?.billable_in_req
            : null
        );
        CandidateForm.setFieldValue("Resume", res?.data?.resume);
        // CandidateForm.setFieldValue(
        //   "location",
        //   res?.data?.location.split(',').map(a => a)
        // );

        const exportedFilename = res?.data?.resume?.split("/")?.pop();

        setResumeURL(res?.data?.resume);
        setResumefromResponse(exportedFilename);
        setNoticePeriod(res?.data?.notice_period);
        setIsPrioritized(res?.data?.is_priority === 1 ? true : false);
        setOpenToRelocate(res?.data?.open_to_relocate === 1 ? true : false);
        setEmployeeTypeField(res?.data?.employee_type);
        setEmployeeStatus(res?.data?.employee_status);
        setCountry_Code(
          countryCode.filter(item =>
            res?.data?.contact_no.startsWith(item))[0]
          || "+91"
        );

        FilterState(res?.data?.country, "location");
        FilterCities(res?.data?.state, "location");
        FilterState(res?.data?.preferred_country?.split(','), "preferred");
        FilterCities(res?.data?.preferred_state?.split(','), "preferred");
        const transformedObj = Object.fromEntries(Object.entries(res.data).map(([key, value]) => {
          switch (key) {
            case "year_of_exp":
              return ["yoe", value];
            case "firstname":
              return ["fname", value];
            case "lastname":
              return ["lname", value];
            case "Aadhaar_id":
              return ["Aadhaar_id", value]
            case "skill_set":
              return ["Skill_set", value]
            case "preferred_city":
              return ["Preferredcity", value]
            case "preferred_state":
              return ["Preferredstate", value]
            case "preferred_country":
              return ["Preferredcountry", value]
            case "Employee_Type":
              return ["Employee_Type", value]
            case "invoice_date":
              return ["invoice_date", value?.split('T')[0]]
            case "end_date":
              return ["end_date", value?.split('T')[0]]
            case "release_date":
              return ["release_date", value?.split('T')[0] || undefined]
            case "city":
              return ["city", value || undefined]
            case "state":
              return ["state", value || undefined]
            case "country":
              return ["country", value || undefined]
            case "non_billable_type":
              return ["non_billable_type", value || undefined]
            case "vendor":
              return ["vendor", value.split('-')[1]]
            case "billable_in_req":
              return ["billable_in_req", !!value ? parseInt(value.split('-')[0]) : 0]
            case "expected_ctc":
              return ["expected_ctc", !!value ? parseInt(value) : 0]
            case "current_ctc":
              return ["current_ctc", !!value ? parseInt(value) : 0]
            case "is_priority":
              return ["is_priority", !!value ? parseInt(value) : 0]
            case "Previous_Company_Details":
              const filteredData = value.reduce((acc, obj) => {
                acc.push({
                  prev_id: obj.id,
                  prev_comp_name: obj.prev_comp_name,
                  prev_role: obj.prev_role,
                  prev_comp_startdate: obj.prev_comp_startdate,
                  prev_comp_enddate: obj.prev_comp_enddate,
                  prev_yoe: obj.prev_yoe
                });
                return acc;
              }, []);
              return ["Previous_Company_details", filteredData]
            default:
              return [key, value];
          }
        }))
        setComparisonData(transformedObj);
      })
      .catch((err) => console.log(err));
  };

  const dateCheck = (values) => {
    if (values.values.year == undefined && values.values.month == undefined) {
      CandidateForm.setFields([
        {
          name: "month",
          errors: ["Please select your month of experience"],
        },
      ]);
      CandidateForm.setFields([
        {
          name: "year",
          errors: ["Please select your year of experience"],
        },
      ]);

      return;
    }
  };

  const CandidateFormSubmit = async (values, key) => {
    const prevCompData = rows.map((item) => {
      return {
        prev_companyName: CandidateForm.getFieldValue(`prev_company_name_${item}`),
        prev_role: CandidateForm.getFieldValue(`prev_role_${item}`),
        prev_startDate: CandidateForm.getFieldValue(`prev_start_date_${item}`) ? dayjs(CandidateForm.getFieldValue(`prev_start_date_${item}`)).format('YYYY/MM/DD') : '',
        prev_endDate: CandidateForm.getFieldValue(`prev_end_date_${item}`) ? dayjs(CandidateForm.getFieldValue(`prev_end_date_${item}`)).format('YYYY/MM/DD') : '',
        prev_yoe: CandidateForm.getFieldValue(`prev_yoe_${item}`),
      }
    });

    const prevCompLogData = rows.map((item) => {
      return {
        prev_id: CandidateForm.getFieldValue(`prev_id_${item}`),
        prev_companyName: CandidateForm.getFieldValue(`prev_company_name_${item}`),
        prev_role: CandidateForm.getFieldValue(`prev_role_${item}`),
        prev_startDate: CandidateForm.getFieldValue(`prev_start_date_${item}`) ? dayjs(CandidateForm.getFieldValue(`prev_start_date_${item}`)).format('YYYY/MM/DD') : '',
        prev_endDate: CandidateForm.getFieldValue(`prev_end_date_${item}`) ? dayjs(CandidateForm.getFieldValue(`prev_end_date_${item}`)).format('YYYY/MM/DD') : '',
        prev_yoe: CandidateForm.getFieldValue(`prev_yoe_${item}`),
      }
    });

    setIsLoading(true);
    let yoe = "";
    if (values.year == undefined && values.month == undefined) {
      CandidateForm.setFields([
        {
          name: "month",
          errors: ["Please select your month of experience"],
        },
      ]);
      CandidateForm.setFields([
        {
          name: "year",
          errors: ["Please select your year of experience"],
        },
      ]);
      setIsLoading(false);
      return;
    } else {
      if (values.year != undefined && values.month != undefined) {
        yoe = `${values.year} ${values.month}`;
      } else if (values.year != undefined) {
        yoe = `${values.year}`;
      } else if (values.month != undefined) {
        yoe = `${values.month}`;
      }
    }
    if (values?.Resume?.fileList?.length == 0) {
      CandidateForm.setFields([
        {
          name: "Resume",
          errors: ["Please upload your resume"],
        },
      ]);
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    let details =
      key === "add"
        ? {
          fname: values?.fname,
          lname: values?.lname,
          yoe: yoe,
          employee_type: values?.Employee_Type,
          employee_status: values?.employee_status,
          vendor: values?.vendor?.split('-')[1],
          billable_in_req: values?.openings?.split('-')[0] || 0,
          end_client: values?.end_client,
          invoice_date: values?.invoice_date
            ? dayjs(values?.invoice_date).format("YYYY-MM-DD")
            : "",
          end_date: values?.end_date
            ? dayjs(values?.end_date).format("YYYY-MM-DD")
            : "",
          ratecard: values?.ratecard,
          non_billable_type: values?.non_billable_type,
          release_date: values?.release_date
            ? dayjs(values?.release_date).format("YYYY-MM-DD")
            : "",
          aadhar_id: values?.Aadhaar_id,
          city: values?.city?.toString(),
          state: values?.state?.toString(),
          country: values?.country?.toString(),
          skill_set: values?.Skill_set?.toString(),
          work_mode: values?.work_mode,
          notice_period: values?.notice_period,
          location: "",
          preferred_city: values?.Preferredcity?.toString(),
          preferred_state: values?.Preferredstate?.toString(),
          preferred_country: values?.Preferredcountry?.toString(),
          email_id: values?.email_id,
          contact_no: `${country_code}${values?.contact_no}`,
          current_ctc: values?.current_ctc || 0,
          expected_ctc: values?.expected_ctc || 0,
          source: values?.source,
          is_priority: values?.is_priority ? 1 : 0,
          open_to_relocate: values?.open_to_relocate ? 1 : 0,
          is_enabled: values?.is_active,
          last_working_day:
            values?.notice_period === "Serving Notice Period"
              ? dayjs(values?.lastworkingday).format("YYYY-MM-DD")
              // new Date(values?.lastworkingday)?.toDateString()
              : "",
          Previous_Company_details: prevCompData.map((item) => (
            {
              prev_comp_name: item.prev_companyName,
              prev_comp_startdate: item.prev_startDate,
              prev_comp_enddate: item.prev_endDate,
              prev_yoe: item.prev_yoe,
              prev_role: item.prev_role
            }
          ))
        }
        : {
          candidate_id: parseInt(id) || parseInt(idFromDB),
          fname: values?.fname,
          lname: values?.lname,
          yoe: yoe,
          employee_type: values?.Employee_Type,
          employee_status: values?.employee_status,
          vendor: values?.vendor?.split('-')[1],
          billable_in_req: values?.openings?.split('-')[0] || 0,
          end_client: values?.end_client,
          invoice_date: values?.invoice_date
            ? dayjs(values?.invoice_date).format("YYYY-MM-DD")
            : "",
          end_date: values?.end_date
            ? dayjs(values?.end_date).format("YYYY-MM-DD")
            : "",
          ratecard: values?.ratecard,
          non_billable_type: values?.non_billable_type,
          release_date: values?.release_date
            ? dayjs(values?.release_date).format("YYYY-MM-DD")
            : undefined,
          aadhar_id: values?.Aadhaar_id,
          city: values?.city?.toString(),
          state: values?.state?.toString(),
          country: values?.country?.toString(),
          skill_set: values?.Skill_set?.toString(),
          work_mode: values?.work_mode,
          notice_period: values?.notice_period,
          location: "",
          preferred_city: values?.Preferredcity?.toString(),
          preferred_state: values?.Preferredstate?.toString(),
          preferred_country: values?.Preferredcountry?.toString(),
          email_id: values?.email_id,
          contact_no: `${country_code}${values?.contact_no}`,
          current_ctc: values?.current_ctc || 0,
          expected_ctc: values?.expected_ctc || 0,
          source: values?.source,
          is_priority: values?.is_priority ? 1 : 0,
          open_to_relocate: values?.open_to_relocate ? 1 : 0,
          is_enabled: values?.is_active,
          last_working_day:
            values?.notice_period === "Serving Notice Period"
              ? dayjs(values?.lastworkingday).format("YYYY-MM-DD")
              : "",
          Previous_Company_details: prevCompData.map((item) => (
            {
              prev_comp_name: item.prev_companyName,
              prev_comp_startdate: item.prev_startDate,
              prev_comp_enddate: item.prev_endDate,
              prev_yoe: item.prev_yoe,
              prev_role: item.prev_role
            }
          )
          )
        };
    formData.append("details", JSON.stringify(details));
    formData.append("username", userName);
    if (typeof values?.Resume == "object") {
      formData.append("fakepath", values?.Resume.file.name);  
      formData.append("resume", values?.Resume.file, values?.Resume.file.name);
    }
    if (!!resumeResponseObj) {
      formData.append("fakepath", resumeResponseObj.resume.name);
      formData.append("resume", resumeResponseObj.resume, resumeResponseObj.resume.name);
    }
    await staffingUrl
      .post(
        key === "add"
          ? `api/StaffMgnt/addcandidate`
          : `api/StaffMgnt/updateCandidate`,
        formData
      )
      .then((res) => {
        if (res.data.status === 1) {
          details = {
            ...details,
            Previous_Company_details: prevCompLogData?.map((item) => ({
              prev_id: item?.prev_id,
              prev_comp_name: item.prev_companyName,
              prev_comp_startdate: item.prev_startDate,
              prev_comp_enddate: item.prev_endDate,
              prev_yoe: item.prev_yoe,
              prev_role: item.prev_role
            }))
          };

          setIdFromDB(null);
          key === "add" && setIsPrioritized(false);
          key === "add" && setOpenToRelocate(false);
          key === "add" && CandidateForm.resetFields();
          key === "add" && setAddBtnClicked(false);

          openNotificationWithIcon({
            color: "success",
            Title: "Success",
            description: `Candidate Information ${key === "add" ? "Added" : "Updated"} Successfully`,
            duration: 2,
          });

          if (key === "add") {
            const addPayload = {
              candidate_id: res.data.candidate_id,
              actionEvent: "Candidate Created",
              changedBy: userName,
              username: name,
              occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
              changes: [
                {
                  content: `${values?.fname} ${values?.lname} created by ${name}`,
                  candidateData: details,
                  candidateName: `${values?.fname} ${values?.lname}`,
                }
              ]
            };

            logger(addPayload);
          } else {
            const modifiedCompData = {};

            Object.keys(comparisonData).forEach(key => {
              if (details.employee_status === "Billable") {
                if (!(key === "non_billable_type"
                  || key === "release_date")) {
                  modifiedCompData[key] = comparisonData[key];
                }
              } else if (details.employee_status === "Non-Billable") {
                if (!(key === "vendor"
                  || key === "invoice_date"
                  || key === "end_client"
                  || key === "end_date"
                  || key === "ratecard"
                  || key === "billable_in_req"
                )) {
                  modifiedCompData[key] = comparisonData[key];
                }
              } else if (details.employee_status === "Fresher" || details.employee_status === "Market") {
                if (!(key === "vendor"
                  || key === "invoice_date"
                  || key === "end_client"
                  || key === "end_date"
                  || key === "ratecard"
                  || key === "billable_in_req"
                  || key === "non_billable_type"
                  || key === "release_date"
                )) {
                  modifiedCompData[key] = comparisonData[key];
                }
              }
            });

            const handleChanges = (acc, key) => {
              if (
                details.hasOwnProperty(key)
                && details[key] !== modifiedCompData[key]
              ) {
                if (typeof details[key] !== 'object') {
                  let modifiedKey = modifyKeys(key);
                  acc.push({
                    existing_data: {
                      key: modifiedKey,
                      value: (
                        modifiedCompData[key] === ""
                        || modifiedCompData[key] === null
                        || modifiedCompData[key] === undefined
                      )
                        ? "-"
                        : key === "is_enabled"
                          ? modifiedCompData[key] === 1
                            ? "YES"
                            : "NO"
                          : modifiedCompData[key]
                    },
                    new_data: {
                      key: modifiedKey,
                      value: (
                        details[key] === ""
                        || details[key] === null
                        || details[key] === undefined
                      )
                        ? "-"
                        : key === "is_enabled"
                          ? details[key] === 1
                            ? "YES"
                            : "NO"
                          : details[key]
                    }
                  });
                }

                if (key === "Previous_Company_details") {
                  let modifiedKey = modifyKeys(key);

                  const addedCompDetails = details[key].filter(item =>
                    !modifiedCompData[key].some(item2 => item2.prev_id === item.prev_id)
                  );

                  const removedCompDetails = modifiedCompData[key].filter(item =>
                    !details[key].some(item2 => item2.prev_id === item.prev_id)
                  );

                  const removeChangedData = details[key].filter(item => {
                    if (addedCompDetails.length > 0 && removedCompDetails.length > 0) {
                      return addedCompDetails.some(item2 => item2.prev_id !== item.prev_id)
                        && removedCompDetails.some(item2 => item2.prev_id !== item.prev_id)
                    } else if (addedCompDetails.length > 0) {
                      return addedCompDetails.some(item2 => item2.prev_id !== item.prev_id)
                    } else if (removedCompDetails.length > 0) {
                      return removedCompDetails.some(item2 => item2.prev_id !== item.prev_id);
                    } else {
                      return item
                    }
                  }
                  );

                  const getModifiedDataSentence = (modifiedCompData, removeChangedData, key) => {
                    const modifiedSentences = removeChangedData.map(item => {
                      const correspondingItem = modifiedCompData[key].find(item2 => item.prev_id === item2.prev_id);
                      if (!correspondingItem) return '';

                      const changes = [];

                      for (const [field, value] of Object.entries(item)) {
                        if (field !== 'prev_id' && value !== correspondingItem[field]) {
                          changes.push({
                            field,
                            oldValue: correspondingItem[field],
                            newValue: value
                          });
                        }
                      }

                      if (changes.length === 0) return '';

                      const changeDescriptions = changes.map(change => {
                        const fieldName = change.field.replace('prev_', '').replace('_', ' ').replace('comp startdate', 'start date').replace('comp enddate', 'end date').replace('yoe', 'YOE').replace('comp', 'company');

                        return `${fieldName} changed from ${change.oldValue} to ${change.newValue}`;
                      }).join(', ');

                      return `${item.prev_comp_name}'s ${changeDescriptions}`;
                    }).filter(Boolean);

                    return modifiedSentences;
                  };

                  const modifiedSentences = getModifiedDataSentence(modifiedCompData, removeChangedData, key);

                  const updatedDetails = modifiedSentences;

                  if (addedCompDetails.length > 0 || removedCompDetails.length > 0 || updatedDetails.length > 0) {
                    acc.push({
                      key: modifiedKey,
                      "Added Company Details": addedCompDetails,
                      "Removed Company Details": removedCompDetails,
                      "Updated Company Details": updatedDetails
                    });
                  }
                }
              }
            }

            const changes = Object.keys(modifiedCompData).reduce((acc, key) => {
              handleChanges(acc, key);
              return acc;
            }, []);

            if (typeof values?.Resume == "object") {
              changes.push({
                Resume: "Resume has been updated"
              })
            }

            const updatePayload = {
              candidate_id: parseInt(id),
              actionEvent: "Candidate Updated",
              changedBy: userName,
              username: name,
              occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
              changes
            }
            if (changes.length > 0) {
              logger(updatePayload);
            }
          }
        } else if (res.data.status === 2) {
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "Candidate Information is already Exists",
            duration: 2,
          });
        } else {
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "Candidate Information is not updated",
            duration: 2,
          });
          if (!!res.data?.candidate_id) {
            setIdFromDB(res.data?.candidate_id);
            CandidateForm.setFieldValue("Resume", undefined);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const getSkillset = async () => {
    await staffingUrl
      .get(`api/StaffMgnt/getSkillset`)
      .then((res) => {
        setSkillset(res.data);
      })
      .catch((err) => console.log(err));
  };

  const addSkill = async (value) => {
    await staffingUrl
      .post(
        `api/StaffMgnt/addSkill?skill=${value.skillName}&username=${userName}`
      )
      .then((res) => {
        if (res.data.status == 1) {
          openNotificationWithIcon({
            color: "success",
            Title: "Success",
            description: "The skill Added successfully",
            duration: 2,
          });
          addSkillForm.resetFields();
          getSkillset();
        } else if (res.data.status == 2) {
          addSkillForm.resetFields();
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: `${value.skillName} is already exist`,
            duration: 2,
          });
        } else {
          addSkillForm.resetFields();
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "The skill is not Added",
            duration: 2,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  const handleUpload = async (file) => {
    setIsUploading(true)
    const formDataResume = new FormData();
    formDataResume.append('file', file.file);

    // try {
    //   const resumeResponse = await axios.post('https://resumemakerai.talent.jetzerp.com/parse_resume',
    //     formDataResume,
    //     {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //         'Accept': 'application/json'
    //       }
    //     }
    //   );

    //   CandidateForm.setFields([
    //     {
    //       name: 'fname',
    //       value: resumeResponse.data.first_name,
    //       errors: (
    //         !!resumeResponse.data.first_name
    //         && resumeResponse.data.first_name.length > 0
    //       )
    //         ? []
    //         : ["First name is required"]
    //     },
    //     {
    //       name: 'lname',
    //       value: resumeResponse.data.last_name,
    //       errors: (!!resumeResponse.data.last_name
    //         && resumeResponse.data.last_name.length > 0)
    //         ? []
    //         : ["Last name is required"]
    //     },
    //     {
    //       name: 'email_id',
    //       value: resumeResponse.data.email,
    //       errors: (
    //         !!resumeResponse.data.email
    //         && resumeResponse.data.email.length > 0
    //       )
    //         ? []
    //         : ["Email is required"]
    //     },
    //     {
    //       name: 'contact_no',
    //       value: resumeResponse.data?.phone?.split('-')[1],
    //       errors: (
    //         !!resumeResponse.data.phone
    //         && resumeResponse.data.phone.length > 0
    //       )
    //         ? []
    //         : ["Contact no is required"]
    //     },
    //     {
    //       name: 'year',
    //       value: `${resumeResponse.data?.years_of_experience?.split('.')[0]} Years`,
    //       errors: (
    //         !!resumeResponse.data?.years_of_experience
    //         && resumeResponse.data?.years_of_experience?.length > 0
    //       )
    //         ? []
    //         : ["YOE is required"]
    //     },
    //     {
    //       name: 'month',
    //       value: `${resumeResponse.data?.years_of_experience?.split('.')[1]} Months`,
    //       errors: (
    //         !!resumeResponse.data?.years_of_experience
    //         && resumeResponse.data?.years_of_experience?.length > 0
    //       )
    //         ? []
    //         : ["YOE is required"]
    //     },
    //     {
    //       name: 'Skill_set',
    //       value: resumeResponse?.data?.skills.filter((skill) => skill.split(',')),
    //       errors: resumeResponse.data.skills.length > 0
    //         ? []
    //         : ["Skill set is required"]
    //     },
    //   ]);
    //   // CandidateForm.setFieldValue('fname', resumeResponse.data.first_name);
    //   // CandidateForm.setFieldValue('lname', resumeResponse.data.last_name);
    //   // CandidateForm.setFieldValue('email_id', resumeResponse.data.email);
    //   // CandidateForm.setFieldValue('contact_no', resumeResponse.data.phone?.split('-')[1]);
    //   // CandidateForm.setFieldValue('year', `${resumeResponse.data.years_of_experience.split('.')[0]} Years`);
    //   // CandidateForm.setFieldValue('month', `${resumeResponse.data.years_of_experience.split('.')[1]} Months`);
    //   // CandidateForm.setFieldValue("Skill_set", resumeResponse?.data?.skills.filter((skill) => skill.split(',')));
    //   setCountry_Code(countryCode.filter(item => resumeResponse?.data?.contact?.startsWith(item))[0] || countryCode[0]);
    // } catch (err) {
    //   console.log(err)
    // }
    const fileName = file.file;
    const acceptedFormats = ["pdf", "doc", "docx"];
    if (acceptedFormats.includes(fileName.name.split(".")[1])) {
      console.log("OK");
    } else {
      setIsLoading(false);
      console.log("ERROR");
    }
  };

  const handleEmployeeField = (value) => {
    setEmployeeTypeField(value);
    setEmployeeStatus("");
    CandidateForm.setFieldValue("employee_status", "")
    if (value === "Internal") {
      setEmployeeStatusOptions(prevState => {
        return employeeStatusList.filter(item => item.emp_status_id !== 3)
      });
    } else if (value === "Sub-Contract") {
      setEmployeeStatusOptions(prevState => {
        return employeeStatusList.filter(item => (item.emp_status_id === 1 || item.emp_status_id === 2))
      });
    } else if (value === "External") {
      setEmployeeStatusOptions(prevState => {
        return employeeStatusList;
      });
    }
  }

  const addNewSkill = (menu) => (
    <>
      {menu}
      <Divider
        style={{
          margin: "8px 0",
        }}
      />
      <Space
        style={{
          padding: "0 8px 4px",
        }}
      >
        <Form form={addSkillForm}
          onFinish={addSkill}
          onValuesChange={(values) => {
            if (values?.skillName?.length === 1 && values.skillName) {
              addSkillForm.setFieldValue('skillName', values?.skillName?.trim())
            }
          }}
        >
          <Form.Item
            name="skillName"
            rules={[
              {
                required: true,
                message: "Please enter name of the skill",
              },
            ]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Input
                placeholder="Please enter item"
                className="w-full"
                size="middle"
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button htmlType="submit" icon={<PlusOutlined />}>
                Add skill
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Space>
    </>
  );

  const handleKeyDown = (event) => {
    // Allow only numeric key presses
    if (
      !(
        // Allow numeric keys
        (
          (event.keyCode >= 48 && event.keyCode <= 57) ||
          // Allow numpad keys
          (event.keyCode >= 96 && event.keyCode <= 105) ||
          // Allow backspace, delete, tab, arrow keys, and other special keys
          event.keyCode === 8 || // Backspace
          event.keyCode === 9 || // Tab
          event.keyCode === 37 || // Left Arrow
          event.keyCode === 39 || // Right Arrow
          event.keyCode === 46
        ) // Delete
      )
    ) {
      event.preventDefault();
    }
  };

  const isRowEmpty = (index) => {
    const company_name = CandidateForm.getFieldValue(`prev_company_name_${index}`);
    const role = CandidateForm.getFieldValue(`prev_role_${index}`);
    const start_date = CandidateForm.getFieldValue(`prev_start_date_${index}`);
    const end_date = CandidateForm.getFieldValue(`prev_end_date_${index}`);
    const id = CandidateForm.getFieldValue(`prev_id_${index}`);

    return !(company_name && role && start_date && end_date);
  };

  const handleAddRow = (item) => {
    setRows([...rows, item + 1]);
    CandidateForm.setFieldValue(`prev_id_${item + 1}`, item + 1);
  };

  const handleDeleteRow = (index) => {
    if (rows.length === 1) {
      setAddBtnClicked(false);
    }
    setRows(prevRows => {
      const newState = prevRows.filter((key, i) => {
        return key !== index
      });

      return newState;
    });

    CandidateForm.setFieldValue(`prev_company_name_${index}`, null);
    CandidateForm.setFieldValue(`prev_role_${index}`, null);
    CandidateForm.setFieldValue(`prev_start_date_${index}`, null);
    CandidateForm.setFieldValue(`prev_yoe_${index}`, null);
    CandidateForm.setFieldValue(`prev_end_date_${index}`, null);
    CandidateForm.setFieldValue(`prev_id_${index}`, null);
  };

  const disabledEndDate = (current, startValue) => {
    return current && current <= dayjs(startValue).startOf('day');
  };

  const calculateExperience = (startDate, endDate, index) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    if (!startDate) {
      CandidateForm.setFieldsValue({
        [`prev_end_date_${index}`]: null,
        [`prev_yoe_${index}`]: null,
      });
      return;
    }

    if (!endDate) {
      CandidateForm.setFieldsValue({
        [`prev_yoe_${index}`]: null,
      });
      return;
    }

    let years = end.diff(start, 'year');

    const adjustedStart = start.add(years, 'year');
    if (end.isBefore(adjustedStart)) {
      years--;
    }
    const months = end.diff(start.add(years, 'year'), 'month');
    CandidateForm.setFieldValue(`prev_yoe_${index}`, `${years}Y ${months}M`)
  };

  const handleCandidateFormChanges = (values) => {
    if (values?.end_client?.length === 1 && values.end_client) {
      CandidateForm.setFieldValue('end_client', values?.end_client?.trim())
    }
    if (values?.fname?.length === 1 && values.fname) {
      CandidateForm.setFieldValue('fname', values?.fname?.trim())
    }
    if (values?.lname?.length === 1 && values.lname) {
      CandidateForm.setFieldValue('lname', values?.lname?.trim())
    } if (values?.email_id?.length === 1 && values.email_id) {
      CandidateForm.setFieldValue('email_id', values?.email_id?.trim())
    }
    rows.map((row) => {
      if (values[`prev_company_name_${row}`]?.length === 1 && values[`prev_company_name_${row}`]) {
        CandidateForm.setFieldValue(`prev_company_name_${row}`, values[`prev_company_name_${row}`]?.trim())
      } else if (values[`prev_role_${row}`]?.length === 1 && values[`prev_role_${row}`]) {
        CandidateForm.setFieldValue(`prev_role_${row}`, values[`prev_role_${row}`]?.trim())
      }
    })
  };

  const handleOnChangeVendor = async (value) => {
    const customer_id = value.split('-')[0]
    await staffingUrl
      .get(`api/StaffMgnt/getVendorAssociatedRequirements/${customer_id}`)
      .then((res) => {
        setRequirementList(res.data.requirements)
        CandidateForm.setFieldValue('openings', null);
      })
      .catch((err) => console.error(err));
  };

  const handleStateSelect = () => {
    CandidateForm.setFieldValue('state', null);
    CandidateForm.setFieldValue('city', null);
    CandidateForm.setFieldValue('Preferredstate', null);
    CandidateForm.setFieldValue('Preferredcity', null);
  };

  const handleCityChange = () => {
    CandidateForm.setFieldValue('city', null);
    CandidateForm.setFieldValue('Preferredcity', null);
  };

  const handleActiveStatus = async (value) => {
    await staffingUrl.post(`api/StaffMgnt/enableOrDisableCandidate/${id}`);
  };

  const handleResumeRemove = () => {
    setIsShowUploadFile(false);
    CandidateForm.setFieldValue('fname', null);
    CandidateForm.setFieldValue('lname', null);
    CandidateForm.setFieldValue('email_id', null);
    CandidateForm.setFieldValue('contact_no', null);
    CandidateForm.setFieldValue('year', null);
    CandidateForm.setFieldValue('month', null);
    CandidateForm.setFieldValue('Skill_set', null);
  };

  useEffect(() => {
    if (employeeStatusList.length > 0) {
      if (employeeTypeField === "Internal") {
        setEmployeeStatusOptions(prevState => {
          return employeeStatusList.filter(item => item.emp_status_id !== 3)
        });
      } else if (employeeTypeField === "Sub-Contract") {
        setEmployeeStatusOptions(prevState => {
          return employeeStatusList.filter(item => item.emp_status_id <= 2)
        });
      } else if (employeeTypeField === "External") {
        setEmployeeStatusOptions(prevState => {
          return employeeStatusList;
        });
      }
    }
  }, [employeeTypeField, employeeStatusList]);

  const formatDate = (monthYear) => {
    if (monthYear === 'Till Date' || monthYear === 'Present') {
      return dayjs().format('YYYY-MM-DD');
    }

    const yearOnlyFormat = /^\d{4}$/;
    if (yearOnlyFormat.test(monthYear)) {
      return dayjs(monthYear, 'YYYY').format('YYYY'); 
    }

    const [month, year] = monthYear?.split(' ');
    const formattedDate = dayjs(`${'20' + year}-${month}-01`, 'YYYY-MMM-DD').format('YYYY-MM-DD');

    return formattedDate;
    
    // if (dayjs(formattedDate).isValid()) {
    //   return formattedDate.format('YYYY-MM-DD');
    // } else {
    //   console.error(`Invalid date format for input: ${monthYear}`);
    //   return null;
    // }
  };

  useEffect(() => {
    if (!!resumeResponseObj) {
      resumeResponseObj.prev_company_name.length > 0 ?  setAddBtnClicked(true) :  setAddBtnClicked(false)
      setIsShowUploadFile(true);
      CandidateForm.setFieldValue('fname', resumeResponseObj.first_name);
      CandidateForm.setFieldValue('lname', resumeResponseObj.last_name);
      CandidateForm.setFieldValue('email_id', resumeResponseObj.email);
      CandidateForm.setFieldValue('contact_no', resumeResponseObj?.phone);
      CandidateForm.setFieldValue('year', resumeResponseObj.yoe_year);
      CandidateForm.setFieldValue('month', !!resumeResponseObj.yoe_month ? resumeResponseObj.yoe_month : "0 Month");
      CandidateForm.setFieldValue('Skill_set', resumeResponseObj.skill_set?.filter((skill) => skill?.split(',')));
      setCountry_Code(resumeResponseObj.countryCode);
      setRows(resumeResponseObj?.prev_company_role?.map((_, i) => i));
      resumeResponseObj?.prev_company_role?.map((value, index) => {
        CandidateForm.setFieldValue(`prev_role_${index}`, value);
        CandidateForm.setFieldValue(`prev_id_${index}`, index);
      })
      resumeResponseObj?.prev_company_name.map((value, index) => {
        CandidateForm.setFieldValue(`prev_company_name_${index}`, value);
      })
      // resumeResponseObj?.prev_company_duration?.forEach((value, index) => {
      //   const [start, end] = value.split('-');
      //   const startDate = formatDate(start); 
      //   const endDate = formatDate(end);
  
      //   if (startDate && endDate) {
      //     calculateExperience(dayjs(startDate),dayjs(endDate),index)
      //     CandidateForm.setFieldValue(`prev_start_date_${index}`, dayjs(startDate));
      //     CandidateForm.setFieldValue(`prev_end_date_${index}`, dayjs(endDate));
      //   }
      // });
    }
  }, [resumeResponseObj]);

  useEffect(() => {
    if (
      country_code === "+91"
      || country_code === "+61"
      || country_code === "+1"
      || country_code === "+44"
      || country_code === "+52"
    ) {
      setPhoneNumLength(10);
    } else if (country_code === "+86") {
      setPhoneNumLength(11);
    }
  }, [country_code]);

  useEffect(() => {
    !!idFromDB && getCandidateInfo(idFromDB);
  }, [idFromDB]);

  // useEffect(() => {
  //   if (rows.length === 0) {
  //     setAddBtnClicked(false);
  //   }
  // }, [rows])

  useEffect(() => {
    getData();
    getCustomer();
    if (id) {
      getCandidateInfo(id);
    }
  }, []);

  return (
    <>
      {contextHolder}
      <Grid>
        <Breadcrumb
          className="cursor-pointer"
          items={[
            {
              title: (
                <span
                  onClick={() => {
                    navigate("/candidatedashboard");
                  }}
                >
                  Candidate Dashboard
                </span>
              ),
            },
            {
              title: "Candidate Form",
            },
          ]}
        />
      </Grid>
      <Grid sx={{ padding: "1em", paddingBottom: "2em", boxShadow: "none" }}>
        <div style={{ border: '1px solid #c3c3c7', borderRadius: '10px' }}>
          <div>
            <Form
              layout="vertical"
              onFinish={(values) => {
                CandidateFormSubmit(
                  values,
                  id || idFromDB ? "update" : "add"
                );
              }}
              form={CandidateForm}
              onFinishFailed={dateCheck}
              className="w-full"
              onValuesChange={handleCandidateFormChanges}
            >
              <div>
                <div style={{ padding: '15px 20px', background: '#dddddd52', borderRadius: '10px 10px 0px px' }}>
                  <h4 className="text-[16px] font-[500]">Candidate Info</h4>
                </div>
                <div style={{ padding: '20px' }}>
                  <div className="md:flex md:flex-wrap py-2">
                    <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                      <Form.Item
                        name="fname"
                        label="First Name"
                        labelStyle={{ fontWeight: 700 }}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your first name",
                          },
                          {
                            pattern: /^[a-zA-Z\s]+$/,
                            message: "Invalid first name",
                          }
                        ]}
                      >
                        <Input
                          className="w-full h-10"
                          size=""
                          placeholder="Enter Your First Name"
                          maxLength={25}
                          onKeyDown={(e) => {
                            if (/\d/.test(e.key) || /[^a-zA-Z ]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="md:w-1/2 px-3">
                      <Form.Item
                        name="lname"
                        label="Last Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your last name",
                          },
                          {
                            pattern: /^[a-zA-Z\s]+$/,
                            message: "Invalid last name",
                          }
                        ]}
                      >
                        <Input
                          className="w-full h-10"
                          size=""
                          placeholder="Enter Your Last Name"
                          maxLength={25}
                          onKeyDown={(e) => {
                            if (/\d/.test(e.key) || /[^a-zA-Z ]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="md:flex md:flex-wrap py-2">
                    <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                      <Form.Item
                        name="email_id"
                        label="Email Address"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Email Address",
                          },
                          {
                            type: "email",
                            message: "Invalid Email Address",
                          },
                          {
                            validator: (rule, value) => {
                              return new Promise((resolve, reject) => {
                                const findMail = candidateEmails?.find(
                                  (item) => item === value
                                )
                                if (findMail) {
                                  reject('User already exists');
                                } else {
                                  resolve();
                                }
                              })
                            }
                          }
                        ]}
                      >
                        <Input
                          className="w-full h-10"
                          size=""
                          placeholder="Enter Your Email Address"
                        />
                      </Form.Item>
                    </div>
                    <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-4 md:mb-0">
                      <div className="w-full items-center mb-4 md:mb-0">
                        <Form.Item
                          className="h-10 custom-form-item"
                          name="contact_no"
                          label="Contact Number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your phone number",
                            },
                            // {
                            //   min: phoneNumLength,
                            //   message: "Invalid phone number",
                            // }
                            {
                              max: 17,
                              message: "Invalid phone number",
                            }
                          ]}
                        >
                          <Input
                            className="w-full h-full"
                            // size="large"
                            placeholder="Enter Your Contact Number"
                            // maxLength={phoneNumLength}
                            maxLength={17}
                            addonBefore={
                              <Select
                                className="h-full"
                                defaultValue="+91"
                                style={{ width: 70 }}
                                options={countryCode.map((a) => {
                                  return {
                                    value: a,
                                    lable: a,
                                  };
                                })}
                                onChange={e => setCountry_Code(e)}
                                value={country_code}
                              />
                            }
                            style={{ fontSize: 13 }}
                            onChange={(event) => {
                              const numericValue = event.target.value.replace(/\D/g, ''); // Replace non-digit characters with empty string
                              CandidateForm.setFieldValue('contact_no', numericValue)
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="w-full items-center ">
                        <Form.Item
                          name="Aadhaar_id"
                          label="Aadhaar Number"
                          rules={[
                            {
                              required: false,
                              message: "Please enter your aadhar number",
                            },
                            {
                              min: 12,
                              message: "Invalid aadhar number",
                            },
                          ]}
                        >
                          <Input
                            className="w-full h-10"
                            // size="large"
                            placeholder="Enter Your Aadhaar Number"
                            minLength={12}
                            maxLength={12}
                            onChange={(event) => {
                              const numericValue = event.target.value.replace(/\D/g, ''); // Replace non-digit characters with empty string
                              CandidateForm.setFieldValue('Aadhaar_id', numericValue)
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:flex-wrap py-2">
                    <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                      <div className="w-full items-center">
                        <Form.Item
                          name="country"
                          label="Location"
                          rules={[
                            {
                              required: false,
                              message: "Please select a location",
                            },
                          ]}
                        >
                          <Select
                            className="w-full"
                            size="large"
                            // mode="multiple"
                            showSearch
                            placeholder="Country"
                            onSelect={handleStateSelect}
                            options={country.map((map) => ({
                              value: map.name,
                              label: map.name,
                            }))}
                            onChange={(value) => {
                              FilterState(value, "location");
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="w-full items-center">
                        <Form.Item
                          name="state"
                          label=" "
                          rules={[
                            {
                              required: false,
                              message: "Please select a location",
                            },
                          ]}
                        >
                          <Select
                            className="w-full"
                            size="large"
                            // mode="multiple"
                            showSearch
                            placeholder="State"
                            onSelect={handleCityChange}
                            options={stateFilterd.map((map) => ({
                              value: map.name,
                              label: map.name,
                            }))}
                            onChange={(value) => {
                              FilterCities(value, "location");
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="w-full items-center">
                        <Form.Item
                          name="city"
                          label=" "
                          rules={[
                            {
                              required: false,
                              message: "Please select a location",
                            },
                          ]}
                        >
                          <Select
                            className="w-full"
                            size="large"
                            // mode="multiple"
                            showSearch
                            placeholder="City"
                            options={cityFilterd.map((map) => ({
                              value: map.name,
                              label: map.name,
                            }))}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                      <div className="w-full items-center">
                        <Form.Item
                          name="open_to_relocate"
                          label="Open To Relocate"
                          valuePropName="checked"
                          rules={[
                            {
                              required: false,
                              message: "Please select a relocate option",
                            },
                          ]}
                        >
                          <Checkbox
                            name="open_to_relocate"
                            className="custom-checkbox w-8"
                            checked={openToRelocate}
                            onChange={e => setOpenToRelocate(e.target.checked)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="mt-4">
                <div style={{ padding: '15px 20px', background: '#dddddd52', borderRadius: '10px 10px 0px px' }}>
                  <h4 className="text-[16px] font-[500]">Employment Info</h4>
                </div>
                <div style={{ padding: '20px' }}>
                  <div className="md:flex md:flex-wrap py-2">
                    <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                      <div className="flex gap-2 items-center">
                        <div className="w-full">
                          <Form.Item label="Years of Experience" required>
                            <div className="flex gap-2 items-center">
                              <div className="w-full">
                                <Form.Item
                                  name="year"
                                  rules={[
                                    {
                                      required: false,
                                      message:
                                        "Please select your year of experience",
                                    },
                                  ]}
                                  style={{ marginBottom: 0 }}
                                >
                                  <Select
                                    allowClear
                                    className="w-full"
                                    size="large"
                                    placeholder="Year"
                                    options={Array.from({ length: 21 }, (_, index) => ({
                                      value: `${index} ${(index === 0 || index === 1) ? 'Year' : 'Years'}`,
                                      label: `${index} ${(index === 0 || index === 1) ? 'Year' : 'Years'}`,
                                    }))}
                                  />
                                </Form.Item>
                              </div>
                              <div className="w-full">
                                <Form.Item
                                  name="month"
                                  rules={[
                                    {
                                      required: false,
                                      message:
                                        "Please select your month of experience",
                                    },
                                  ]}
                                  style={{ marginBottom: 0 }}
                                >
                                  <Select
                                    allowClear
                                    className="w-full"
                                    size="large"
                                    placeholder="Month"
                                    options={Array.from({ length: 13 }, (_, index) => ({
                                      value: `${index} ${(index === 0 || index === 1) ? 'Month' : 'Months'}`,
                                      label: `${index} ${(index === 0 || index === 1) ? 'Month' : 'Months'}`,
                                    }))}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                      <div className="w-full items-center mb-4 md:mb-0">
                        <div className="w-full">
                          <Form.Item
                            name="current_ctc"
                            label="Current CTC"
                            rules={[
                              {
                                required: false,
                                message: "Please enter your current CTC",
                              },
                            ]}
                          >
                            <InputNumber
                              controls={false}
                              className="w-full"
                              size="large"
                              placeholder="Enter Your Current CTC"
                              maxLength={15}
                              onKeyDown={(event) => {
                                if (
                                  !(
                                    // Allow numeric keys
                                    (
                                      (event.keyCode >= 48 &&
                                        event.keyCode <= 57) ||
                                      // Allow numpad keys
                                      (event.keyCode >= 96 &&
                                        event.keyCode <= 105) ||
                                      // Allow backspace, delete, tab, arrow keys, and other special keys
                                      event.keyCode === 8 || // Backspace
                                      event.keyCode === 9 || // Tab
                                      event.keyCode === 37 || // Left Arrow
                                      event.keyCode === 39 || // Right Arrow
                                      event.keyCode === 46 || // Delete
                                      event.keyCode === 190 || // Period (for decimal numbers)
                                      event.keyCode === 110
                                    )
                                  )
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              } // Parses the value to remove currency symbols and commas
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="w-full items-center ">
                        <div className="w-full">
                          <Form.Item
                            name="expected_ctc"
                            label="Expected CTC"
                            rules={[
                              {
                                required: false,
                                message: "Please enter your expected CTC",
                              },
                            ]}
                          >
                            <InputNumber
                              controls={false}
                              className="w-full"
                              size="large"
                              placeholder="Enter Your Expected CTC"
                              maxLength={15}
                              onKeyDown={(event) => {
                                if (
                                  !(
                                    // Allow numeric keys
                                    (
                                      (event.keyCode >= 48 &&
                                        event.keyCode <= 57) ||
                                      // Allow numpad keys
                                      (event.keyCode >= 96 &&
                                        event.keyCode <= 105) ||
                                      // Allow backspace, delete, tab, arrow keys, and other special keys
                                      event.keyCode === 8 || // Backspace
                                      event.keyCode === 9 || // Tab
                                      event.keyCode === 37 || // Left Arrow
                                      event.keyCode === 39 || // Right Arrow
                                      event.keyCode === 46 || // Delete
                                      event.keyCode === 190 || // Period (for decimal numbers)
                                      event.keyCode === 110
                                    )
                                  )
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              } // Parses the value to remove currency symbols and commas
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:flex-wrap py-2">
                    <div className="md:w-1/2 px-3 mb-6 md:mb-3">
                      <Form.Item
                        name="Skill_set"
                        label="Skill Set"
                        rules={[
                          {
                            required: true,
                            message: "Please select your skill set",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          size="large"
                          placeholder="Select Skill Set"
                          dropdownRender={addNewSkill}
                          options={skillSet.map((item) => ({
                            value: item.skill,
                            label: item.skill,
                          }))}
                        />
                      </Form.Item>
                    </div>
                    <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                      <div className="w-full items-center mb-4 md:mb-0">
                        <Form.Item
                          name="work_mode"
                          label="Work Mode"
                          rules={[
                            {
                              required: true,
                              message: "Please select your work mode",
                            },
                          ]}
                        >
                          <Select
                            className="w-full"
                            size="large"
                            placeholder="Select Work Mode"
                            options={work_mode}
                          />
                        </Form.Item>
                      </div>
                      <div className="w-full items-center ">
                        <div className="w-full">
                          <Form.Item
                            name="source"
                            label="Source"
                            rules={[
                              {
                                required: false,
                                message: "Please select source",
                              },
                            ]}
                          >
                            <Select
                              className="w-full"
                              size="large"
                              placeholder="Select Source"
                              options={Source}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>

                    <div className="md:flex md:gap-2 md:w-full px-3 mb-6 md:mb-0">
                      <div className="w-full items-center">
                        <Form.Item
                          name="Preferredcountry"
                          label="Preferred Location"
                          rules={[
                            {
                              required: false,
                              message: "Please select a location",
                            },
                          ]}
                        >
                          <Select
                            className="w-full"
                            size="large"
                            mode="multiple"
                            placeholder="Country"
                            options={country.map((map) => ({
                              value: map.name,
                              label: map.name,
                            }))}
                            onChange={(value) => {
                              FilterState(value, "preferred");
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="w-full items-center">
                        <Form.Item
                          name="Preferredstate"
                          label=" "
                          rules={[
                            {
                              required: false,
                              message: "Please select a location",
                            },
                          ]}
                        >
                          <Select
                            className="w-full"
                            size="large"
                            mode="multiple"
                            placeholder="State"
                            options={prefStateFilterd.map((map) => ({
                              value: map.name,
                              label: map.name,
                            }))}
                            onChange={(value) => {
                              FilterCities(value, "preferred");
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="w-full items-center">
                        <Form.Item
                          name="Preferredcity"
                          label=" "
                          rules={[
                            {
                              required: false,
                              message: "Please select a location",
                            },
                          ]}
                        >
                          <Select
                            className="w-full"
                            size="large"
                            mode="multiple"
                            placeholder="City"
                            options={prefCityFilterd.map((map) => ({
                              value: map.name,
                              label: map.name,
                            }))}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div style={{ padding: '15px 20px', background: '#dddddd52', borderRadius: '10px 10px 0px px' }}>
                  <h4 className="text-[16px] font-[500]">Management Info</h4>
                </div>
                <div style={{ padding: '20px' }}>
                  <div className="md:flex md:gap-2 md:full px-3 mb-6 md:mb-0">
                    <div className="w-full items-center mb-4 md:mb-0">
                      <div className="w-full">
                        <Form.Item
                          name="Employee_Type"
                          label="Employee Type"
                          rules={[
                            {
                              required: true,
                              message: "Please select your employee type",
                            },
                          ]}
                        >
                          <Select
                            className="w-full"
                            size="large"
                            placeholder="Employee Type"
                            value={employeeTypeField}
                            onChange={handleEmployeeField}
                            options={employeeType.map((item) => ({
                              value: item.emp_type,
                              label: item.emp_type,
                            }))}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="w-full items-center mb-4 md:mb-0">
                      <div className="w-full">
                        <Form.Item
                          name="employee_status"
                          label="Employee Status"
                          rules={[
                            {
                              required: true,
                              message: "Please select your employee status",
                            },
                          ]}
                        >
                          <Select
                            className="w-full"
                            size="large"
                            placeholder="Employee Status"
                            options={employeeStatusOptions.map((item) => ({
                              value: item.emp_status,
                              label: item.emp_status,
                            }))}
                            disabled={employeeTypeField.length === 0}
                            value={employeeStatus}
                            onChange={(e) => setEmployeeStatus(e)}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    {!!id && (
                      <div className="w-full items-center">
                        <Form.Item
                          name="is_active"
                          label="Active Status"
                          rules={[
                            {
                              required: false,
                              message: "",
                            },
                          ]}
                        >
                          <Select
                            className="w-full"
                            size="large"
                            placeholder="Candidate active status"
                            onChange={handleActiveStatus}
                            options={[
                              { value: 1, label: "YES" },
                              { value: 0, label: "NO" }
                            ]}
                          />
                        </Form.Item>
                      </div>
                    )}
                    <div className="w-full mb-4 md:mb-0">
                      <div className="w-full">
                        <Form.Item
                          name="is_priority"
                          label="Prioritize"
                          valuePropName="checked"
                        >
                          <div className="ms-5">
                            <Checkbox
                              name="is_priority"
                              className="custom-checkbox w-8"
                              checked={isPrioritized}
                              onChange={e => setIsPrioritized(e.target.checked)}
                            />
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-3">
                    {employeeStatus === "Billable" && (
                      <div className="md:flex md:flex-wrap py-3 dynamicBg rounded">
                        <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                          <div className="w-full items-center mb-4 md:mb-0">
                            <div className="w-full">
                              <Form.Item name="vendor" label="Vendor"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select your Vendor type",
                                  },
                                ]}
                              >
                                <Select
                                  name="vendor"
                                  className="w-full"
                                  size="large"
                                  placeholder="Select the vendor"
                                  showSearch
                                  onInput={(e) => {
                                    e.target.value = e.target.value.substring(0, 25);
                                  }}
                                  onKeyDown={(e) => {
                                    if (/\d/.test(e.key)) { // Check if the pressed key is a number
                                      e.preventDefault(); // Prevent input of numbers
                                    }
                                  }}
                                  onChange={handleOnChangeVendor}
                                  // dropdownRender={addNewCustomer}
                                  options={customerName?.map((item) => ({
                                    label: item.customer1,
                                    value: `${item.sno}-${item.customer1}`,
                                  }))}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="w-full items-center mb-4 md:mb-0">
                            <div className="w-full">
                              <Form.Item
                                name="openings"
                                label="Openings"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please select the requirement",
                                  },
                                ]}
                              >
                                <Select
                                  name="openings"
                                  className="w-full"
                                  size="large"
                                  placeholder="Select the opening"
                                  showSearch
                                  onInput={(e) => {
                                    e.target.value = e.target.value.substring(0, 25);
                                  }}
                                  onKeyDown={(e) => {
                                    if (/\d/.test(e.key)) { // Check if the pressed key is a number
                                      e.preventDefault(); // Prevent input of numbers
                                    }
                                  }}

                                  // dropdownRender={addNewCustomer}
                                  options={requirementList?.map((item) => ({
                                    label: item.req_title,
                                    value: `${item.req_id}-${item.req_title}`,
                                  }))}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="w-full items-center mb-4 md:mb-0">
                            <div className="w-full">
                              <Form.Item name="end_client" label="Client"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select your client type",
                                  },
                                ]}
                              >
                                <Input
                                  className="w-full"
                                  size="large"
                                  placeholder="Enter the client"
                                  maxLength={35}
                                  onChange={() => CandidateForm?.getFieldValue('end_client')?.trim('')}
                                  onKeyDown={(e) => {
                                    if (/\d/.test(e.key)) { // Check if the pressed key is a number
                                      e.preventDefault(); // Prevent input of numbers
                                    }
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                          <div className="w-full items-center mb-4 md:mb-0">
                            <div className="w-full">
                              <Form.Item name="invoice_date" label="Invoice Date"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select your invoice date ",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="w-full"
                                  size="large"
                                //minDate={dayjs(new Date())}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="w-full items-center mb-4 md:mb-0">
                            <div className="w-full">
                              <Form.Item name="end_date" label="End Date"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select your end date",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="w-full"
                                  size="large"
                                //minDate={dayjs(new Date())}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="w-full items-center mb-4 md:mb-0">
                            <div className="w-full">
                              <Form.Item name="ratecard" label="Rate Card"

                                rules={[
                                  {
                                    required: true,
                                    message: "Please select your rate card",
                                  },
                                ]}>
                                <InputNumber
                                  controls={false}
                                  className="w-full"
                                  size="large"
                                  placeholder="Rate card"
                                  suffix="LPA"
                                  maxLength={4}
                                  min={1}
                                  max={999}
                                  onKeyDown={(event) => {
                                    const allowedKeys = /[0-9.]/
                                    const specialKeys = [
                                      'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'
                                    ];

                                    if (!allowedKeys.test(event.key) && !specialKeys.includes(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  formatter={(value) =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  } // Formats the value as currency
                                  parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, "")
                                  } // Parses the value to remove currency symbols and commas
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {employeeStatus === "Non-Billable" && (
                      <div className="md:flex md:flex-wrap py-3 dynamicBg rounded">
                        <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                          <div className="w-full">
                            <Form.Item
                              name="non_billable_type"
                              label="Non-Billable Type"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select Your Employee Status",
                            //   },
                            // ]}
                            >
                              <Select
                                className="w-full"
                                size="large"
                                placeholder="Employee Status"
                                options={[
                                  { value: "Training", label: "Training" },
                                  { value: "Pipeline", label: "Pipeline" },
                                  { value: "Bench", label: "Bench" },
                                ]}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="md:flex md:gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                          <div className="w-full">
                            <Form.Item
                              name="release_date"
                              label="Release Date"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select Your Release Date",
                            //   },
                            // ]}
                            >
                              <DatePicker
                                className="w-full"
                                size="large"
                              //minDate={dayjs(new Date())}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div style={{ padding: '15px 20px', background: '#dddddd52', borderRadius: '10px 10px 0px px' }}>
                  <h4 className="text-[16px] font-[500]">Experience Info</h4>
                </div>
                <div style={{ padding: '20px' }}>
                  <div className="px-3">
                    <Button
                      className="btn save-btn"
                      type="primary"
                      onClick={() => {
                        setAddBtnClicked(true);
                        rows.length === 0 && setRows([1]);
                        rows.length === 0 && CandidateForm.setFieldValue(`prev_id_1`, 1);
                      }}
                    >
                      + Add Previous Company
                    </Button>
                  </div>
                  <div className="mt-3">
                    {addBtnClicked &&
                      <>
                        <div className="px-3">
                          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                            <thead style={{ backgroundColor: '#f2f2f2' }}>
                              <tr>
                                <th style={styles.header}>Company name</th>
                                <th style={styles.header}>Role</th>
                                <th style={styles.header}>Start date</th>
                                <th style={styles.header}>End date</th>
                                <th style={styles.header}>YOE</th>
                                <th style={styles.header}>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {rows.map((item, index) => (
                                <tr key={item}>
                                  <td style={styles.cell}>
                                    <Form.Item
                                      name={`prev_company_name_${item}`}
                                      label=""
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please enter company name',
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="w-full"
                                        size="large"
                                        placeholder="Company name"
                                        maxLength={70}
                                        onBlur={(e) => e.target.value.trim()}
                                        onKeyDown={(e) => {
                                          if (/\d/.test(e.key) || /[^a-zA-Z ]/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td style={styles.cell}>
                                    <Form.Item
                                      name={`prev_role_${item}`}
                                      label=""
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please enter your role',
                                        },
                                        {
                                          pattern: /^[a-zA-Z\s]*$/,
                                          message: 'Please enter a valid role'
                                        }
                                      ]}
                                    >
                                      <Input
                                        className="w-full"
                                        size="large"
                                        placeholder="Role"
                                        maxLength={40}
                                        onKeyDown={(e) => {
                                          if (/\d/.test(e.key) || /[^a-zA-Z ]/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td style={styles.cell}>
                                    <Form.Item
                                      name={`prev_start_date_${item}`}
                                      label=""
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please select the start date',
                                        },
                                      ]}
                                    >
                                      <DatePicker
                                        size="large"
                                        onChange={(startDate) => {
                                          setStartDateSelected({
                                            ...startDateSelected,
                                            [item]: !!startDate
                                          });

                                          calculateExperience(startDate, CandidateForm.getFieldValue(`prev_end_date_${item}`), item);
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td style={styles.cell}>
                                    <Form.Item
                                      name={`prev_end_date_${item}`}
                                      label=""
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please select the end date',
                                        },
                                      ]}
                                    >
                                      <DatePicker
                                        size="large"
                                        onChange={(e) => calculateExperience(
                                          CandidateForm.getFieldValue(`prev_start_date_${item}`), e, item
                                        )}
                                        disabledDate={(current) => disabledEndDate(current, CandidateForm.getFieldValue(`prev_start_date_${item}`))}
                                        disabled={!startDateSelected[item]}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td style={styles.cell}>
                                    <Form.Item
                                      name={`prev_yoe_${item}`}
                                      label=""
                                      rules={[
                                        {
                                          required: false,
                                          message: 'Please enter years of experience',
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="w-full"
                                        size="large"
                                        placeholder="Years of experience"
                                        maxLength={25}
                                        disabled
                                      />
                                    </Form.Item>
                                  </td>
                                  <td style={styles.cell}>
                                    <div className="flex items-center gap-2 justify-center">
                                      {rows.length - 1 === index &&
                                        <Tooltip title="Add" placement="bottom">
                                          <FiPlusCircle
                                            onClick={() => {
                                              if (!isRowEmpty(item)) {
                                                handleAddRow(item);
                                              }
                                            }}
                                            style={{
                                              fontSize: '18px',
                                              color: '#12b912',
                                              cursor: 'pointer'
                                            }}
                                          />
                                        </Tooltip>
                                      }
                                      <Tooltip
                                        title="Delete"
                                        placement="bottom"
                                      >
                                        <MdDelete
                                          onClick={() => handleDeleteRow(item)}
                                          style={{
                                            fontSize: '18px',
                                            color: '#ff4040',
                                            cursor: 'pointer'
                                          }}
                                        />
                                      </Tooltip>

                                      <Form.Item
                                        hidden
                                        name={`prev_id_${item}`}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    }
                  </div>
                  <div>
                    <div className="md:flex md:flex-wrap py-2">
                      {noticePeriod != "Serving Notice Period" && (
                        <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                          <Form.Item
                            name="notice_period"
                            label="Notice Period"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please select Your notice period",
                          //   },
                          // ]}
                          >
                            <Select style={{ width: '50%' }}
                              // className="w-full"
                              size="large"
                              placeholder="Select Duration"
                              onChange={(value) => {
                                setNoticePeriod(value);
                              }}
                              options={Array.from({ length: 6 }).map(
                                (_, index) => ({
                                  value:
                                    index === 0
                                      ? `15 Days or less`
                                      : index === 4
                                        ? `More than 3 Month`
                                        : index === 1
                                          ? `${index} Month`
                                          : index === 5
                                            ? `Serving Notice Period`
                                            : `${index} Months`,
                                  label:
                                    index === 0
                                      ? `15 Days or less`
                                      : index === 4
                                        ? `More than 3 Month`
                                        : index === 1
                                          ? `${index} Month`
                                          : index === 5
                                            ? `Serving Notice Period`
                                            : `${index} Months`,
                                })
                              )}
                            />
                          </Form.Item>
                        </div>
                      )}
                      {noticePeriod === "Serving Notice Period" && (
                        <div className="flex gap-2 md:w-1/2 px-3 mb-6 md:mb-0">
                          <div className="w-full items-center">
                            <div className="w-full">
                              <Form.Item
                                name="notice_period"
                                label="Notice Period"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select your notice period",
                                  },
                                ]}
                              >
                                <Select
                                  className="w-full"
                                  size="large"
                                  placeholder="Select Duration"
                                  onChange={(value) => {
                                    setNoticePeriod(value);
                                  }}
                                  options={Array.from({ length: 6 }).map(
                                    (_, index) => ({
                                      value:
                                        index === 0
                                          ? `15 Days or less`
                                          : index === 4
                                            ? `More than 3 Month`
                                            : index === 1
                                              ? `${index} Month`
                                              : index === 2
                                                ? "Immediate Joiner"
                                                : index === 5
                                                  ? `Serving Notice Period`
                                                  : `${index} Months`,
                                      label:
                                        index === 0
                                          ? `15 Days or less`
                                          : index === 4
                                            ? `More than 3 Month`
                                            : index === 1
                                              ? `${index} Month`
                                              : index === 2
                                                ? "Immediate Joiner"
                                                : index === 5
                                                  ? `Serving Notice Period`
                                                  : `${index} Months`,
                                    })
                                  )}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="w-full items-center ">
                            <div className="w-full">
                              <Form.Item
                                name="lastworkingday"
                                label="Last Working Day"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please select your last working day",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="w-full"
                                  size="large"
                                //minDate={dayjs(new Date())}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {!isShowUploadFile ? (
                      <div className="md:flex md:flex-wrap py-2">
                        <div className="md:w-1/2 px-3">
                          <Form.Item
                            name="Resume"
                            label=" Upload Resume"
                            rules={[
                              {
                                required: true,
                                message: "Please upload your resume",
                              },
                              {
                                validator: (_, file) => {
                                  if (
                                    !file ||
                                    file.length === 0 ||
                                    typeof (CandidateForm.getFieldValue('Resume')) === 'string'
                                  ) {
                                    return Promise.resolve();
                                  }
                                  const format = file.file.type;
                                  const acceptedFormats = [
                                    "application/pdf",
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                    "application/msword",
                                  ];
                                  // const fileExtension = ;
                                  if (!acceptedFormats.includes(format)) {
                                    return Promise.reject(
                                      "You can only upload PDF, DOC, or DOCX files!"
                                    );
                                  }
                                  if (!acceptedFormats.includes(format)) {
                                    return Upload.LIST_IGNORE;
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Upload
                              beforeUpload={() => false}
                              name="file"
                              listType="picture"
                              maxCount={1}
                              accept=".pdf, .doc, .docx"
                              onChange={(file) => handleUpload(file)}
                            >
                              <Button icon={<UploadOutlined />}> Upload</Button>
                            </Upload>
                          </Form.Item>
                          <span className=" text-gray-500 flex pt-2.5     ">
                            <ExclamationCircleOutlined className="text-lg" /> &nbsp;
                            supported files .pdf, .doc, .docx
                          </span>
                          {!isUploading && resumefromResponse != "" && (
                            <div
                              className="file-card cursor-pointer"
                              style={{ width: "max-content" }}
                              onClick={() => window.open(resumeURL)}
                            >
                              <FolderOpenOutlined className="text-lg" />
                              <span className="ml-2">{resumefromResponse}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2">
                        <div className="mt-2">Resume :</div>
                        <div
                          className="file-card flex-row"
                          style={{ width: "max-content" }}
                        >
                          <FolderOpenOutlined className="text-lg" />
                          <span className="ml-2">{resumeResponseObj?.resume?.name}</span>
                        </div>
                        <span
                          className="flex-end mt-3 text-lg cursor-pointer"
                          onClick={handleResumeRemove}
                        >
                          <MdDelete className="text-[#7c7c7cad] text-[20px]" />
                        </span>
                      </div>
                    )
                    }
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-5 mb-5 gap-3">
                <Form.Item>
                  <Button
                    className="btn save-btn"
                    type="primary"
                    htmlType="submit"
                    disabled={isLoading}
                    loading={isLoading}
                    iconPosition={"start"}
                  >
                    Submit
                  </Button>
                </Form.Item>
                <button
                  className="btn close-btn"
                  onClick={() => navigate("/candidatedashboard")}
                >
                  Back
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Grid>
    </>
  );
}

const styles = {
  header: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
    fontWeight: '500',
    color: '#403e6f'
  },
  cell: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  },
  button: {
    padding: '6px 12px',
    backgroundColor: '#f44336',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  },
};
