import React, { useState, useEffect, useMemo, useRef } from "react";
import { Form, Button, Modal, notification, Input, Drawer, Tooltip, Tabs } from "antd";
import { MdStickyNote2 } from "react-icons/md";
import { Breadcrumb } from "antd";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import CommonTable from "./CommonTable";
import * as moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { staffingUrl } from "../interceptor";
import { useMsal } from "@azure/msal-react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Chat from "../dashboard/components/Chat";
import Log from "../dashboard/components/Log";
import Scheduler from "../dashboard/components/Scheduler";
import { PiToolboxLight } from "react-icons/pi";
import { SlLocationPin } from "react-icons/sl";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BsDownload } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import ContentLoader from 'react-content-loader';

dayjs.extend(utc);

export default function CandidateInfo() {

  const [api, contextHolder] = notification.useNotification();
  const { instance } = useMsal();
  const accDetails = instance.getActiveAccount();
  const userName = accDetails?.name;
  const Userid = accDetails["username"];

  const navigate = useNavigate();
  const { id } = useParams();

  const [dataList, setDataList] = useState([]);
  const [globalDataList, setGlobalDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [candidate, setCandidate] = useState([]);
  const [count, setCount] = useState({
    submission_count: 0,
    interview_count: 0,
    selected_count: 0,
    onboard_count: 0,
    purchase_order_count: 0
  });
  const [candidateFilterValue, setCandidateFilterValue] = useState("Submitted");
  const [mentionedEmpId, setMentionedEmpId] = useState("");
  const [mentionedReqId, setMentionedReqId] = useState("");
  const [employeeLists, setEmployeeLists] = useState([]);
  const [reqLists, setReqLists] = useState([]);
  const [logData, setLogData] = useState([]);
  const [selectedCandidateDetails, setSelectedCandidateDetails] = useState({})
  const [showDetail, setShowDetail] = useState(false);
  const [backupLogData, setBackupLogData] = useState([]);
  const [filterValue, setFilterValue] = useState("All");
  const [billingIn, setBillingIn] = useState([]);

  const size = "small";
  const columnVisibilityModel = {
    "s_no": false,
    "name": true,
    "skill": true,
    "experience": true,
    "status": true,
    "date": true,
  }

  const requirementTitle = () => {
    return (
      <>
        <div className="w-full">
          <Tabs
            type="card"
            size={size}
            // tabBarExtraContent={{
            //   right: <Scheduler id={id} />,
            // }}
            items={items}
          />
        </div>
      </>
    );
  };

  const openNotificationWithIcon = (req) => {
    api[req?.color]({
      message: req?.Title,
      description: req?.description,
      duration: req?.duration
    });
  };

  const getDiscussion = async () => {
    try {
      const getCommentLog = await staffingUrl.get(`api/Log/getTalentEventLogs/0/${id}`);

      const commentData = getCommentLog.data.data.reverse();
      const alterCmtData = commentData.map(item => ({ ...item, createdDate: item.occuredAt.split('T')[0] }));

      const mergeData = [...alterCmtData];
      const alteredMergeData = Object.groupBy(mergeData, ({ createdDate }) => createdDate);

      const sortMergedData = Object.keys(alteredMergeData)
        .sort((a, b) => b.localeCompare(a))
        .reduce((acc, key) => {
          acc[key] = alteredMergeData[key];
          return acc;
        }, {});

      setLogData(sortMergedData);
      setBackupLogData(sortMergedData);
    } catch (error) {
      console.log(error);
    }
  }

  const handleCandidateFilter = (val) => {
    setCandidateFilterValue(val)
    if (val === "Submitted") {
      setDataList(globalDataList);
    } else {
      setDataList(globalDataList.filter(ls => ls.candidate_status === val));
    }
  };

  const formatDate = (createdDate) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - new Date(createdDate?.split("Z")[0])) / 1000);

    const seconds = diffInSeconds;
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return "Just now";
    } else if (minutes < 60) {
      return minutes + " mins ago";
    } else if (hours < 24) {
      return hours + " hrs ago";
    } else {
      return `${createdDate.split('T')[0]} ${createdDate.split('T')[1].split(':')[0]}:${createdDate.split('T')[1].split(':')[1]}`
    }
  }

  const fetchReport = async () => {
    try {
      await staffingUrl.get(`api/StaffMgnt/getCandidatesList`).then(async (res) => {
        setCandidate(res.data);

        const getReqs = res.data.map(item => {
          if (item.candidate_id === parseInt(id)) {
            return !!item.Associated_Requirements && item.Associated_Requirements.map(item2 => ({
              value: item2.req_title,
              label: item2.req_title,
              req_id: item2.req_id
            }))
          }
        }).filter(item => item).flat();

        const req_id = res?.data?.find((item) => item.candidate_id === parseInt(id));

        await staffingUrl
          .get(`api/StaffMgnt/getRequirementbyID?id=${req_id.billable_in_req || 0}`)
          .then((res) => {
            setBillingIn(res.data?.Requirement_title);
          });

        setReqLists(getReqs);
      }).catch((err) => { console.log(err); });

      await staffingUrl
        .get(`api/StaffMgnt/getEmployees/0`)
        .then((res) => {
          let empList = res?.data
            ?.filter((a) => a.position === 'Talent Team')
            .map((a) => ({
              value: a.username,
              label: a.username,
              emp_id: a.Emp_id
            }));
          setEmployeeLists(empList);
        })
        .catch((err) => {
          console.log(err);
        });

      await getDiscussion();

      const fetchData = await staffingUrl.get(`api/StaffMgnt/Candidatereport`);

      const filteredCandidates = fetchData.data.list?.filter(item => item.candidate_id == parseInt(id));
      const statusValue = [];
      filteredCandidates?.forEach((item) => {
        if (item.candidate_status === 1) {
          statusValue.push({ ...item, candidate_status: "Submitted" })
        } else if (item.candidate_status === 2) {
          statusValue.push({ ...item, candidate_status: "Interview" })
        } else if (item.candidate_status === 3) {
          statusValue.push({ ...item, candidate_status: "Selected" })
        } else if (item.candidate_status === 4) {
          statusValue.push({ ...item, candidate_status: "Onboard" })
        } else if (item.candidate_status === 5) {
          statusValue.push({ ...item, candidate_status: "Purchase order" })
        } else if (item.candidate_status === 6) {
          statusValue.push({ ...item, candidate_status: "Onhold" })
        } else if (item.candidate_status === 7) {
          statusValue.push({ ...item, candidate_status: "Rejected" })
        } else if (item.candidate_status === 8) {
          statusValue.push({ ...item, candidate_status: "Screening Rejected" })
        } else if (item.candidate_status === 9) {
          statusValue.push({ ...item, candidate_status: "Internal Pre-Screening" })
        } else if (item.candidate_status === 10) {
          statusValue.push({ ...item, candidate_status: "Yet to Contact" })
        }
      });

      statusValue.map((item) => {
        if (item.req_status === 1) {
          item.req_status = "Ongoing"
        } else if (item.req_status === 2) {
          item.req_status = "Hold"
        } else if (item.req_status === 3) {
          item.req_status = "Closed"
        } else if (item.req_status === 4) {
          item.req_status = "Yet to Start"
        }
      });

      setCount({
        submission_count: statusValue?.length,
        interview_count: statusValue?.filter(item => item?.candidate_status === "Interview")?.length,
        selected_count: statusValue?.filter(item => item?.candidate_status === "Selected")?.length,
        onboard_count: statusValue?.filter(item => item?.candidate_status === "Onboard")?.length,
        purchase_order_count: statusValue?.filter(item => item?.candidate_status === "Purchase order")?.length
      });

      setGlobalDataList(statusValue)
      setDataList(statusValue);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  const handlefilterLog = (id, name) => {
    if (id === 0 && name === "all") {
      setLogData(backupLogData);
    } else {
      let mergedData = {};
      Object.entries(backupLogData).forEach(([key, value]) => {
        let filteredItems = [];
        value?.forEach((item) => {
          if (item?.actionEvent === "Discussion Log") {
            JSON.parse(item.changes).forEach((candidate) => {
              if (candidate?.mentionedReq === name) {
                filteredItems.push(item);
              }
            });
          } else if (
            item?.actionEvent === "Candidate Mapping"
            || item?.actionEvent === "Candidate Removing"
            || item?.actionEvent === "Candidate Status Change"
            || item?.actionEvent === "Interview Scheduled"
            || item?.actionEvent === "Interview Status Change"
          ) {
            JSON.parse(item.changes).forEach((candidate) => {
              if (candidate?.req_id === id) {
                filteredItems.push(item);
              }
            })
          }
        });

        if (filteredItems.length > 0) {
          mergedData[key] = filteredItems;
        }
      });
      setLogData(mergedData)
    }
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "s_no",
        header: "S.No",
        enableSorting: false,
        enableColumnFilter: false,
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "req_title",
        header: "Requirement Title",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          return (
            <>
              <div onClick={() => {
                navigate(`/requirement/info/${row.original.req_id}`);
              }} style={{
                color: "#6c5295",
                fontWeight: "500",
                display: 'flex',
                justifyContent: 'center'
              }}>
                {row.original.req_title || "-"}
              </div>
              {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
            {row.original.req_title || "-"}
          </div> */}
            </>
          )
        }
      },
      {
        accessorKey: "customer",
        header: "Customer",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          return <div style={{ display: 'flex', justifyContent: 'center' }}>
            {row.original.customer || "-"}
          </div>
        }
      },
      {
        accessorKey: "req_status",
        header: "Requirement Status",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          return <div style={{ display: 'flex', justifyContent: 'center' }}>
            {row.original.req_status || "-"}
          </div>
        }
      },
      {
        accessorKey: "candidate_status",
        header: "Candidate Status",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          let backgroundColor = '';
          let borderColor = '';
          let textColor = '#000';
          switch (row.original.candidate_status) {
            case 'Purchase order':
              backgroundColor = '#f17a2533';
              borderColor = '#f17a25';
              textColor = '#f17a25';
              break;
            case 'Onboard':
              backgroundColor = '#9b59b647';
              borderColor = '#9b59b6';
              textColor = '#9b59b6';
              break;
            case 'Interview':
              backgroundColor = '#3498db33';
              borderColor = '#3498db';
              textColor = '#3498db';
              break;
            case 'Selected':
              backgroundColor = '#82f28245';
              borderColor = '#0e890e';
              textColor = '#0e890e';
              break;
            case 'Rejected':
              backgroundColor = '#f64d4d4a';
              borderColor = '#e82929';
              textColor = '#e82929';
              break;
            case 'Onhold':
              backgroundColor = '#3366ff26';
              borderColor = '#3366ff';
              textColor = '#3366ff';
              break;
            default:
              backgroundColor = 'transparent';
              borderColor = 'gray';
          }
          return <div style={{
            backgroundColor,
            border: `1px solid ${borderColor}`,
            padding: '3px 5px 2px',
            color: textColor,
            textAlign: 'center',
            borderRadius: '5px'
          }}>
            {row.original.candidate_status}
          </div>
        }
      },
      {
        accessorKey: "created_date",
        header: "Mapped Date",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          return <div style={{ display: 'flex', justifyContent: 'center' }}>
            {row.original.created_date.substring(0, 10)}
          </div>
        }
      },
    ],
    [dataList]
  );

  const items = [
    {
      key: "2",
      label: "Log Note",
      children: (
        <>
          <Chat
            employeeLists={employeeLists}
            reqLists={reqLists}
            mentionedEmpId={mentionedEmpId}
            mentionedReqId={mentionedReqId}
            setMentionedEmpId={setMentionedEmpId}
            setMentionedReqId={setMentionedReqId}
            getDiscussion={getDiscussion}
            id={id}
            openNotificationWithIcon={openNotificationWithIcon}
            from={"candidate"}
            setFilterValue={setFilterValue}
          />
          <Log
            logData={logData}
            from={"candidate"}
            dataList={dataList}
            handlefilterLog={handlefilterLog}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
          />
        </>
      ),
    },
  ];

  const handleCandidateEdit = () => {
    navigate(`/candidatedashboard/${id}`);
  }

  useEffect(() => {
    fetchReport();
  }, []);

  useEffect(() => {
    if (!!candidate && id !== undefined) {
      const findCandidate = candidate.find(item => item.candidate_id === parseInt(id));
      setSelectedCandidateDetails(findCandidate);
    }
  }, [candidate, id]);

  return (
    <>
      <div style={{ display: "flex", gap: "1em" }}>
        <div className="w-[70%] border-r border-gray-300">
          {contextHolder}
          <Grid>
            <Breadcrumb
              items={[
                {
                  title: (
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        navigate("/candidatedashboard");
                      }}
                    >
                      Candidate Dashboard
                    </span>
                  ),
                },
                {
                  title: "Candidate Info",
                },
              ]}
            />
          </Grid>
          <Grid className="p-5 mt-3 mr-4" style={{ borderRadius: '5px', background: '#fff', border: '1px solid #dddddd99' }}>
            {isLoading
              ? (
                <div className="overflow-hidden">
                  <ContentLoader
                    speed={2}
                    width={900}
                    height={200}
                    viewBox="0 0 900 200"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="3" ry="3" width="100%" height="14" />
                    <rect x="0" y="20" rx="3" ry="3" width="90" height="10" />
                    <rect x="0" y="40" rx="3" ry="3" width="100%" height="8" />
                    <rect x="0" y="60" rx="3" ry="3" width="70" height="10" />
                    <rect x="0" y="80" rx="3" ry="3" width="100%" height="8" />
                    <rect x="0" y="100" rx="3" ry="3" width="90" height="10" />
                    <rect x="0" y="120" rx="3" ry="3" width="100%" height="10" />
                    <rect x="0" y="140" rx="3" ry="3" width="120" height="10" />
                    <rect x="0" y="160" rx="3" ry="3" width="150" height="12" />
                    <rect x="0" y="180" rx="3" ry="3" width="100%" height="14" />
                    <rect x="0" y="200" rx="3" ry="3" width="80" height="10" />
                    <rect x="0" y="220" rx="3" ry="3" width="100%" height="8" />
                    <rect x="0" y="240" rx="3" ry="3" width="80" height="10" />
                    <rect x="0" y="260" rx="3" ry="3" width="100%" height="8" />
                    <rect x="0" y="280" rx="3" ry="3" width="80" height="10" />
                  </ContentLoader>
                </div>
              )
              : (
                <>
                  <div className="flex items-center justify-between capitalize">
                    <div className="flex gap-4 items-center">
                      <p style={{ fontSize: '16px', fontWeight: '600' }}>
                        {selectedCandidateDetails?.firstname} {selectedCandidateDetails?.lastname}
                      </p>
                      <p
                        className="text-xs flex items-center gap-1"
                      >
                        <span className={`${selectedCandidateDetails?.is_enabled ? 'bg-green-600' : 'bg-red-600'} w-2.5 h-2.5 inline-block rounded-full`}></span>
                        {selectedCandidateDetails?.is_enabled ? 'Active' : 'In-active'}
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <p
                        style={{
                          fontSize: '12px',
                          border: `
                    ${selectedCandidateDetails?.employee_status === "Non-Billable"
                              ? '1px solid #f64605'
                              : selectedCandidateDetails?.employee_status === "Fresher"
                                ? '1px solid #0e890e'
                                : selectedCandidateDetails?.employee_status === "Market"
                                  ? '1px solid #0e890e'
                                  : '1px solid #0e890e'
                            }`,
                          background: `
                    ${selectedCandidateDetails?.employee_status === "Non-Billable"
                              ? '#f646052e'
                              : selectedCandidateDetails?.employee_status === "Fresher"
                                ? '#82f28245'
                                : selectedCandidateDetails?.employee_status === "Market"
                                  ? '#82f28245'
                                  : '#82f28245'
                            }`,
                          color: `
                    ${selectedCandidateDetails?.employee_status === "Non-Billable"
                              ? '#f64605'
                              : selectedCandidateDetails?.employee_status === "Fresher"
                                ? '#0e890e'
                                : selectedCandidateDetails?.employee_status === "Market"
                                  ? '#0e890e'
                                  : '#0e890e'
                            }`,
                          padding: '3px 10px',
                          borderRadius: '5px'
                        }}
                      >
                        {
                          selectedCandidateDetails?.employee_status === "Non-Billable"
                            ? "Non Billing"
                            : selectedCandidateDetails?.employee_status === "Fresher"
                              ? "Fresher"
                              : selectedCandidateDetails?.employee_status === "Market"
                                ? "Market"
                                : !!billingIn && billingIn.length > 0
                                  ? `Billing in: ${billingIn}`
                                  : "Billable"
                        }
                      </p>
                      <p
                        style={{
                          fontSize: '12px',
                          border: `
                    ${selectedCandidateDetails?.employee_type === "Internal"
                              ? '1px solid #479db5'
                              : selectedCandidateDetails?.employee_type === "External"
                                ? '1px solid #f17a25'
                                : '1px solid #3366ff'
                            }`,
                          background: `
                    ${selectedCandidateDetails?.employee_type === "Internal"
                              ? '#479db533'
                              : selectedCandidateDetails?.employee_type === "External"
                                ? '#f17a2533'
                                : '#e1e8ff'
                            }`,
                          color: `
                    ${selectedCandidateDetails?.employee_type === "Internal"
                              ? '#479db5'
                              : selectedCandidateDetails?.employee_type === "External"
                                ? '#f17a25'
                                : '#3366ff'
                            }`,
                          padding: '3px 10px',
                          borderRadius: '5px'
                        }}
                      >
                        {selectedCandidateDetails?.employee_type || "-"}
                      </p>
                      <p className="cursor-pointer" onClick={handleCandidateEdit} style={{ fontSize: '16px', color: "#3366FF", border: '1px solid #3366FF', padding: '3px 10px', borderRadius: '5px' }}><FiEdit /></p>
                    </div>
                  </div>
                  <div className="mt-3 flex items-center gap-2">
                    <div className="flex items-center gap-2">
                      <PiToolboxLight style={{ color: '#717b9e', fontSize: '18px' }} />
                      <p style={{ fontSize: '12px', fontWeight: '500', color: '#474d6a' }}>{selectedCandidateDetails?.yoe?.substring(0, 2)} Yrs</p>
                    </div>
                    <div style={{ fontSize: '14px', color: '#e7e7f1', fontWeight: '300' }}>|</div>
                    <div className="flex items-center gap-2">
                      <SlLocationPin style={{ color: '#717b9e', fontSize: '16px' }} />
                      <p style={{ fontSize: '12px', fontWeight: '500', color: '#474d6a' }}>{selectedCandidateDetails?.city || "-"}</p>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#e7e7f1",
                        fontWeight: "300",
                      }}
                    >
                      |
                    </div>
                    <p style={{ fontSize: '13px', fontWeight: '500', color: '#474d6a' }}>ECTC :</p>
                    <p style={{ fontSize: '12px', fontWeight: '500', color: '#474d6a' }}>{selectedCandidateDetails?.expected_ctc || "-"}</p>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#e7e7f1",
                        fontWeight: "300",
                      }}
                    >
                      |
                    </div>
                    <p style={{ fontSize: '13px', fontWeight: '500', color: '#474d6a' }}>CCTC :</p>
                    <p style={{ fontSize: '12px', fontWeight: '500', color: '#474d6a' }}>{selectedCandidateDetails?.current_ctc || "-"}</p>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#e7e7f1",
                        fontWeight: "300",
                      }}
                    >
                      |
                    </div>
                    <p style={{ fontSize: '13px', fontWeight: '500', color: '#474d6a' }}>Work Mode :</p>
                    <p style={{ fontSize: '12px', fontWeight: '500', color: '#474d6a' }}>{selectedCandidateDetails?.work_mode || "-"}</p>
                  </div>
                  <div className="mt-3">
                    <div className="flex items-center gap-2">
                      <p style={{ fontSize: '13px', fontWeight: '500', color: '#474d6a', whiteSpace: 'nowrap' }}>Skill Set</p>
                      <div style={{ fontSize: '14px', color: '#e7e7f1', fontWeight: '300' }}>|</div>
                      <div className="flex flex-wrap flex-auto gap-2">
                        {selectedCandidateDetails?.skill_set
                          ?.split(",")
                          .filter((skill) => skill.trim() !== "").length > 0 ? (
                          selectedCandidateDetails.skill_set
                            .split(",")
                            .map((skill, index) => (
                              <p
                                key={index}
                                style={{
                                  fontSize: "12px",
                                  border: "1px solid #ddd",
                                  background: "#dddddd57",
                                  color: "#474d6a",
                                  padding: "3px 10px",
                                  borderRadius: "15px",
                                  fontWeight: "500",
                                  whiteSpace: "nowrap",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {skill.trim()}
                              </p>
                            ))
                        ) : (
                          <p
                            style={{
                              fontSize: "12px",
                              padding: "3px 10px",
                              fontWeight: "500",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            -
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="flex items-center gap-2">
                        <p style={{ fontSize: '13px', fontWeight: '500', color: '#474d6a', whiteSpace: 'nowrap' }}>Prefered Locations</p>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#e7e7f1",
                            fontWeight: "300",
                            whiteSpace: 'nowrap'
                          }}
                        >
                          |
                        </div>
                        <div>
                          <p style={{ fontSize: '12px', fontWeight: '500', color: '#474d6a' }}>{selectedCandidateDetails?.preferred_state}</p>
                          <p style={{ fontSize: '12px', fontWeight: '500', color: '#474d6a' }}>{selectedCandidateDetails?.preferred_country}</p>
                          <p style={{ fontSize: '12px', fontWeight: '500', color: '#474d6a' }}>{selectedCandidateDetails?.preferred_city}</p>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <div className="flex items-center gap-2">
                      <div className="flex items-center gap-2">
                        <FaPhoneAlt style={{ color: "#717b9e", fontSize: "12px" }} />
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#474d6a",
                          }}
                        >
                          {selectedCandidateDetails?.contact_no || "-"}
                        </p>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#e7e7f1",
                          fontWeight: "300",
                        }}
                      >
                        |
                      </div>
                      <div className="flex items-center gap-2">
                        <MdEmail style={{ color: "#717b9e", fontSize: "16px" }} />
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#474d6a",
                          }}
                        >
                          {selectedCandidateDetails?.email_id || "-"}
                        </p>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#e7e7f1",
                          fontWeight: "300",
                        }}
                      >
                        |
                      </div>
                      <div className="cursor-pointer flex flex-row"
                        onClick={() => window.open(selectedCandidateDetails?.resume)}
                      >
                        <BsDownload
                        />
                        <div className="mx-2.5">
                          <p className="capitalize" style={{ fontSize: '12px', fontWeight: '500', color: '#474d6a' }}>{selectedCandidateDetails?.resume?.split("/")?.pop()}</p>
                        </div>
                      </div>
                    </div>
                    <p
                      onClick={() => setShowDetail(!showDetail)}
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#6c5295",
                        cursor: "pointer",
                      }}
                    >
                      {showDetail ? (
                        <span className="close-detail">Close Experience</span>
                      ) : (
                        <span className="view-detail">View Experience</span>
                      )}
                    </p>
                  </div>
                  <div className="mt-3">
                    {showDetail && (
                      <div className="flex items-start gap-2">
                        {/* <LuClipboardList style={{ color: "#717b9e", fontSize: "22px" }} /> */}
                        {selectedCandidateDetails?.Previous_comp_details?.length > 0 ? (
                          <span style={{ fontSize: "13px", fontWeight: "400", color: "#474d6a" }}>
                            <div className="experience-table">
                              <table>
                                <thead>
                                  <tr>
                                    <th style={styles.header}>Company Name</th>
                                    <th style={styles.header}>Start Date</th>
                                    <th style={styles.header}>End Date</th>
                                    <th style={styles.header}>YOE</th>
                                    <th style={styles.header}>Role</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  {selectedCandidateDetails?.Previous_comp_details?.map((item, index) => (
                                    <tr key={index}>
                                      <td style={styles.cell}>
                                        {item?.prev_comp_name || '-'}
                                      </td>
                                      <td style={styles.cell}>
                                        {dayjs(item?.prev_comp_startdate).format("YYYY-MM-DD") || '-'}
                                      </td>
                                      <td style={styles.cell}>
                                        {dayjs(item?.prev_comp_enddate).format("YYYY-MM-DD") || '-'}
                                      </td>
                                      <td style={styles.cell}>
                                        {item?.prev_yoe || '-'}
                                      </td>
                                      <td style={styles.cell}>
                                        {item?.prev_role || '-'}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                          </span>
                        ) : (
                          <div className="label-text">No records to display</div>
                        )
                        }

                      </div>
                    )}
                  </div>
                </>
              )
            }
          </Grid>
          <Card sx={{ padding: "1em", paddingBottom: "2em", boxShadow: "none" }}>
            <Grid sx={{ background: "primary.main", marginTop: '0.2em' }} container spacing={2} style={{ paddingTop: '10px' }}>
              <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", alignItems: "left", justifyContent: "left" }}>
                <Typography component="div" variant="h6" fontWeight={500} sx={{ fontSize: "18px", marginTop: "-18px", whiteSpace: 'nowrap', marginBottom: "10px" }}>
                  Candidate Information
                </Typography>
              </Grid>
            </Grid>
            <Grid sx={{ background: "primary.main" }} container spacing={2} justifyContent="center">
              <Grid item xs={12} md={12}>
                <Card sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignContent: "stretch", justifyContent: "space-between" }}>
                  <Link
                    onClick={() => { handleCandidateFilter("Submitted") }}
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: "1 0 auto",
                        padding: "10px !important",
                        backgroundColor:
                          candidateFilterValue === "Submitted"
                            ? 'rgb(235 223 255)'
                            : '',
                        borderRadius:
                          candidateFilterValue === "Submitted"
                            ? '5px'
                            : ''
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                          <Typography
                            component="div"
                            sx={{ color: "#53596F", fontSize: "13px", fontWeight: "500", textAlign: 'center' }}
                          >
                            Mapping
                          </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '30%' }}>
                          {/* <FaPaperPlane style={{ fontSize: "18px", color: "#001F3F" }} /> */}
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="text.primary"
                              sx={{
                                color: "#808080",
                                fontSize: "20px",
                                lineHeight: "25px",
                              }}
                              component="h6"
                              fontWeight={600}
                            >
                              {count?.submission_count}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Link>
                  <Link
                    onClick={() => { handleCandidateFilter("Interview") }}
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: "1 0 auto",
                        padding: "10px !important",
                        backgroundColor:
                          candidateFilterValue === "Interview"
                            ? 'rgb(235 223 255)'
                            : '',
                        borderRadius:
                          candidateFilterValue === "Interview"
                            ? '5px'
                            : ''
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                          <Typography
                            component="div"
                            sx={{ color: "#53596F", fontSize: "13px", fontWeight: "500", textAlign: 'center' }}
                          >
                            Interview
                          </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '30%' }}>
                          {/* <GiDiscussion style={{ fontSize: "24px", color: "#3498db" }} /> */}
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="text.primary"
                              sx={{
                                color: "#3498db",
                                fontSize: "20px",
                                lineHeight: "25px",
                              }}
                              component="h6"
                              fontWeight={600}
                            >
                              {count?.interview_count}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Link>
                  <Link
                    onClick={() => { handleCandidateFilter("Selected") }}
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: "1 0 auto",
                        padding: "10px !important",
                        backgroundColor:
                          candidateFilterValue === "Selected"
                            ? 'rgb(235 223 255)'
                            : '',
                        borderRadius:
                          candidateFilterValue === "Selected"
                            ? '5px'
                            : ''
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                          <Typography
                            component="div"
                            sx={{ color: "#53596F", fontSize: "13px", fontWeight: "500", textAlign: 'center' }}
                          >
                            Selected
                          </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '30%' }}>
                          {/* <BsFillPersonCheckFill style={{ fontSize: "24px", color: "#27ae60" }} /> */}
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="text.primary"
                              sx={{
                                color: "#27ae60",
                                fontSize: "20px",
                                lineHeight: "25px",
                              }}
                              component="h6"
                              fontWeight={600}
                            >
                              {count?.selected_count}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Link>
                  <Link
                    onClick={() => { handleCandidateFilter("Onboard") }}
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: "1 0 auto",
                        padding: "10px !important",
                        backgroundColor:
                          candidateFilterValue === "Onboard"
                            ? 'rgb(235 223 255)'
                            : '',
                        borderRadius:
                          candidateFilterValue === "Onboard"
                            ? '5px'
                            : ''
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                          <Typography
                            component="div"
                            sx={{ color: "#53596F", fontSize: "13px", fontWeight: "500", textAlign: 'center' }}
                          >
                            Onboard
                          </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '30%' }}>
                          {/* <TbDiscountCheckFilled style={{ fontSize: "24px", color: "#9b59b6" }} /> */}
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="text.primary"
                              sx={{
                                color: "#9b59b6",
                                fontSize: "20px",
                                lineHeight: "25px",
                              }}
                              component="h6"
                              fontWeight={600}
                            >
                              {count?.onboard_count}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Link>
                  <Link
                    onClick={() => { handleCandidateFilter("Purchase order") }}
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: "1 0 auto",
                        padding: "10px !important",
                        backgroundColor:
                          candidateFilterValue === "Purchase order"
                            ? 'rgb(235 223 255)'
                            : '',
                        borderRadius:
                          candidateFilterValue === "Purchase order"
                            ? '5px'
                            : ''
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                          <Typography
                            component="div"
                            sx={{ color: "#53596F", fontSize: "13px", fontWeight: "500", textAlign: 'center', whiteSpace: 'nowrap' }}
                          >
                            Purchase Order
                          </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '30%' }}>
                          {/* <BsFillClipboard2DataFill style={{ fontSize: "24px", color: "#f39c12" }} /> */}
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="text.primary"
                              sx={{
                                color: "#f39c12",
                                fontSize: "20px",
                                lineHeight: "25px",
                              }}
                              component="h6"
                              fontWeight={600}
                            >
                              {count?.purchase_order_count}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Link>
                </Card>
              </Grid>
            </Grid>
            <Grid sx={{ background: "primary.main", marginTop: '1em' }}>
              <Card sx={{ boxShadow: "none" }}>
                <Grid
                  sx={{
                    background: "primary.main",
                    marginTop: "1em",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="requirment-table">
                    <CommonTable
                      data={dataList}
                      columns={columns}
                      tableKey={'Candidate_Info'}
                      isLoading={isLoading}
                      // customizeHeader={tableHead}
                      defaultColumn={columnVisibilityModel}
                      pinColumn={['candidate_id']}
                    />
                  </div>
                </Grid>
              </Card>
            </Grid>
          </Card>
        </div>
        <div className="chat-box flex-1 w-[29%] max-h-[calc(100vh-55px-1.5rem)]">
          {requirementTitle()}
        </div>
      </div>
    </>
  );
};

const styles = {
  header: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
    fontWeight: '500',
    color: '#403e6f'
  },
  cell: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  },
  button: {
    padding: '6px 12px',
    backgroundColor: '#f44336',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  },
};
