import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Select,
  Form,
  Button,
  Modal,
  notification,
  Input,
  Drawer,
  Tabs,
} from "antd";
import { MdStickyNote2, MdDelete, MdEditNote } from "react-icons/md";
import { Breadcrumb } from "antd";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import CommonTable from "./CommonTable";
import * as moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { staffingUrl } from "../interceptor";
import { useMsal } from "@azure/msal-react";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Chat from "../dashboard/components/Chat";
import Log from "../dashboard/components/Log";
import Scheduler from "../dashboard/components/Scheduler";
import { logger } from "../utils/logger";
import { PiToolboxLight } from "react-icons/pi";
import { SlLocationPin } from "react-icons/sl";
import { LuClipboardList } from "react-icons/lu";
import { FaUser } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { BsInfoCircle } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { SlClock } from "react-icons/sl";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { DatePicker, TimePicker } from 'antd';
import { FiEdit } from "react-icons/fi";
import ContentLoader from 'react-content-loader';

dayjs.extend(utc);

export default function RequirementInfo() {
  const [api, contextHolder] = notification.useNotification();
  const { confirm } = Modal;
  const { instance } = useMsal();
  const accDetails = instance.getActiveAccount();
  const userName = accDetails?.name;
  const Userid = accDetails["username"];

  const [candidateEditForm] = Form.useForm();
  const [interviewEditForm] = Form.useForm();

  const navigate = useNavigate();
  const { id } = useParams();

  const [cadidateMappingForm] = Form.useForm();

  const { TextArea } = Input;
  const size = "small";

  const [dataList, setDataList] = useState([]);
  const [globalDataList, setGlobalDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [reqList, setReqList] = useState();
  const [candidate, setCandidate] = useState([]);
  const [filterCandidate, setFilterCandidate] = useState([]);
  const [count, setCount] = useState([]);
  const [candidateFilterValue, setCandidateFilterValue] = useState(0);
  const [mentionedEmpId, setMentionedEmpId] = useState("");
  const [mentionedCandId, setMentionedCandId] = useState("");
  const [employeeLists, setEmployeeLists] = useState([]);
  const [candidateLists, setCandidateLists] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [logData, setLogData] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [candidateName, setCandidateName] = useState("");
  const [showSchudeledHistory, setShowScheduledHistory] = useState(2);
  const [interviewLevelOptions, setInterviewLevelOptions] = useState([]);
  const [interviewData, setInterviewData] = useState([]);
  const [showCandidateModal, setShowCandidateModal] = useState(false);
  const [interviewEditData, setInterviewEditData] = useState({});
  const [interviewStsOptions, setInterviewStsOptions] = useState([]);
  const [backupLogData, setBackupLogData] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("All");
  const [filteredSubmissionCount, setFilteredSubmissionCount] = useState([]);
  const [dropDownLoader, setDropDownLoader] = useState(true);

  const columnVisibilityModel = {
    s_no: false,
    firstname: true,
    lastname: true,
    year_of_exp: true,
    employee_type: true,
    work_mode: true,
    candidate_status: true,
    candidate_id: true,
  };

  const options = [
    { label: "Yet to Contact", value: 10 },
    { label: "Internal Pre-Screening", value: 9 },
    { label: "Submitted", value: 1 },
    { label: "Interview", value: 2 },
    { label: "Screening Rejected", value: 8 },
    { label: "Selected", value: 3 },
    { label: "Onboard", value: 4 },
    { label: "Purchase Order", value: 5 },
    { label: "Onhold", value: 6 },
    { label: "Rejected", value: 7 },
  ];

  const statusStyles = {
    1: {
      fontSize: "12px",
      border: "1px solid #0e890e",
      background: "#82f28245",
      color: "#0e890e",
      padding: "3px 10px",
      borderRadius: "5px",
    },
    2: {
      fontSize: "12px",
      border: "1px solid #0b53c8",
      background: "#0b53c826",
      color: "#0b53c8",
      padding: "3px 10px",
      borderRadius: "5px",
    },
    3: {
      fontSize: "12px",
      border: "1px solid #da1e26",
      background: "#da1e2630",
      color: "#da1e26",
      padding: "3px 10px",
      borderRadius: "5px",
    },
    default: {
      fontSize: "12px",
      border: "1px solid #3498db",
      background: "#3498db21",
      color: "#3498db",
      padding: "3px 10px",
      borderRadius: "5px",
    },
  };

  const status = reqList?.req_status;
  const priority = reqList?.priority;

  const priorityStyles = {
    High: {
      fontSize: "12px",
      border: "1px solid #f17a25",
      background: "#f17a2533",
      color: "#f17a25",
      padding: "3px 10px",
      borderRadius: "5px",
    },
    Medium: {
      fontSize: "12px",
      border: "1px solid #6c5295",
      background: "#f6f0ff",
      color: "#6c5295",
      padding: "3px 10px",
      borderRadius: "5px",
    },
    Low: {
      fontSize: "12px",
      border: "1px solid #e92926",
      background: "#e929263d",
      color: "#e92926",
      padding: "3px 10px",
      borderRadius: "5px",
    },
  };

  const requirementTitle = () => {
    return (
      <div className="w-full">
        <Tabs
          type="card"
          size={size}
          tabBarExtraContent={{
            right: (
              <Scheduler
                id={id}
                openNotificationWithIcon={openNotificationWithIcon}
                open={openActivityModal}
                setOpen={setOpenActivityModal}
                handlefilterLog={handlefilterLog}
                setFilterValue={setFilterValue}
              />
            ),
          }}
          items={items}
        />
      </div>
    );
  };

  const openNotificationWithIcon = (req) => {
    api[req?.color]({
      message: req?.Title,
      description: req?.description,
      duration: req?.duration,
    });
  };

  const getDiscussion = async () => {
    try {
      const getCommentLog = await staffingUrl.get(
        `api/StaffMgnt/getEmployeeDiscussionsonRequirement/0/sent/${id}`
      );

      const getActionLog = await staffingUrl.get(
        `api/Log/getTalentEventLogs/${id}/0`
      );

      const commentData = getCommentLog.data.data.reverse();
      const actionLogData = getActionLog.data.data.reverse();

      const alterCmtData = commentData.map((item) => ({
        ...item,
        createdDate: item.created_at.split("T")[0],
      }));

      const alterLogData = actionLogData.map((item) => ({
        ...item,
        createdDate: item.occuredAt.split("T")[0],
      }));

      const mergeData = [...alterCmtData, ...alterLogData];
      const sortMergeData = mergeData.sort((a, b) => {
        const dateA = a.occuredAt || a.created_at.split("Z")[0];
        const dateB = b.occuredAt || b.created_at.split("Z")[0];

        const timeA = new Date(dateA);
        const timeB = new Date(dateB);
        return timeB - timeA;
      });
      const alteredMergeData = Object.groupBy(
        sortMergeData,
        ({ createdDate }) => createdDate
      );

      const sortMergedData = Object.keys(alteredMergeData)
        .sort((a, b) => b.localeCompare(a))
        .reduce((acc, key) => {
          acc[key] = alteredMergeData[key];
          return acc;
        }, {});

      setLogData(sortMergedData);
      setBackupLogData(sortMergedData);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    setDropDownLoader(true);
    await staffingUrl
      .get(`api/StaffMgnt/getRequirementInfo?req_id=${id}`)
      .then((res) => {
        if (res.data.status == 1) {
          const candidates = res?.data?.req_details?.candidate_details.map(item => ({
            value: `${item.firstname} ${item.lastname}`,
            label: `${item.firstname} ${item.lastname}`,
            candidate_id: item.candidate_id,
          }));

          const candidateDetails = res?.data?.req_details?.candidate_details.map((data, index) => {
            return { ...data, s_no: index + 1 };
          });

          setCandidateLists(candidates);
          setDataList(candidateDetails);
          setGlobalDataList(candidateDetails);
          setReqList(res?.data?.req_details);
          setIsLoading(false);
        } else {
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "No data found",
            duration: 2,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    await staffingUrl
      .get(`api/StaffMgnt/getCandidatesList`)
      .then((res) => {
        setCandidate(res.data);
        setDropDownLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setDropDownLoader(false);
      });
    await staffingUrl
      .get(`api/StaffMgnt/listOfInterviewLevels`)
      .then((res) => {
        const interview_options = res.data?.data.map(item => ({
          label: item.level_name,
          value: `${item.level_name}_${item.level_id}`,
        }))
        setInterviewLevelOptions(interview_options);
      })
      .catch((err) => {
        console.log(err);
      });
    await staffingUrl
      .get(`api/StaffMgnt/listOfInterviewLevelStatus`)
      .then((res) => {
        const interview_sts_options = res.data?.data.map(item => ({
          label: item.level_name,
          value: `${item.level_name}_${item.level_id}`,
        }))
        setInterviewStsOptions(interview_sts_options);
      })
      .catch((err) => {
        console.log(err);
      });
    await staffingUrl
      .get(`api/StaffMgnt/getDashboardInfo/${id}/all/all`)
      .then((res) => {
        setCount(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    await getDiscussion();
    await staffingUrl
      .get(`api/StaffMgnt/getEmployees/0`)
      .then((res) => {
        let empList = res?.data
          ?.filter((a) => a.position === 'Talent Team')
          .map((a) => ({
            value: a.username,
            label: a.username,
            emp_id: a.Emp_id
          }));
        setEmployeeLists(empList);
      })
      .catch((err) => {
        console.log(err);
      });
    {/*
      await staffingUrl
        .get(`api/StaffMgnt/getEmployees/${id}`)
        .then((res) => {
          const responseData = res.data
            .map(
              (item) =>
                !!item.username && {
                  label: item.username,
                  value: `${item.username}`,
                  emp_id: item.Emp_id,
                }
            )
            .filter((item) => item);
          setEmployeeLists(responseData);
        })
        .catch((err) => { console.log(err);});
    */}
    setIsLoading(false);
  };

  const handleOk = () => {
    cadidateMappingForm.submit();
  };

  const handleCancel = () => {
    setOpen(false);
    setFilterCandidate([]);
  };

  const openCandidatePopup = () => {
    // cadidateMappingForm.setFieldValue("Skill_set", val?.split(",").map(a => a));
    cadidateMappingForm.setFieldValue("Candidate", undefined);
    candidateFilter();
    setOpen(true);
  };

  const candidateFilter = () => {
    let temp = candidate;
    const dataListIds = globalDataList?.map((item) => item?.candidate_id);
    const filteredTemp = temp?.filter(
      (candidate) => !dataListIds?.includes(candidate?.candidate_id) && candidate?.is_enabled === 1
    );
    setFilterCandidate(filteredTemp);
    if (filteredTemp.length == 0) {
      cadidateMappingForm.setFieldValue("Candidate", undefined);
    }
  };

  const CandidateMapping = async (values) => {
    setSubmitLoader(true);
    let templist = candidate.filter((data) => {
      return values?.Candidate.some(
        (a) => a.split("-")[0] === data?.candidate_id.toString()
      );
    });
    console.log(templist);
    let req = {
      req_id: id,
      candidate_details: templist,
      username: Userid,
    };

    await staffingUrl
      .post(`api/StaffMgnt/mapCandidateToReq`, req)
      .then((res) => {
        if (res.data.status === 1) {
          AfterCandidateMapping();
          handlefilterLog(0, "all");
          setFilterValue('All');
          openNotificationWithIcon({
            color: "success",
            Title: "Success",
            description: "Candidate added successfully",
            duration: 2,
          });
          const candidateNames = templist
            .map((item) => `${item.firstname} ${item.lastname}`)
            .join(", ");
          const candidate_ids = templist.map(item => item.candidate_id);

          const payload = {
            req_id: id,
            actionEvent: "Candidate Mapping",
            changedBy: Userid,
            username: userName,
            occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
            changes: [
              {
                content: `${candidateNames} ${candidateNames.split(",").length > 1 ? "are" : "is"
                  } mapped by ${userName}`,
                candidate_data: templist,
                candidate_ids
              },
            ],
          };
          logger(payload);

          candidate_ids.forEach(item => {
            const candidate_payload = {
              candidate_id: item,
              actionEvent: "Candidate Mapping",
              changedBy: Userid,
              username: userName,
              occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
              changes: [
                {
                  content: `In Requirement: #${id}-${reqList?.req_title}, ${candidateNames} ${candidateNames.split(",").length > 1 ? "are" : "is"
                    } mapped by ${userName}`,
                  candidate_data: templist,
                  candidate_ids,
                  req_id: id
                },
              ],
            };
            logger(candidate_payload);
          })

          setOpen(false);
          setSubmitLoader(false);
          getDiscussion();
        } else {
          setSubmitLoader(false);
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "Candidate not added successfully",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        setSubmitLoader(false);
        console.log(err);
      });
  };

  const AfterCandidateMapping = async () => {
    await staffingUrl
      .get(`api/StaffMgnt/getRequirementInfo?req_id=${id}`)
      .then((res) => {
        if (res.data.status == 1) {
          const candidates = res?.data?.req_details?.candidate_details.map(item => ({
            value: `${item.firstname} ${item.lastname}`,
            label: `${item.firstname} ${item.lastname}`,
            candidate_id: item.candidate_id,
          }));

          const candidateDetails = res?.data?.req_details?.candidate_details.map((data, index) => {
            return { ...data, s_no: index + 1 };
          });

          setCandidateLists(candidates);
          setGlobalDataList(candidateDetails);
          setDataList(candidateDetails);
          setReqList(res?.data?.req_details);
          setIsLoading(false);
        } else {
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "No data found",
            duration: 2,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    await staffingUrl
      .get(`api/StaffMgnt/getDashboardInfo/${id}/all/all`)
      .then((res) => {
        setCount(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const candidateInterviewHistory = async (data) => {
    try {
      const getHistory = await staffingUrl.get(`api/StaffMgnt/getInterviewStatus/${id}/${data.candidate_id}`);

      setShowCandidateModal(true);
      setShowScheduledHistory(1);
      setSelectedCandidate(data.candidate_id);
      setCandidateName(`${data.firstname} ${data.lastname}`);
      setInterviewData(getHistory.data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleChangeInterviewSts = (value, data, prevValue) => {
    const prevSts = interviewStsOptions.find(item => item.label === prevValue).value;
    console.log(data);

    interviewEditForm.setFieldValue("interview_status", value);
    interviewEditForm.setFieldValue("id", data.id);
    interviewEditForm.setFieldValue("candidate_id", data.candidate);
    interviewEditForm.setFieldValue("level_name", data.level_name);
    interviewEditForm.setFieldValue("level_id", data.level_id);
    interviewEditForm.setFieldValue("old_status", prevSts);
    setShowScheduledHistory(3);
  }

  const ChangeCandidateStatus = async (values, index, row) => {
    let req = {
      req_id: id,
      candidate_id: row.candidate_id,
      status: values,
      username: Userid,
    };

    let value = {
      values: values,
      index: index,
      row: row
    }
    setInterviewEditData(value)
    if (values === 2) {
      globalDataList.map((item) => {
        if (row.candidate_id === item.candidate_id) {
          setSelectedCandidate(item.candidate_id)
          setCandidateName(item.firstname)
        }
      })
      setShowCandidateModal(true);
      setShowScheduledHistory(2);
    }

    if (values !== 2) {
      await staffingUrl
        .post(`api/StaffMgnt/changeCandidatestatus`, req)
        .then(async (res) => {
          if (res.data.status === 1) {
            openNotificationWithIcon({
              color: "success",
              Title: "Success",
              description: "Candidate status updated successfully",
              duration: 2,
            });

            await staffingUrl
              .get(`api/StaffMgnt/getDashboardInfo/${id}/all/all`)
              .then((res) => {
                setCount(res.data);
              })
              .catch((err) => {
                console.log(err);
              });

            await staffingUrl
              .get(`api/StaffMgnt/getRequirementInfo?req_id=${id}`)
              .then((res) => {
                if (res.data.status == 1) {
                  const candidateInfo =
                    res?.data?.req_details?.candidate_details.map((item, index) => ({ ...item, s_no: index + 1 }));

                  setGlobalDataList(candidateInfo);
                  setCandidateFilterValue(candidateFilterValue);
                  if (candidateFilterValue === 0) {
                    setDataList(candidateInfo);
                  } else {
                    setDataList(candidateInfo.filter((ls) => ls.candidate_status === candidateFilterValue));
                  }
                  setReqList(res?.data?.req_details);
                }
              })
              .catch((err) => {
                console.log(err);
                setIsLoading(false);
              });

            const oldStatus = globalDataList.filter(item =>
              item.candidate_id === row.candidate_id)[0]?.candidate_status;
            const newStatus = values;

            const oldStsTxt = options.filter((item) =>
              item.value === oldStatus)[0]?.label;
            const newStsTxt = options.filter((item) =>
              item.value === newStatus)[0]?.label;

            const payload = {
              req_id: id,
              actionEvent: "Candidate Status Change",
              changedBy: Userid,
              username: userName,
              occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
              changes: [
                {
                  content: `${row.firstname} ${row.lastname}'s status changed from ${oldStsTxt} to ${newStsTxt} by ${userName}`,
                  candidate_id: row.candidate_id,
                  candidate_data: row,
                },
              ],
            };
            const candidate_payload = {
              candidate_id: row.candidate_id,
              actionEvent: "Candidate Status Change",
              changedBy: Userid,
              username: userName,
              occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
              changes: [
                {
                  content: `In Requirement: #${id}-${reqList?.req_title}, ${row.firstname} ${row.lastname}'s status changed from ${oldStsTxt} to ${newStsTxt} by ${userName}`,
                  candidate_id: row.candidate_id,
                  candidate_data: row,
                  req_id: id,
                },
              ],
            };
            await logger(payload);
            await logger(candidate_payload);
            getDiscussion();
          } else {
            openNotificationWithIcon({
              color: "warning",
              Title: "Warning",
              description: "Candidate status not updated",
              duration: 2,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const DeleteCandidate = async (candidate_id) => {
    await staffingUrl
      .get(
        `api/StaffMgnt/removeCandidate?req_id=${id}&candidate_id=${candidate_id}&status=${0}&username=${Userid}`
      )
      .then((res) => {
        if (res.data.status == 1) {
          openNotificationWithIcon({
            color: "success",
            Title: "Success",
            description: "Candidate removed successfully",
            duration: 2,
          });
          getData();
          let templist = candidate.filter(
            (item) => item?.candidate_id === candidate_id
          )[0];
          console.log(templist);
          const payload = {
            req_id: id,
            actionEvent: "Candidate Removing",
            changedBy: Userid,
            username: userName,
            occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
            changes: [
              {
                content: `${templist?.firstname} ${templist?.lastname} is removed by ${userName}`,
                candidate_data: templist,
              },
            ],
          };
          const candidate_payload = {
            candidate_id: candidate_id,
            actionEvent: "Candidate Removing",
            changedBy: Userid,
            username: userName,
            occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
            changes: [
              {
                content: `In Requirement: #${id}-${reqList?.req_title}, ${templist.firstname} ${templist.lastname} is removed by ${userName}`,
                candidate_data: templist,
                req_id: id,
              },
            ],
          };
          logger(payload);
          logger(candidate_payload);
          AfterCandidateMapping();
          getDiscussion();
        } else {
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "Failed to remove candidate",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showConfirm = (candidate_id) => {
    confirm({
      title: "Warning",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure want to remove this candidate?",
      onOk() {
        DeleteCandidate(candidate_id);
      },
      onCancel() { },
    });
  };

  const handleCandidateFilter = (val) => {
    setCandidateFilterValue(val);
    if (val === 0) {
      setDataList(globalDataList);
    } else if (val === 8) {
      setDataList(globalDataList.filter((item) => item.candidate_status !== 9 && item.candidate_status !== 10))
    } else {
      setDataList(globalDataList.filter((ls) => ls.candidate_status === val));
    }
  };

  const submitCandidateEditForm = async () => {
    candidateEditForm
      .validateFields()
      .then(async (values) => {
        setModalLoading(true);
        const formattedDate = dayjs(values.interview_date).format("YYYY-MM-DD");
        const formattedStartTime = dayjs(values.start_time).format("HH:mm");
        const formattedEndTime = dayjs(values.end_time).format("HH:mm");

        let Payload = {
          "req_id": id,
          "candidate_id": selectedCandidate,
          "level_id": parseInt(values.level.split("_")[1]),
          "level_name": values.level.split("_")[0],
          "interview_date": formattedDate,
          "interview_starttime": formattedStartTime,
          "interview_endtime": formattedEndTime,
          "interview_status_id": 6,
          "interview_status": "Scheduled",
          "status": 1,
          "created_at": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
          "updated_at": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS")
        }

        await staffingUrl.post('api/StaffMgnt/scheduleInterview', Payload)
          .then(async (res) => {
            if (res.data.status === 1) {
              setShowCandidateModal(true)
              openNotificationWithIcon({
                color: "success",
                Title: "Success",
                description: "Interview Scheduled successfully",
                duration: 2,
              });
              let req = {
                req_id: id,
                candidate_id: selectedCandidate,
                status: 2,
                username: Userid,
              };
              !!interviewEditData?.values
                && await staffingUrl
                  .post(`api/StaffMgnt/changeCandidatestatus`, req)
                  .then(async (res) => {
                    if (res.data.status === 1) {
                      await staffingUrl
                        .get(`api/StaffMgnt/getDashboardInfo/${id}/all/all`)
                        .then((res) => {
                          setCount(res.data);
                        })
                        .catch((err) => {
                          console.log(err);
                        });

                      await staffingUrl
                        .get(`api/StaffMgnt/getRequirementInfo?req_id=${id}`)
                        .then((res) => {
                          if (res.data.status == 1) {
                            const candidateInfo =
                              res?.data?.req_details?.candidate_details.map((item, index) => ({ ...item, s_no: interviewEditData.index + 1 }));

                            setGlobalDataList(candidateInfo);
                            setCandidateFilterValue(candidateFilterValue);
                            if (candidateFilterValue === 0) {
                              setDataList(candidateInfo);
                            } else {
                              setDataList(candidateInfo.filter((ls) => ls.candidate_status === candidateFilterValue));
                            }
                            setReqList(res?.data?.req_details);
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          setIsLoading(false);
                        });
                    } else {
                      openNotificationWithIcon({
                        color: "warning",
                        Title: "Warning",
                        description: "Candidate status not updated",
                        duration: 2,
                      });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });

              const oldStatus = globalDataList.filter(item =>
                item?.candidate_id === interviewEditData.row?.candidate_id)[0]?.candidate_status;

              const oldStsTxt = options.filter((item) =>
                item.value === oldStatus)[0]?.label;

              console.log(interviewEditData);
              const payload = {
                req_id: id,
                actionEvent: "Interview Scheduled",
                changedBy: Userid,
                username: userName,
                occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
                changes: [
                  {
                    content: oldStsTxt !== "Interview"
                      ? `${interviewEditData.row?.firstname} ${interviewEditData.row?.lastname}'s stage changed from ${oldStsTxt} to Interview by ${userName}`
                      : `${interviewEditData.row?.firstname} ${interviewEditData.row?.lastname}'s Interview status updated by ${userName}`,
                    candidate_id: interviewEditData.row?.candidate_id,
                    candidate_name: `${interviewEditData.row?.firstname} ${interviewEditData.row?.lastname}`,
                    candidate_data: interviewEditData.row,
                    interview_date: formattedDate,
                    start_time: formattedStartTime,
                    end_time: formattedEndTime,
                    level: values.level.split("_")[0],
                    level_id: parseInt(values.level.split("_")[1]),
                    interview_status_id: 6,
                    interview_status: "Scheduled",
                  },
                ],
              };

              const candidate_payload = {
                candidate_id: selectedCandidate,
                actionEvent: "Interview Scheduled",
                changedBy: Userid,
                username: userName,
                occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
                changes: [
                  {
                    content: oldStsTxt !== "Interview"
                      ? `In Requirement: #${id}-${reqList?.req_title}, ${interviewEditData.row?.firstname} ${interviewEditData.row?.lastname}'s stage changed from ${oldStsTxt} to Interview by ${userName}`
                      : `In Requirement: #${id}-${reqList?.req_title}, ${interviewEditData.row?.firstname} ${interviewEditData.row?.lastname}'s Interview status updated by ${userName}`,
                    candidate_id: interviewEditData.row?.candidate_id,
                    candidate_name: `${interviewEditData.row?.firstname} ${interviewEditData.row?.lastname}`,
                    candidate_data: interviewEditData.row,
                    interview_date: formattedDate,
                    start_time: formattedStartTime,
                    end_time: formattedEndTime,
                    level: values.level.split("_")[0],
                    level_id: parseInt(values.level.split("_")[1]),
                    interview_status_id: 6,
                    interview_status: "Scheduled",
                    req_id: id
                  },
                ],
              };
              await logger(payload);
              await logger(candidate_payload);
              getDiscussion();
            }
          }).catch((err) => {
            console.log(err);
          });
        candidateEditForm.resetFields();
        setShowCandidateModal(false);
        setModalLoading(false);
      }).catch((err) => {
        console.log(err);
        setModalLoading(false);
      })
  }

  const submitInterviewForm = async (values) => {
    try {
      setModalLoading(true);
      const payload = {
        id: values.id,
        interview_status_id: parseInt(values.interview_status.split("_")[1]),
        interview_status: values.interview_status.split("_")[0],
        interview_feedback: values.interview_feedback
      };

      const changeSts = await staffingUrl.post(`api/StaffMgnt/updateInterviewStatus`, payload);

      if (changeSts.data.status === 1) {
        openNotificationWithIcon({
          color: "success",
          Title: "Success",
          description: "Interview status changed successfully",
          duration: 2,
        });

        const getHistory = await staffingUrl.get(`api/StaffMgnt/getInterviewStatus/${id}/${values.candidate_id}`);
        setInterviewData(getHistory.data?.data);

        const payload = {
          req_id: id,
          actionEvent: "Interview Status Change",
          changedBy: Userid,
          username: userName,
          occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
          changes: [
            {
              content: `${candidateName}'s ${values.level_name} status changed from ${values.old_status.split("_")[0]} to ${values.interview_status.split("_")[0]} by ${userName}`,
              feedback: values.interview_feedback,
              level_id: values.level_id,
              level_name: values.level_name,
              old_status: values.old_status.split("_")[0],
              old_status_id: parseInt(values.old_status.split("_")[1]),
              new_status: values.interview_status.split("_")[0],
              new_status_id: parseInt(values.interview_status.split("_")[1]),
              candidate_id: selectedCandidate,
              candidate_name: candidateName
            },
          ],
        };

        const candidate_payload = {
          candidate_id: selectedCandidate,
          actionEvent: "Interview Status Change",
          changedBy: Userid,
          username: userName,
          occuredAt: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
          changes: [
            {
              content: `In Requirement: #${id}-${reqList?.req_title}, ${candidateName}'s ${values.level_name} status changed from ${values.old_status.split("_")[0]} to ${values.interview_status.split("_")[0]} by ${userName}`,
              feedback: values.interview_feedback,
              level_id: values.level_id,
              level_name: values.level_name,
              old_status: values.old_status.split("_")[0],
              old_status_id: parseInt(values.old_status.split("_")[1]),
              new_status: values.interview_status.split("_")[0],
              new_status_id: parseInt(values.interview_status.split("_")[1]),
              candidate_id: selectedCandidate,
              candidate_name: candidateName,
              req_id: id
            },
          ],
        };
        await logger(payload);
        await logger(candidate_payload);
        getDiscussion();
        interviewEditForm.resetFields();
        setShowScheduledHistory(1);
        setModalLoading(false);
      }
      setModalLoading(false);
    } catch (error) {
      setModalLoading(false);
      console.log(error);
    }
  }

  const handleCandidateCancel = () => {
    setShowCandidateModal(false);
    candidateEditForm.resetFields();
  }

  const handleRequirementEdit = () => {
    navigate(`/requirement/${id}`);
  }

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Ongoing";
      case 2:
        return "Hold";
      case 3:
        return "Closed";
      default:
        return "Yet to Start";
    }
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  const handleScheduledHistory = () => {
    setShowScheduledHistory(1);
  }

  const handleScheduleInterview = () => {
    setShowScheduledHistory(2);
  }

  const handlefilterLog = (id, name) => {
    if (id === 0 && name === "all") {
      setLogData(backupLogData);
    } else {
      let mergedData = {};
      Object.entries(backupLogData).forEach(([key, value]) => {
        let filteredItems = [];
        value?.forEach((item) => {
          if (item?.actionEvent === "Candidate Status Change") {
            JSON.parse(item.changes).forEach((candidate) => {
              if (candidate?.candidate_data?.candidate_id === parseInt(id)) {
                filteredItems.push(item);
              }
            });
          } else if (item?.actionEvent === "Discussion Log") {
            JSON.parse(item.changes).forEach((candidate) => {
              if (candidate?.mentionedCand === name) {
                filteredItems.push(item);
              }
            });
          } else if (item?.actionEvent === "Interview Scheduled") {
            JSON.parse(item.changes).forEach((candidate) => {
              if (candidate?.candidate_data?.candidate_id === parseInt(id)) {
                filteredItems.push(item);
              }
            });
          } else if (item?.actionEvent === "Candidate Removing") {
            JSON.parse(item.changes).forEach((candidate) => {
              if (candidate?.candidate_data?.candidate_id === parseInt(id)) {
                filteredItems.push(item);
              }
            })
          } else if (item?.actionEvent === "Candidate Mapping") {
            JSON.parse(item.changes).forEach((candidate) => {
              candidate?.candidate_data.map((candidateId) => {
                if (candidateId?.candidate_id === parseInt(id)) {
                  filteredItems.push(item);
                }
              })
            })
          } else if (item?.actionEvent === "Interview Status Change") {
            JSON.parse(item.changes).forEach((candidate) => {
              if (candidate?.candidate_data?.candidate_id === parseInt(id)) {
                filteredItems.push(item);
              }
            })
          }
        });

        if (filteredItems.length > 0) {
          mergedData[key] = filteredItems;
        }
      });
      setLogData(mergedData)
    }
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "s_no",
        header: "S.No",
        enableSorting: false,
        enableColumnFilter: false,
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "firstname",
        header: "Name",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <div onClick={() => {
            navigate(`/candidateInfo/info/${row.original.candidate_id}`);
          }} style={{
            color: "#6c5295",
            fontWeight: "500",
          }}>
            {row.original.firstname} {row.original.lastname}
          </div>
        ),
      },
      {
        accessorKey: "skill_set",
        header: "Skill Set",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          return (
            // <p title={row.original.skill_set} className="w-44 text-ellipsis overflow-hidden">
            <Stack
              direction="row"
              spacing={1}
              className="w-44 flex"
              title={row.original.skill_set}
            >
              {row.original?.skill_set
                ?.split(",")
                .filter((skill) => skill.trim() !== "").length > 0 ? (
                row.original.skill_set
                  ?.split(",")
                  ?.map((item, i) => (
                    <Chip
                      label={item}
                      variant="outlined"
                      size="small"
                      key={i}
                    />
                  ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  -
                </div>
              )}
            </Stack>
            // </p>
          );
        },
      },
      {
        accessorKey: "submisson_date",
        header: "Submission Date",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <span>
            {moment(new Date(row.original.submisson_date)).format("DD-MM-YYYY")}
          </span>
        ),
      },
      {
        accessorKey: "candidate_status",
        header: "Status",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <div className="flex items-center gap-2">
            <Select
              className="w-full"
              size="small"
              placeholder="Select Status"
              value={row.original.candidate_status}
              onChange={(value) => ChangeCandidateStatus(value, row.index, row.original)}
            >
              {options?.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        ),
      },
      {
        accessorKey: "year_of_exp",
        header: "Experience",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          const experience = row.original.year_of_exp || "";
          const formattedExperience = experience
            .replace(/(\d+)\s*Years?/, "$1Y")
            .replace(/(\d+)\s*Months?/, "$1M")
            .replace(/undefined\s*Y?/, "")
            .replace(/undefined\s*M?/, "")
            .trim();
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {formattedExperience}
            </div>
          );
        },
      },
      // {
      //   accessorKey: "employee_type",
      //   header: "Employee Type",
      //   size: 100,
      //   muiTableHeadCellProps: {
      //     align: "left",
      //   },
      //   muiTableBodyCellProps: {
      //     align: "center",
      //   },
      // },
      // {
      //   accessorKey: "work_mode",
      //   header: "Work Mode",
      //   size: 100,
      //   muiTableHeadCellProps: {
      //     align: "left",
      //   },
      //   muiTableBodyCellProps: {
      //     align: "center",
      //   },
      // },
      {
        accessorKey: "candidate_id",
        header: "Action",
        enableSorting: false,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 50,
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: 10,
              }}
            >
              <Tooltip title="Interview Info" placement="bottom">
                <BsInfoCircle
                  color="#003263"
                  fontSize={16}
                  onClick={() => {
                    setInterviewEditData({
                      values: row.original.candidate_status,
                      index: row.index,
                      row: row.original
                    });
                    candidateInterviewHistory(row.original);
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete" placement="bottom">
                <MdDelete
                  onClick={() => {
                    showConfirm(row.original.candidate_id);
                  }}
                  style={{ color: "#FF3333", fontSize: "16px" }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [dataList, candidate]
  );

  const interviewColumns = useMemo(() => [
    // {
    //   accessorKey: "action",
    //   header: "Action",
    //   enableSorting: false,
    //   enableColumnFilter: false,
    //   size: 50,
    //   muiTableHeadCellProps: {
    //     align: "left",
    //   },
    //   muiTableBodyCellProps: {
    //     align: "center",
    //   },
    //   Cell: ({ row }) => {
    //     return (
    //       <div
    //         style={{
    //           display: "flex",
    //           gap: "10px",
    //           justifyContent: "center",
    //           alignItems: "center",
    //         }}
    //       >
    //         <Tooltip title="Edit" placement="bottom">
    //           <MdEditNote
    //             onClick={() => {
    //               console.log("Edit");
    //             }}
    //             style={{ color: "#3366FF", fontSize: "18px" }}
    //           />
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    {
      accessorKey: "level_name",
      header: "Level",
      enableSorting: false,
      enableColumnFilter: false,
      size: 60,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "interview_date",
      header: "Date",
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => (
        <div>{row.original.interview_date.split("T")[0]}</div>
      )
    },
    {
      accessorKey: "interview_starttime",
      header: "Start Time",
      enableSorting: false,
      enableColumnFilter: false,
      size: 50,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "interview_endtime",
      header: "End Time",
      enableSorting: false,
      enableColumnFilter: false,
      size: 50,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "interview_status",
      header: "Status",
      enableSorting: false,
      enableColumnFilter: false,
      size: 80,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => (
        <Select
          className="w-full"
          size="small"
          placeholder="Select Status"
          value={row.original.interview_status}
          onChange={(value) => handleChangeInterviewSts(value, row.original, row.original.interview_status)}
        >
          {interviewStsOptions?.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      accessorKey: "interview_feedback",
      header: "Feedback",
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => (
        <div>
          {
            (!!row.original.interview_feedback
              || row.original.interview_feedback === ""
            )
              ? row.original.interview_feedback
              : "-"
          }
        </div>
      )
    },
  ], [interviewData]);

  const tableHead = [
    <>
      <Button
        variant="contained"
        className="toolbutton"
        component="label"
        size="large"
        onClick={() => {
          openCandidatePopup();
        }}
      >
        <AddCircleOutlineIcon className="icontool" /> Add Candidate
      </Button>
    </>,
  ];

  const items = [
    // {
    //   key: "1",
    //   label: "Send Message",
    //   children: (
    //     <>
    //       <Chat showSender />
    //       <Log />
    //     </>
    //   ),
    // },
    {
      key: "2",
      label: "Log Note",
      children: (
        <>
          <Chat
            employeeLists={employeeLists}
            candidateLists={candidateLists}
            mentionedEmpId={mentionedEmpId}
            mentionedCandId={mentionedCandId}
            setMentionedEmpId={setMentionedEmpId}
            setMentionedCandId={setMentionedCandId}
            getDiscussion={getDiscussion}
            id={id}
            openNotificationWithIcon={openNotificationWithIcon}
            from={"requirement"}
            setFilterValue={setFilterValue}
          />
          <Log
            logData={logData}
            from={"requirement"}
            dataList={dataList}
            handlefilterLog={handlefilterLog}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let tempDataList = globalDataList.filter(item => !(item.candidate_status === 9) && !(item.candidate_status === 10))
    setFilteredSubmissionCount(tempDataList?.length)
  }, [globalDataList]);

  useEffect(() => {
    if (!openActivityModal) {
      getDiscussion();
    }
  }, [openActivityModal]);

  return (
    <>
      <div style={{ display: "flex", gap: "1em" }}>
        <div className="w-[70%] border-r border-gray-300">
          {contextHolder}
          <Grid>
            <Breadcrumb
              items={[
                {
                  title: (
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        navigate("/requirementdashboard");
                      }}
                    >
                      Requirement Dashboard
                    </span>
                  ),
                },
                {
                  title: "Requirement Info",
                },
              ]}
            />
          </Grid>
          <Grid
            sx={{ background: "primary.main", marginTop: "0.2em" }}
            container
            spacing={2}
            style={{ paddingTop: "10px" }}
          >
          </Grid>
          <Grid
            className="p-5 mr-4"
            style={{
              borderRadius: "5px",
              background: "#fff",
              border: "1px solid #dddddd99",
            }}
          >
            {isLoading
              ? (
                <div>
                  <ContentLoader
                    speed={2}
                    width={900}
                    height={200}
                    viewBox="0 0 900 200"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="3" ry="3" width="100%" height="14" />
                    <rect x="0" y="20" rx="3" ry="3" width="90" height="10" />
                    <rect x="0" y="40" rx="3" ry="3" width="100%" height="8" />
                    <rect x="0" y="60" rx="3" ry="3" width="70" height="10" />
                    <rect x="0" y="80" rx="3" ry="3" width="100%" height="8" />
                    <rect x="0" y="100" rx="3" ry="3" width="90" height="10" />
                    <rect x="0" y="120" rx="3" ry="3" width="100%" height="10" />
                    <rect x="0" y="140" rx="3" ry="3" width="120" height="10" />
                    <rect x="0" y="160" rx="3" ry="3" width="150" height="12" />
                    <rect x="0" y="180" rx="3" ry="3" width="100%" height="14" />
                    <rect x="0" y="200" rx="3" ry="3" width="80" height="10" />
                    <rect x="0" y="220" rx="3" ry="3" width="100%" height="8" />
                    <rect x="0" y="240" rx="3" ry="3" width="80" height="10" />
                    <rect x="0" y="260" rx="3" ry="3" width="100%" height="8" />
                    <rect x="0" y="280" rx="3" ry="3" width="80" height="10" />
                  </ContentLoader>
                </div>
              )
              : (
                <>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <p
                        component="div"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        {reqList?.req_title}
                      </p>
                      <p style={{ fontSize: "16px", fontWeight: "500" }}>-</p>
                      <p style={{ fontSize: "16px", fontWeight: "500" }}>
                        {reqList?.customer}
                      </p>
                    </div>

                    <div className="flex items-center gap-2">
                      <div className="flex items-center gap-1">
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#474d6a",
                          }}
                        >
                          Assigned to
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#1e1e1f",
                            padding: "3px 10px",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            background: "#f3f3f3",
                          }}
                        >
                          {reqList?.assigned_to === 0
                            ? "Unassigned"
                            : (() => {
                              const foundItem = employeeLists.find(
                                (item) => item.emp_id === reqList?.assigned_to
                              );
                              return foundItem ? foundItem.label : "Unassigned";
                            })()}
                        </p>
                      </div>
                      <p style={statusStyles[status] || statusStyles.default}>
                        {getStatusText(status)}
                      </p>
                      <p style={priorityStyles[priority] || priorityStyles.default}>
                        {priority}
                      </p>
                      <p className="cursor-pointer" onClick={handleRequirementEdit} style={{ fontSize: '16px', color: "#3366FF", border: '1px solid #3366FF', padding: '3px 10px', borderRadius: '5px' }}><FiEdit /></p>
                    </div>
                  </div>
                  <div className="mt-3 flex items-center gap-2">
                    <div className="flex items-center gap-2">
                      <PiToolboxLight
                        style={{ color: "#717b9e", fontSize: "18px" }}
                      />
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#474d6a",
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {reqList?.yoe}
                      </p>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#e7e7f1",
                        fontWeight: "300",
                      }}
                    >
                      |
                    </div>
                    <div className="flex items-center gap-2">
                      <SlClock style={{ color: "#717b9e", fontSize: "16px" }} />
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#474d6a",
                        }}
                      >
                        {reqList?.job_duration}
                      </p>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#e7e7f1",
                        fontWeight: "300",
                      }}
                    >
                      |
                    </div>
                    <div className="flex items-center gap-2">
                      <SlLocationPin style={{ color: "#717b9e", fontSize: "16px" }} />
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#474d6a",
                          maxWidth: '360px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {reqList?.city == "" ? "-" : reqList?.city}
                      </p>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#e7e7f1",
                        fontWeight: "300",
                      }}
                    >
                      |
                    </div>
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#474d6a",
                        fontWeight: "500",
                      }}
                    >
                      <span>Closed - </span>
                      <span>
                        {reqList?.position_closed_at.split(' ')[0] || "-"}
                      </span>
                    </p>
                  </div>
                  <div className="mt-3">
                    <div className="flex items-center gap-2">
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#474d6a",
                          whiteSpace: 'nowrap'
                        }}
                      >
                        Skill Set
                      </p>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#e7e7f1",
                          fontWeight: "300",
                        }}
                      >
                        |
                      </div>
                      <div className="flex flex-wrap flex-auto gap-2">
                        {reqList?.skill_set?.split(',').map((skill, i) => (
                          <p
                            key={i}
                            style={{
                              fontSize: "12px",
                              border: "1px solid #ddd",
                              background: "#dddddd57",
                              color: "#474d6a",
                              padding: "3px 10px",
                              borderRadius: "15px",
                              fontWeight: "500",
                              whiteSpace: 'nowrap',
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            {skill}
                          </p>
                        ))}
                      </div>

                    </div>
                  </div>
                  <div className="mt-3">
                    {showDetail && (
                      <div className="flex items-start gap-2 break-all">
                        <LuClipboardList
                          style={{ color: "#717b9e", fontSize: "22px" }}
                        />
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#474d6a",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: reqList?.job_description == "<p><br></p>"
                              ? " - "
                              : reqList?.job_description
                                .replace(
                                  new RegExp(`@${escapeRegExp(reqList?.job_description)}`, "g"),
                                  '<strong style="color: #6C5295; font-style: italic; font-size: 12px;">$&</strong>'
                                )
                                .replace(/\n/g, "<br />"),
                          }}
                        ></span>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <div className="flex items-center gap-2">
                      <div className="flex items-center gap-2">
                        <FaUser style={{ color: "#717b9e", fontSize: "14px" }} />
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#474d6a",
                          }}
                        >
                          {reqList?.contactPersonName || "-"}
                        </p>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#e7e7f1",
                          fontWeight: "300",
                        }}
                      >
                        |
                      </div>
                      <div className="flex items-center gap-2">
                        <FaPhoneAlt style={{ color: "#717b9e", fontSize: "12px" }} />
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#474d6a",
                          }}
                        >
                          {reqList?.contactPersonNo || "-"}
                        </p>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#e7e7f1",
                          fontWeight: "300",
                        }}
                      >
                        |
                      </div>
                      <div className="flex items-center gap-2">
                        <MdEmail style={{ color: "#717b9e", fontSize: "16px" }} />
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#474d6a",
                          }}
                        >
                          {reqList?.contactPersonEmail || "-"}
                        </p>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#e7e7f1",
                          fontWeight: "300",
                        }}
                      >
                        |
                      </div>

                      <button
                        className={!dropDownLoader ? 'cursor-pointer' : 'cursor-wait'}
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#6c5295",
                          padding: "3px 10px",
                          border: "1px solid #ddd",
                          borderRadius: '5px',
                          background: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px'
                        }}
                        disabled={dropDownLoader}
                        onClick={() => {
                          openCandidatePopup();
                        }}
                      ><span style={{ fontSize: '15px' }}>+</span><span>Candidate</span></button>
                    </div>
                    <p
                      onClick={() => setShowDetail(!showDetail)}
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#6c5295",
                        cursor: "pointer",
                      }}
                    >
                      {showDetail ? (
                        <span className="close-detail">Close Description</span>
                      ) : (
                        <span className="view-detail">View Description</span>
                      )}
                    </p>
                  </div>
                </>
              )
            }
          </Grid>
          <Card className="mt-4 mr-4" style={{ boxShadow: "none" }}>
            <Grid
              sx={{ background: "primary.main" }}
              container
              spacing={2}
              justifyContent="center"
            >
              <Grid item xs={12} md={12}>
                <Card
                  style={{ background: "#fff" }}
                  sx={{
                    border: "1px solid #ddd",
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignContent: "stretch",
                    justifyContent: "space-between",
                    marginBottom: "1em",
                  }}
                >
                  <Link
                    onClick={() => {
                      handleCandidateFilter(0);
                    }}
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: "1 0 auto",
                        padding: "10px !important",
                        backgroundColor:
                          candidateFilterValue === 0 ? "rgb(235 223 255)" : "",
                        borderRadius: candidateFilterValue === 0 ? "5px" : "",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography
                            component="div"
                            sx={{
                              color: "#53596F",
                              fontSize: "13px",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            Mapped
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            width: "30%",
                          }}
                        >
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="text.primary"
                              sx={{
                                color: "#808080",
                                fontSize: "20px",
                                lineHeight: "25px",
                              }}
                              component="h6"
                              fontWeight={500}
                            >
                              {count?.requirement_details?.candidate_count}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Link>
                  <Link
                    onClick={() => {
                      handleCandidateFilter(8);
                    }}
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: "1 0 auto",
                        padding: "10px !important",
                        backgroundColor:
                          candidateFilterValue === 8 ? "rgb(235 223 255)" : "",
                        borderRadius: candidateFilterValue === 8 ? "5px" : "",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography
                            component="div"
                            sx={{
                              color: "#53596F",
                              fontSize: "13px",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            Submission
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            width: "30%",
                          }}
                        >
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="text.primary"
                              sx={{
                                color: "#3498db",
                                fontSize: "20px",
                                lineHeight: "25px",
                              }}
                              component="h6"
                              fontWeight={500}
                            >
                              {filteredSubmissionCount}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Link>
                  <Link
                    onClick={() => {
                      handleCandidateFilter(2);
                    }}
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: "1 0 auto",
                        padding: "10px !important",
                        backgroundColor:
                          candidateFilterValue === 2 ? "rgb(235 223 255)" : "",
                        borderRadius: candidateFilterValue === 2 ? "5px" : "",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography
                            component="div"
                            sx={{
                              color: "#53596F",
                              fontSize: "13px",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            Interview
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            width: "30%",
                          }}
                        >
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="text.primary"
                              sx={{
                                color: "#3498db",
                                fontSize: "20px",
                                lineHeight: "25px",
                              }}
                              component="h6"
                              fontWeight={500}
                            >
                              {count?.requirement_details?.interview_count}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Link>
                  <Link
                    onClick={() => {
                      handleCandidateFilter(3);
                    }}
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: "1 0 auto",
                        padding: "10px !important",
                        backgroundColor:
                          candidateFilterValue === 3 ? "rgb(235 223 255)" : "",
                        borderRadius: candidateFilterValue === 3 ? "5px" : "",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography
                            component="div"
                            sx={{
                              color: "#53596F",
                              fontSize: "13px",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            Selected
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            width: "30%",
                          }}
                        >
                          {/* <BsFillPersonCheckFill style={{ fontSize: "24px", color: "#27ae60" }} /> */}
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="text.primary"
                              sx={{
                                color: "#27ae60",
                                fontSize: "20px",
                                lineHeight: "25px",
                              }}
                              component="h6"
                              fontWeight={500}
                            >
                              {count?.requirement_details?.selected_count}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Link>
                  <Link
                    onClick={() => {
                      handleCandidateFilter(4);
                    }}
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: "1 0 auto",
                        padding: "10px !important",
                        backgroundColor:
                          candidateFilterValue === 4 ? "rgb(235 223 255)" : "",
                        borderRadius: candidateFilterValue === 4 ? "5px" : "",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography
                            component="div"
                            sx={{
                              color: "#53596F",
                              fontSize: "13px",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            Onboard
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            width: "30%",
                          }}
                        >
                          {/* <TbDiscountCheckFilled style={{ fontSize: "24px", color: "#9b59b6" }} /> */}
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="text.primary"
                              sx={{
                                color: "#9b59b6",
                                fontSize: "20px",
                                lineHeight: "25px",
                              }}
                              component="h6"
                              fontWeight={500}
                            >
                              {count?.requirement_details?.onboard_count}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Link>
                  <Link
                    onClick={() => {
                      handleCandidateFilter(7);
                    }}
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: "1 0 auto",
                        padding: "10px !important",
                        backgroundColor:
                          candidateFilterValue === 7 ? "rgb(235 223 255)" : "",
                        borderRadius: candidateFilterValue === 7 ? "5px" : "",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography
                            component="div"
                            sx={{
                              color: "#53596F",
                              fontSize: "13px",
                              fontWeight: "500",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Rejected
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            width: "30%",
                          }}
                        >
                          {/* <BsFillClipboard2DataFill style={{ fontSize: "24px", color: "#f39c12" }} /> */}
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="text.primary"
                              sx={{
                                color: "#f39c12",
                                fontSize: "20px",
                                lineHeight: "25px",
                              }}
                              component="h6"
                              fontWeight={500}
                            >
                              {count?.requirement_details?.rejected_count}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Link>
                </Card>
              </Grid>
            </Grid>


            <Grid sx={{ background: "primary.main" }}>
              <Card sx={{ boxShadow: "none", border: "1px solid #ddd" }}>
                <Grid
                  sx={{
                    background: "primary.main",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="requirment-table">
                    <CommonTable
                      data={dataList}
                      columns={columns}
                      tableKey={"Requirement_Info"}
                      isLoading={isLoading}
                      // customizeHeader={tableHead}
                      defaultColumn={columnVisibilityModel}
                      pinColumn={["candidate_id"]}
                    />
                  </div>
                </Grid>
              </Card>
            </Grid>
          </Card>
        </div>
        <div className="chat-box flex-1 w-[28%] max-h-[calc(100vh-55px-1.5rem)]">
          {requirementTitle()}
        </div>
      </div>
      <Modal
        className="custom-modal"
        title="Select Candidate"
        open={open}
        onOk={handleOk}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            className="btn save-btn"
            onClick={handleOk}
            loading={submitLoader}
          >
            Save
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          form={cadidateMappingForm}
          onFinish={CandidateMapping}
          className="w-full px-2 py-2"
        >
          <div>
            <label
              className="block tracking-wide text-gray-700 text-sm font-bold mb-2"
              htmlFor="customerName"
            >
              Candidate :
            </label>
            <Form.Item
              style={{ width: "80%" }}
              name="Candidate"
              rules={[
                {
                  required: true,
                  message: "Please select the candidate",
                },
              ]}
            >
              <Select
                mode="multiple"
                size="large"
                placeholder="Select Candidate"
                options={filterCandidate.map((item) => ({
                  value:
                    item.candidate_id +
                    "-" +
                    item.firstname +
                    " " +
                    item.lastname,
                  label: item.firstname + " " + item.lastname,
                }))}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Modal className="Interview-popup"
        title="Set Interview Level"
        open={showCandidateModal}
        onOk={submitCandidateEditForm}
        maskClosable={false}
        onCancel={handleCandidateCancel}
        footer={[
          <div className="flex justify-between">
            {showSchudeledHistory < 3 && (
              <div className="flex gap-2">
                <Button
                  className={showSchudeledHistory === 1 ? "save-btn" : ""}
                  onClick={handleScheduledHistory}
                >
                  Scheduled History
                </Button>
                <Button
                  className={showSchudeledHistory === 2 ? "save-btn" : ""}
                  onClick={handleScheduleInterview}
                >
                  Schedule Interview
                </Button>
              </div>
            )}
            {showSchudeledHistory === 2 && (
              <div className="flex gap-2">
                <Button className="btn save-btn" onClick={handleCandidateCancel}>
                  Cancel
                </Button>
                <Button
                  className="btn save-btn"
                  onClick={submitCandidateEditForm}
                  loading={modalLoading}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        ]}
        width={1050}
      >
        <div className="flex items-center justify-between">
          <div
            style={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography
              component="div"
              sx={{ fontSize: "18px", lineHeight: "25px", }}
              fontWeight={500}
            >
              {reqList?.req_title}
            </Typography>
            <Typography>-</Typography>
            <Typography
              component="div"
              sx={{
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              {reqList?.customer}
            </Typography>
          </div>
          <div className="flex items-center gap-2">
            <p
              style={{
                fontSize: '12px',
                border: '1px solid #0e890e',
                background: '#82f28245',
                color: '#0e890e',
                padding: '3px 10px',
                borderRadius: '5px'
              }}
            >
              {
                reqList?.req_status == 1
                  ? 'Ongoing'
                  : reqList?.req_status == 2
                    ? 'Hold'
                    : reqList?.req_status == 3
                      ? 'Closed'
                      : 'Yet to Start'
              }
            </p>
            <p
              style={{
                fontSize: '12px',
                border: '1px solid #f17a25',
                background: '#f17a2533',
                color: '#f17a25',
                padding: '3px 10px',
                borderRadius: '5px'
              }}
            >
              {reqList?.priority}
            </p>
          </div>
        </div>
        <div className="mt-3 flex items-center gap-2">
          <div className="flex items-center gap-2">
            <p style={{ fontSize: '15px', fontWeight: '500' }}>Assigned to :</p>
            <p style={{ fontSize: '15px', fontWeight: '500' }}>
              {reqList?.assigned_to === 0
                ? "Unassigned"
                : (() => {
                  const foundItem = employeeLists.find(
                    (item) => item.emp_id === reqList?.assigned_to
                  );
                  return foundItem ? foundItem.label : "Unassigned";
                })()}
            </p>
          </div>
          <div style={{ fontSize: '14px', color: '#474d6a', fontWeight: '300' }}>|</div>
          <div className="flex items-center gap-2">
            <p style={{ fontSize: '15px', fontWeight: '500' }}>Candidate name :</p>
            <p style={{ fontSize: '15px', fontWeight: '500' }}> {candidateName.toLocaleUpperCase()}</p>
          </div>
        </div>
        {showSchudeledHistory === 1 && (
          <div className="requirment-table mt-3">
            <CommonTable
              data={interviewData}
              columns={interviewColumns}
              tableKey={"Interview_Info"}
              isLoading={isLoading}
              defaultColumn={{}}
              pinColumn={[]}
            />
          </div>
        )}
        {showSchudeledHistory === 2 && (
          <Form
            className="py-4 interview-form"
            form={candidateEditForm}
            onFinish={submitCandidateEditForm}
          >
            <div className="grid grid-cols-2 gap-x-5">
              <Form.Item
                name="level"
                className="custom-formItem"
                label="Interview Level"
                rules={[
                  {
                    required: true,
                    message: "Select the interview level",
                  },
                ]}
              >
                <Select
                  placeholder="Please select the level"
                  options={interviewLevelOptions}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                className="custom-formItem"
                name="interview_date"
                label="Interview Date"
                rules={[
                  {
                    required: true,
                    message: "Please select the date",
                  },
                ]}
              >
                <DatePicker
                  className="w-full"
                  size="large"
                  minDate={dayjs(new Date())}
                />
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-x-5">
              <Form.Item
                className="pt-2 custom-formItem"
                name="start_time"
                label="Start Time"
                rules={[
                  {
                    required: true,
                    message: "Select the start time of interview",
                  },
                ]}
              >
                <TimePicker
                  format="HH:mm"
                  style={{ width: '100%' }}
                  size="large"
                  needConfirm={false}
                />
              </Form.Item>
              <Form.Item
                className="pt-2 custom-formItem"
                name="end_time"
                label="End Time"
                rules={[
                  {
                    required: true,
                    message: "Select the end time of interview",
                  },
                ]}
              >
                <TimePicker
                  format="HH:mm"
                  style={{ width: '100%' }}
                  size="large"
                  needConfirm={false}
                />
              </Form.Item>
            </div>
          </Form>
        )}
        {showSchudeledHistory === 3 && (
          <Form
            className="pt-4 interview-form"
            form={interviewEditForm}
            onFinish={submitInterviewForm}
          >
            <div className="grid grid-cols-2 gap-x-5">
              <Form.Item
                className="custom-formItem"
                name="interview_status"
                label="Interview Status"
                rules={[
                  {
                    required: true,
                    message: "Select the interview status",
                  },
                ]}
              >
                <Select
                  placeholder="Please select the level"
                  options={interviewStsOptions}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                className="custom-formItem"
                name="interview_feedback"
                label="Feedback"
              >
                <TextArea placeholder="Enter your feedback here" allowClear />
              </Form.Item>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="candidate_id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="old_status" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="level_name" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="level_id" hidden>
                <Input />
              </Form.Item>
            </div>
            <div className="mt-4 flex justify-end gap-2">
              <Button
                className="btn save-btn"
                htmlType="button"
                onClick={() => {
                  interviewEditForm.resetFields();
                  setShowScheduledHistory(1);
                }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                className="btn save-btn"
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
}
