import axios from "axios";
export const staffingUrl = axios.create({
  ///////////////////////  Staffing LIVE     ////////////////////////////

  baseURL: "https://api.talent.jetzerp.com/",

  ///////////////////////   Staffing DEV    ///////////////////////////////

  // baseURL: "https://devjetztalentapi.jetzerp.com/",

});
