import React, { useEffect, useRef, useState, useCallback } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { TablePagination } from '@mui/material';
import { MenuItem } from '@mui/material';
import dayjs from "dayjs";
import { MdOutlineFileDownload } from "react-icons/md";
import { Select, Button } from "antd";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#6c5295",
    },
  },
});

export default function CommonTable({
  data,
  columns,
  tableKey,
  isLoading,
  customizeHeader,
  defaultColumn,
  pinColumn
  // HiddenColumnlist
}) {
  const [columnVisibility, setColumnVisibility] = useState(defaultColumn);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [showGlobalFilter, setShowGlobalFilter] = useState(false);
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { Option } = Select;

  const isFirstRender = useRef(true);

  const ExportButtonWithSelector = ({ table }) => {
    const [selectedVendor, setSelectedVendor] = useState('');

    const csvConfig = mkConfig({
      fieldSeparator: ',',
      decimalSeparator: '.',
      useKeysAsHeaders: true,
      headers: ['Job Title', 'Vendor Name', 'Closing Date', 'Assigned To', 'Requirement Status', 'YOE', 'Work Mode', 'Priority', 'Job Duration', 'Submission'],
      filename: `${selectedVendor}_Export_${dayjs().format('YYYY-MM-DD')}`,
    });

    const vendorOptions = [...new Set(table.getCoreRowModel().rows.map(row => row.original.customer))];

    const handleVendorChange = (value) => {
      setSelectedVendor(value);
    };

    const handleExportRows = useCallback(() => {
      const filteredRows = table.getCoreRowModel().rows.filter(row => row.original.customer === selectedVendor);

      const rowData = filteredRows.map((row) => {
        const originalData = row.original;
        let statusValue = '';

        switch (originalData.req_status) {
          case 1:
            statusValue = "Ongoing";
            break;
          case 2:
            statusValue = "Hold";
            break;
          case 3:
            statusValue = "Closed";
            break;
          case 4:
            statusValue = "Yet to Start";
            break;
          default:
            statusValue = "Selected";
            break;
        }

        return {
          'Job Title': originalData.req_title,
          'Vendor Name': originalData.customer,
          // 'Closing Date': dayjs(originalData.closed_at).format('YYYY-MM-DD'), 
          'Closing Date': originalData.closed_at,
          'Assigned To': originalData.assigned_to,
          'Requirement Status': statusValue,
          'YOE': originalData.yoe,
          'Work Mode': originalData.work_mode,
          'Priority': originalData.priority,
          'Job Duration': originalData.job_duration,
          'Submission': originalData.submission_count
        };

        // const transformedData = {};
        // for (const key in originalData) {
        //   if (Object.hasOwnProperty.call(originalData, key)) {
        //     const value = originalData[key];
        //     transformedData[key] = (value === null || ['number', 'string', 'boolean'].includes(typeof value))
        //       ? value
        //       : JSON.stringify(value);
        //   }
        // }
        // return transformedData;
      });

      const csv = generateCsv(csvConfig)(rowData);
      download(csvConfig)(csv);
    }, [selectedVendor, table]);

    return (
      <div style={{ display: 'flex', gap: '1rem', padding: '4px', width: "100%", justifyContent: 'start',position:'relative',top:'3.5rem' }}>
        {/* <span className="pt-2 text-[13px]">Vendor</span> */}
        <Select 
        showSearch
         className="w-[150px]"
          // value={selectedVendor}
          placeholder="Choose a vendor"
          onChange={handleVendorChange}
          size="large"
          style={{ height: '35px' }}
        >
          {vendorOptions.map((vendor) => (
            <Option key={vendor} value={vendor}>
              {vendor}
            </Option>
          ))}
        </Select>
        <Button
          className="flex gap-2 toolbutton"
          disabled={!selectedVendor}
          onClick={handleExportRows}
        >
          <MdOutlineFileDownload size="large" /> Export Rows
        </Button>
      </div>
    );
  };
  const table = useMaterialReactTable({
    columns: columns,
    data: data,
    enableGrouping: true,
    groupedColumnMode: false,
    enableTopToolbar:
      (tableKey !== "Requirement_Info"
        && tableKey !== 'Candidate_Info'
        && tableKey !== 'Interview_Info'
      )
        ? true
        : false,
    enableColumnResizing: true,
    enableColumnFilter: true,
    enableDensityToggle: false,
    enableColumnOrdering: true,
    autoResetPageIndex: false,
    enableFilterMatchHighlighting: false,
    paginateExpandedRows: false,
    positionToolbarAlertBanner: 'bottom',
    positionPagination:'both',
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onShowColumnFiltersChange: setShowColumnFilters,
    onShowGlobalFilterChange: setShowGlobalFilter,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    onPaginationChange: setPagination,
    globalFilterFn: 'startsWith',
    groupedColumnMode: false,
    layoutMode: "semantic",
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 50, 100],
    },
    muiTableBodyRowProps: () => ({
      sx: { cursor: "pointer" },
    }),
    initialState: {
      grouping: tableKey === 'Candidate_Report' ? ['candidate_name'] : [],
      // pagination: {
      //   pageIndex: 0,
      //   pageSize: 5
      // }
      pagination
    },
    state: {
      isLoading,
      density: "comfortable",
      // density: "compact",
      columnVisibility: defaultColumn,
      columnVisibility: columnVisibility,
      columnPinning: { right: pinColumn },
      columnFilters,
      globalFilter,
      sorting,
      showColumnFilters,
      showGlobalFilter,
      pagination,
      expanded: columnFilters?.length > 0 || !!globalFilter ? true : expanded
    },
    onColumnVisibilityChange: setColumnVisibility,
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <div sx={{ display: 'flex', gap: '1rem', p: '4px' }} key={1}>
          {customizeHeader}
        </div>
        {tableKey === 'Requirement_Dashboard' &&
          <ExportButtonWithSelector table={table} />
        }
      </>
    ),
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(even) > td': {
          // backgroundColor: '#f0f4f7',
          backgroundColor: '#f6f0ff',
        },
        '& tr > td': {
          // padding: '16px',
        },
        // '& tr:nth-of-type(1) > td': {
        //   border:'none'
        // },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        // borderRight: 'none',
        border: 'none',
        //add a border between columns
      },
    },
    muiTableHeadCellProps: {
      sx: (theme) => ({
        fontSize: '14px',
        fontWeight: 'bold',
        border: 'none',
        // backgroundColor: '#f0f4f7',
        backgroundColor: '#f6f0ff',
        //add a border between columns
      }),
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#ffff',
      }
    },
    muiTableFooterCellProps: {
      sx: {
        border: 'none',
      }
    }
  });

  const handleChangePage = (event, newPage) => {
    setPagination((prev) => ({ ...prev, pageIndex: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
      pageIndex: 0, // Reset to the first page
    }));
  };

  useEffect(() => {
    // if (table == "userdetails")
    sessionStorage.setItem(
      'mrt_columnVisibility_table_1',
      JSON.stringify(columnVisibility),
    );
  }, [columnVisibility]);

  useEffect(() => {
    if (isFirstRender.current) return;

    if (tableKey === 'Requirement_Dashboard') {
      sessionStorage.setItem(
        'RD_columnFilters_table',
        JSON.stringify(columnFilters),
      );
    } else if (tableKey === 'Employee_Dashboard') {
      sessionStorage.setItem(
        'EMP_columnFilters_table',
        JSON.stringify(columnFilters),
      );
    } else if (tableKey === 'Candidate_Dashboard') {
      sessionStorage.setItem(
        'CD_columnFilters_table',
        JSON.stringify(columnFilters),
      );
    }
  }, [columnFilters]);

  useEffect(() => {
    if (isFirstRender.current) return;

    if (tableKey === 'Requirement_Dashboard') {
      sessionStorage.setItem(
        'RD_globalFilter_table',
        JSON.stringify(globalFilter ?? '')
      );
    } else if (tableKey === 'Employee_Dashboard') {
      sessionStorage.setItem(
        'EMP_globalFilter_table',
        JSON.stringify(globalFilter ?? '')
      );
    } else if (tableKey === 'Candidate_Dashboard') {
      sessionStorage.setItem(
        'CD_globalFilter_table',
        JSON.stringify(globalFilter ?? '')
      );
    }
  }, [globalFilter]);

  useEffect(() => {
    if (isFirstRender.current) return;

    if (tableKey === 'Requirement_Dashboard') {
      sessionStorage.setItem('RD_sorting_table', JSON.stringify(sorting));
    } else if (tableKey === 'Employee_Dashboard') {
      sessionStorage.setItem('EMP_sorting_table', JSON.stringify(sorting));
    } else if (tableKey === 'Candidate_Dashboard') {
      sessionStorage.setItem('CD_sorting_table', JSON.stringify(sorting));
    }
  }, [sorting]);

  useEffect(() => {
    if (isFirstRender.current) return;

    if (tableKey === 'Requirement_Dashboard') {
      sessionStorage.setItem('RD_showGlobalFilter_table', JSON.stringify(showGlobalFilter));
    } else if (tableKey === 'Employee_Dashboard') {
      sessionStorage.setItem('EMP_showGlobalFilter_table', JSON.stringify(sorting));
    } else if (tableKey === 'Candidate_Dashboard') {
      sessionStorage.setItem('CD_showGlobalFilter_table', JSON.stringify(showGlobalFilter));
    }
  }, [showGlobalFilter]);

  useEffect(() => {
    if (isFirstRender.current) return;

    if (tableKey === 'Requirement_Dashboard') {
      sessionStorage.setItem('RD_showColumnFilters_table', JSON.stringify(showColumnFilters));
    } else if (tableKey === 'Employee_Dashboard') {
      sessionStorage.setItem('EMP_showColumnFilters_table', JSON.stringify(sorting));
    } else if (tableKey === 'Candidate_Dashboard') {
      sessionStorage.setItem('CD_showColumnFilters_table', JSON.stringify(showColumnFilters));
    }
  }, [showColumnFilters]);

  useEffect(() => {
    if (isFirstRender.current) return;

    if (tableKey === 'Requirement_Dashboard') {
      sessionStorage.setItem('RD_pagination_table', JSON.stringify(pagination));
    } else if (tableKey === 'Candidate_Report') {
      sessionStorage.setItem('CR_pagination_table', JSON.stringify(pagination));
    } else if (tableKey === 'Candidate_Dashboard') {
      sessionStorage.setItem('CD_pagination_table', JSON.stringify(pagination));
    }
  }, [pagination]);

  useEffect(() => {
    if (tableKey === 'Requirement_Dashboard') {
      const columnFilters = sessionStorage.getItem('RD_columnFilters_table');
      const globalFilter = sessionStorage.getItem('RD_globalFilter_table');
      const sorting = sessionStorage.getItem('RD_sorting_table');
      const showGlobalFilter = sessionStorage.getItem('RD_showGlobalFilter_table');
      const showColumnFilters = sessionStorage.getItem('RD_showColumnFilters_table');
      const pagination = sessionStorage.getItem('RD_pagination_table');

      if (columnFilters) {
        setColumnFilters(JSON.parse(columnFilters));
      }

      if (globalFilter) {
        setGlobalFilter(JSON.parse(globalFilter) || undefined);
      }

      if (sorting) {
        setSorting(JSON.parse(sorting));
      }

      if (showGlobalFilter) {
        setShowGlobalFilter(JSON.parse(showGlobalFilter));
      }

      if (showColumnFilters) {
        setShowColumnFilters(JSON.parse(showColumnFilters));
      }

      if (pagination) {
        setPagination(JSON.parse(pagination));
      }
    } else if (tableKey === 'Candidate_Report') {
      const pagination = sessionStorage.getItem('CR_pagination_table');

      if (pagination) {
        setPagination(JSON.parse(pagination));
      }
    } else if (tableKey === 'Candidate_Dashboard') {
      const columnFilters = sessionStorage.getItem('CD_columnFilters_table');
      const globalFilter = sessionStorage.getItem('CD_globalFilter_table');
      const sorting = sessionStorage.getItem('CD_sorting_table');
      const showGlobalFilter = sessionStorage.getItem('CD_showGlobalFilter_table');
      const showColumnFilters = sessionStorage.getItem('CD_showColumnFilters_table');
      const pagination = sessionStorage.getItem('CD_pagination_table');

      if (columnFilters) {
        setColumnFilters(JSON.parse(columnFilters));
      }

      if (globalFilter) {
        setGlobalFilter(JSON.parse(globalFilter) || undefined);
      }

      if (sorting) {
        setSorting(JSON.parse(sorting));
      }

      if (showGlobalFilter) {
        setShowGlobalFilter(JSON.parse(showGlobalFilter));
      }

      if (showColumnFilters) {
        setShowColumnFilters(JSON.parse(showColumnFilters));
      }

      if (pagination) {
        setPagination(JSON.parse(pagination));
      }
    } else if (tableKey === 'Employee_Dashboard') {
      const columnFilters = sessionStorage.getItem('EMP_columnFilters_table');
      const globalFilter = sessionStorage.getItem('EMP_globalFilter_table');
      const sorting = sessionStorage.getItem('EMP_sorting_table');
      const showGlobalFilter = sessionStorage.getItem('EMP_showGlobalFilter_table');
      const showColumnFilters = sessionStorage.getItem('EMP_showColumnFilters_table');
      const pagination = sessionStorage.getItem('EMP_pagination_table');

      if (columnFilters) {
        setColumnFilters(JSON.parse(columnFilters));
      }

      if (globalFilter) {
        setGlobalFilter(JSON.parse(globalFilter) || undefined);
      }

      if (sorting) {
        setSorting(JSON.parse(sorting));
      }

      if (showGlobalFilter) {
        setShowGlobalFilter(JSON.parse(showGlobalFilter));
      }

      if (showColumnFilters) {
        setShowColumnFilters(JSON.parse(showColumnFilters));
      }

      if (pagination) {
        setPagination(JSON.parse(pagination));
      }
    }
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    // if (table == "userdetails") {
    const columnVisibility = sessionStorage.getItem(
      'mrt_columnVisibility_table_1',
    );
    if (columnVisibility) {
      setColumnVisibility(JSON.parse(columnVisibility));
    }
    // }
  }, []);

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <div className={`patient-table ${tableKey === 'Candidate_Report' ? 'hideGroupBy' : ''}`}>
          <MaterialReactTable table={table} />
        </div>
      </ThemeProvider>
    </>
  );
}
