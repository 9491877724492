import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  Button,
  Form,
  notification,
} from "antd";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import { staffingUrl } from "../interceptor";
import { useMsal } from "@azure/msal-react";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useParams } from "react-router-dom";

export default function EmployeeForm({ setEmpPosition, setIsRegistered }) {
  const [api, contextHolder] = notification.useNotification();
  const { instance } = useMsal();
  const { username, name } = instance.getActiveAccount();

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [EmployeeForm] = Form.useForm();

  const [country_code, setCountry_Code] = useState("+91");
  const [phoneNumLength, setPhoneNumLength] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [empId, setEmpId] = useState(null);

  const disableEmail = location.state?.disableEmail || false;
  const resetFields = location.state?.resetFields || false;
  const emailList = location.state?.emploeeMails || [];
  const countryCode = ["+91", "+1", "+44", "+52", "+86", "+61"];

  const openNotificationWithIcon = (req) => {
    api[req?.color]({
      message: req?.Title,
      description: req?.description,
      duration: req?.duration,
    });
  };

  const handleKeyDown = (event) => {
    // Allow only numeric key presses
    if (
      !(
        // Allow numeric keys
        (
          (event.keyCode >= 48 && event.keyCode <= 57) ||
          // Allow numpad keys
          (event.keyCode >= 96 && event.keyCode <= 105) ||
          // Allow backspace, delete, tab, arrow keys, and other special keys
          event.keyCode === 8 || // Backspace
          event.keyCode === 9 || // Tab
          event.keyCode === 37 || // Left Arrow
          event.keyCode === 39 || // Right Arrow
          event.keyCode === 46
        ) // Delete
      )
    ) {
      event.preventDefault();
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    let req = {
      username: values.username,
      position: values.position,
      email: values.email_id,
      phone_no: `${country_code}${values.phone_number}`,
      logged_user: username,
      Emp_id: !!empId ? empId : 0
    };
    await staffingUrl
      .post(`api/StaffMgnt/addEmployee`, req)
      .then(async (res) => {
        if (res?.data?.status === 1) {
          EmployeeForm.resetFields();
          openNotificationWithIcon({
            color: "success",
            Title: "Success",
            description: res?.data?.msg === 'updated successfully'
              ? "The Employee is Updated successfully"
              : "The Employee is Added successfully",
            duration: 2,
          });
          if (values.email_id === username) {
            await staffingUrl
              .get(`api/StaffMgnt/getEmployeeDetails/${username}/`)
              .then((res) => {
                const EMP_POS = res?.data?.employeeDetails?.position;
                setEmpPosition(EMP_POS);
                setIsRegistered(true);
                setIsLoading(false);
                localStorage.setItem("current_emp_position",values.position)
                if ((EMP_POS == "Management Team" && disableEmail)
                  || EMP_POS !== "Management Team") {
                  setTimeout(() => {
                    navigate("/dashboard");
                  }, 2000);
                } else {
                  setTimeout(() => {
                    navigate("/employeedashboard");
                  }, 2000);
                }
              });
          } else {
            setIsLoading(false);
            !!id
              ? setTimeout(() => {
                navigate("/employeedashboard");
              }, 2000)
              : setTimeout(() => {
                navigate("/employeedashboard");
              }, 2000)
          }
        } else {
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "The Employee is not updated",
            duration: 2,
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false);
      });
  };

  const GetEmployeeDetails = async (email) => {
    await staffingUrl
      .get(`api/StaffMgnt/getEmployeeDetails/${email}/`)
      .then((res) => {
        if (res.data?.msg === "Employee doesn't exist") {
          setPopupText("Please Enroll your details before proceeding futher.")
        } else if (
          res.data?.employeeDetails?.phone_number === null
          || res.data?.employeeDetails?.phone_number === 'null'
        ) {
          setPopupText("Please Enroll your Phone number before proceeding futher.")
        } else {
          const {
            username,
            phone_number,
            Email_id,
            position,
            Emp_id
          } = res.data?.employeeDetails;

          setEmpId(Emp_id);
          EmployeeForm.setFieldValue("username", username);
          EmployeeForm.setFieldValue("phone_number", phone_number.split(countryCode.filter(item => phone_number.startsWith(item))[0])[1]);
          setCountry_Code(countryCode.filter(item => phone_number.startsWith(item))[0]);
          EmployeeForm.setFieldValue("email_id", Email_id);
          EmployeeForm.setFieldValue("position", position);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (resetFields) {
      EmployeeForm.resetFields();
    } else if (!id && id !== '') {
      EmployeeForm.setFieldValue("username", name);
      EmployeeForm.setFieldValue("phone_number", "");
      EmployeeForm.setFieldValue("email_id", username);
      EmployeeForm.setFieldValue("position", "");
      GetEmployeeDetails(username);
    } else {
      EmployeeForm.setFieldValue("username", name);
      EmployeeForm.setFieldValue("phone_number", "");
      EmployeeForm.setFieldValue("email_id", username);
      EmployeeForm.setFieldValue("position", "");
      GetEmployeeDetails(id);
    }
  }, []);

  useEffect(() => {
    if (
      country_code === "+91"
      || country_code === "+61"
      || country_code === "+1"
      || country_code === "+44"
      || country_code === "+52"
    ) {
      setPhoneNumLength(10);
    } else if (country_code === "+86") {
      setPhoneNumLength(11);
    }
  }, [country_code]);

  return (
    <>
      {contextHolder}
      <div>
        <div className="flex justify-center">
          {popupText.length > 0 && (
            <div className="alert-box flex items-center gap-5">
              <BsExclamationCircleFill className="text-[#c25654] relative top-0 text-[14px]" />
              <p className="text-[#c25654] font-medium text-[14px]">
                {popupText}
              </p>
            </div>
          )}
        </div>
        <div className="text-left">
          <h4 className="text-[#6c5295] text-lg font-medium">Employee</h4>
        </div>
        <Box className="tab-section" sx={{ width: "100%" }}>
          <div
            style={{
              border: "1px solid #c3c3c7",
              borderRadius: "10px",
              marginTop: "20px",
            }}
          >
            <Form
              layout="vertical"
              onFinish={(values) => {
                handleSubmit(values);
              }}
              form={EmployeeForm}
              className="w-full px-2 py-2"
            >
              <div className="md:flex md:flex-wrap py-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <Form.Item
                    name="username"
                    label="Username"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Username",
                      },
                    ]}
                  >
                    <Input
                      className="w-full"
                      size="large"
                      placeholder="Enter your Username"
                      maxLength={35}
                    />
                  </Form.Item>
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <Form.Item
                    name="phone_number"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Phone Number",
                      },
                      {
                        pattern: /^[\d\-()+\s]+$/,
                        message: "Invalid phone number format",
                      },
                      {
                        max: 17,
                        message: "Invalid phone number",
                      },
                      // {
                      //   min: phoneNumLength,
                      //   message: "Invalid phone number",
                      // }
                    ]}
                  >
                    <Input
                      className="w-full"
                      size="large"
                      onKeyDown={handleKeyDown}
                      // maxLength={phoneNumLength}
                      maxLength={17}
                      addonBefore={
                        <Select
                          defaultValue="+91"
                          style={{ width: 70 }}
                          options={countryCode.map((a) => {
                            return {
                              value: a,
                              lable: a,
                            };
                          })}
                          onChange={e => setCountry_Code(e)}
                          value={country_code}
                        />
                      }
                      placeholder="Enter your Phone Number"
                    />
                  </Form.Item>
                </div>
                {/* <div className="md:w-1/2 px-3">
                  <Form.Item
                    name="lname"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your last name",
                      },
                    ]}
                  >
                    <Input
                      className="w-full"
                      size="large"
                      placeholder="Enter your Last Name"
                    />
                  </Form.Item>
                </div> */}
              </div>
              <div className="md:flex md:flex-wrap py-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                  <Form.Item
                    name="email_id"
                    label="Email Id"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Email Id",
                      },
                      {
                        type: "email",
                        message: "Invalid Email",
                      },
                      {
                        validator: (rule, value) => {
                          return new Promise((resolve, reject) => {
                            const findMail = emailList.find(
                              (item) => item === value.toLowerCase()
                            )
                            if (findMail) {
                              reject('User already exists');
                            } else {
                              resolve();
                            }
                          })
                        }
                      }
                    ]}
                  >
                    <Input
                      disabled={disableEmail}
                      className="w-full"
                      size="large"
                      placeholder="Enter your Email Id"
                      maxLength={35}
                    />
                  </Form.Item>
                </div>
                <div className="md:w-1/2 px-3">
                  <Form.Item
                    name="position"
                    label="Position"
                    rules={[
                      {
                        required: true,
                        message: "Please select your Position",
                      },
                    ]}
                  >
                    <Select
                      // allowClear={true}
                      className="w-full"
                      size="large"
                      placeholder="Select Position"
                      options={[{
                        value: "Talent Team",
                        label: "Talent Team",
                      },
                      {
                        value: "Management Team",
                        label: "Management Team",
                      },
                      {
                        value: "Internal Team",
                        label: "Internal Team",
                      }]}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="flex justify-center mt-5 mb-5 gap-3">
                <span></span>
                <Button
                  className="btn save-btn"
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Submit
                </Button>
                <button
                  className="btn close-btn"
                  onClick={() => {
                    (!!id || resetFields)
                      ? navigate("/employeedashboard")
                      : navigate("/dashboard")
                  }}
                >
                  Back
                </button>
              </div>
            </Form>
          </div>
        </Box>
      </div>
    </>
  );
}
