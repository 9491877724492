import React, { useState, useEffect, useMemo, useRef } from "react";
import CommonTable from "./CommonTable";
import { Select, Form, Button, Modal, Badge, notification } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { MdEditNote, MdDelete, MdContentCopy, MdOutlineDoneAll } from "react-icons/md";
import { IoMdPersonAdd } from "react-icons/io";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { staffingUrl } from "../interceptor";
import * as moment from "moment";
import { useNavigate, Link, useLocation } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import { FaListCheck } from "react-icons/fa6";
import { TailSpin } from "react-loader-spinner";
import { FaPaperPlane } from "react-icons/fa";
import { GiDiscussion } from "react-icons/gi";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { TbDiscountCheckFilled } from "react-icons/tb";
import { BsFillClipboard2DataFill } from "react-icons/bs";
import { useMsal } from "@azure/msal-react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { Tooltip } from 'antd'

function RequirementDashboard() {
  const { instance } = useMsal();
  const { state } = useLocation();
  const { filterKey, filterValue, dateRange, fromKey } = state || {};
  const { RangePicker } = DatePicker;
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();

  const initialDateRange = {
    startDate: !!dateRange
      ? dateRange.startDate
      : !!sessionStorage.getItem('requirementDateRange')
        ? JSON.parse(sessionStorage.getItem('requirementDateRange'))["startDate"]
        : null, // dayjs().add(-7, "d").format("DD-MM-YYYY"),
    endDate: !!dateRange
      ? dateRange.endDate
      : !!sessionStorage.getItem('requirementDateRange')
        ? JSON.parse(sessionStorage.getItem('requirementDateRange'))["endDate"]
        : null //dayjs().format("DD-MM-YYYY")
  };

  const accDetails = instance.getActiveAccount();
  const userName = accDetails["username"];
  const columnVisibilityModel = {
    s_no: false,
    customer: true,
    req_title: true,
    created_date: true,
    submission_count: true,
    interview_count: true,
    selected_count: true,
    onboard_count: true,
    purchaseorder_count: true,
    closed_at: true,
    job_duration: true,
    assigned_to: true,
    priority: true,
    req_status: true,
    req_id: true,
    work_mode: true,
    openings: true,
    location: false,
    country: false,
    state: false,
    city: false,
    customer_email: false,
    contact: false,
    Department: false,
    integrated_with: false
  };

  const navigate = useNavigate();
  const inputRefs = useRef([]);

  const [cadidateMappingForm] = Form.useForm();

  const [isloading, setIsloading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [globalDataList, setGlobalDataList] = useState([]);
  const [candidate, setCandidate] = useState([]);
  const [filterCandidate, setFilterCandidate] = useState([]);
  const [open, setOpen] = useState(false);
  const [reqList, setReqList] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [requirementFilterValue, setRequirementFilterValue] = useState("");
  const [count, setCount] = useState({});
  const [countfromDashboard, setCountfromDashboard] = useState({})
  const [reqDateRange, setReqDateRange] = useState(initialDateRange);
  const [departmentData, setDepartmentData] = useState([]);
  const [copied, setCopied] = useState({});
  const [isFilterClicked, setIsFilterClicked] = useState(false);

  const openNotificationWithIcon = (req) => {
    api[req?.color]({
      message: req?.Title,
      description: req?.description,
      duration: req?.duration,
    });
  };

  const options = [
    { label: "Yet to Start", value: 4 },
    { label: "Ongoing", value: 1 },
    { label: "Hold", value: 2 },
    { label: "Closed", value: 3 },
  ];

  function getLabelByValue(value) {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        return options[i].label;
      }
    }
    return null; // Return null if value is not found
  }

  const filterRequirement = (val, key) => {
    setRequirementFilterValue(key);
    let temp;
    if (key === "") {
      temp = globalDataList;
    } else {
      temp = globalDataList.filter((item) => val?.includes(item.req_id));
    }
    setDataList(temp);
  };

  const Loader = <TailSpin visible={true} height="30" width="50" radius="1" />;

  const showModal = (val) => {
    // cadidateMappingForm.setFieldValue("Skill_set", val?.split(",").map(a => a));
    cadidateMappingForm.setFieldValue("Candidate", undefined);
    candidateFilter(val);
    setOpen(true);
  };

  const handleOk = () => {
    cadidateMappingForm.submit();
  };

  const handleCancel = () => {
    setOpen(false);
    setFilterCandidate([]);
  };

  const showConfirm = (req_id) => {
    confirm({
      title: "Warning",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure want to delete this Requirement?",
      onOk() {
        handleDeleteRequirement(req_id);
      },
      onCancel() { },
    });
  };

  const GetTableData = async (startDate, endDate) => {
    setIsloading(true);
    const formattedStartDate = !!startDate
      ? dayjs(startDate).format("DD-MM-YYYY")
      : "all";
    const formattedEndDate = !!endDate
      ? dayjs(endDate).format("DD-MM-YYYY")
      : "all";
    await staffingUrl
      .get(`api/StaffMgnt/getCandidatesList`)
      .then((res) => {
        setCandidate(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    await staffingUrl
      .get(`api/StaffMgnt/getRequirementDashboardInfo/0/${formattedStartDate}/${formattedEndDate}`) //getRequirementDashboardInfo
      .then((res) => {
        setCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    await staffingUrl
      .get(`api/StaffMgnt/getDashboardInfo/0/${!!startDate ? startDate : "all"}/${!!endDate ? endDate : "all"}`) //getRequirementDashboardInfo
      .then((res) => {
        setCountfromDashboard(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    let employeeDetail;
    await staffingUrl
      .get(`api/StaffMgnt/getEmployees/0`)
      .then((res) => {
        employeeDetail = res?.data
          ?.filter((a) => a.position === 'Talent Team')
          .map((a) => ({
            value: a.Emp_id,
            label: a.username,
          }));

        setEmployees(employeeDetail);
      })
      .catch((err) => {
        console.log(err);
      });
    await staffingUrl
      .get(`api/StaffMgnt/getRequirementsList/${formattedStartDate}/${formattedEndDate}`)
      .then((res) => {
        const data = res.data.req_list;
        const uniqueDepartment = [...new Set(data?.map(item => item.Department))]?.filter(item => item);
        setDepartmentData(uniqueDepartment);

        const structuredData = res.data.req_list?.map((data, index) => {
          // Check if any of the values in 'data' is null or empty string, and replace them with "-"
          const newData = Object.fromEntries(
            Object.entries(data).map(([key, value]) => [
              key,
              (key === 'closed_at'
                || key === 'created_date'
                || key === 'updated_date')
                && value
                ? dayjs(value.split("T")[0]).format("DD-MM-YYYY")
                : key === 'assigned_to'
                  ? value === 0
                    ? "Unassigned"
                    : !!employeeDetail.find(item => item.value === value)?.label
                      ? employeeDetail.find(item => item.value === value).label
                      : "Unassigned"
                  : value === null || value === "" ? "-" : value,
            ])
          );
          return { ...newData, s_no: index + 1 };
        });

        setDataList(structuredData);
        setGlobalDataList(structuredData);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsloading(false);
  };

  const getDataAfterUpdate = async () => {
    const { startDate, endDate } = reqDateRange;
    setIsloading(true);
    await staffingUrl
      .get(`api/StaffMgnt/getRequirementDashboardInfo/0/${startDate}/${endDate}`)
      .then((res) => {
        setCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    await staffingUrl
      .get(`api/StaffMgnt/getDashboardInfo/0/${startDate}/${endDate}`) //getRequirementDashboardInfo
      .then((res) => {
        setCountfromDashboard(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    await staffingUrl
      .get(`api/StaffMgnt/getRequirementsList/${startDate}/${endDate}`)
      .then((res) => {
        // setDataList(res.data.req_list.reverse().map((data, index) => { return { ...data, s_no: index + 1 } }));
        setDataList(
          res.data.req_list?.map((data, index) => {
            // Check if any of the values in 'data' is null or empty string, and replace them with "-"
            const newData = Object.fromEntries(
              Object.entries(data).map(([key, value]) => [
                key,
                value === null || value === "" ? "-" : value,
              ])
            );
            return { ...newData, s_no: index + 1 };
          })
        );
        setGlobalDataList(
          res.data.req_list?.map((data, index) => {
            // Check if any of the values in 'data' is null or empty string, and replace them with "-"
            const newData = Object.fromEntries(
              Object.entries(data).map(([key, value]) => [
                key,
                value === null || value === "" ? "-" : value,
              ])
            );
            return { ...newData, s_no: index + 1 };
          })
        );
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsloading(false);
  };

  const handleStatusChange = async (value, row, index) => {
    let req = {
      req_id: row.req_id,
      status: value,
      username: userName,
    };
    await staffingUrl
      .post(`api/StaffMgnt/changeRequirementstatus`, req)
      .then((res) => {
        if (res.data.status === 1) {
          openNotificationWithIcon({
            color: "success",
            Title: "Success",
            description: "Status updated successfully",
            duration: 2,
          });
          getDataAfterUpdate();
        } else {
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "Status not updated",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteRequirement = async (req_id) => {
    await staffingUrl
      .post(
        `api/StaffMgnt/deleteRequirement?req_id=${req_id}&username=${userName}`
      )
      .then((res) => {
        if (res.data.status === 1) {
          openNotificationWithIcon({
            color: "success",
            Title: "Success",
            description: "Requirement deleted successfully",
            duration: 2,
          });
          // getDataAfterUpdate();
          GetTableData(reqDateRange.startDate, reqDateRange.endDate);
        } else {
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "Requirement deleted failed",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const candidateFilter = (val) => {
    let temp = candidate;
    // ?.filter(candidate => {
    //   if (candidate?.skill_set) {
    //     const skills = candidate?.skill_set.split(",");
    //     return skills.some(skill => values?.includes(skill.trim()));
    //   }
    //   return false;
    // }
    // );
    temp = temp?.filter(
      (candidate) => !val?.mapped_candidates?.includes(candidate?.candidate_id)
    );
    setReqList(val?.req_id);
    setFilterCandidate(temp);
    if (temp.length == 0) {
      cadidateMappingForm.setFieldValue("Candidate", undefined);
    }
  };

  const CandidateMapping = async (values, req_id) => {
    let templist = candidate?.filter((data) => {
      return values?.Candidate.some(
        (a) => a.split("-")[0] === data?.candidate_id.toString()
      );
    });
    let req = {
      req_id: req_id,
      candidate_details: templist,
      username: userName,
    };
    await staffingUrl
      .post(`api/StaffMgnt/mapCandidateToReq`, req)
      .then((res) => {
        if (res.data.status === 1) {
          openNotificationWithIcon({
            color: "success",
            Title: "Success",
            description: "Candidate added successfully",
            duration: 2,
          });
          getDataAfterUpdate();
          setOpen(false);
        } else {
          openNotificationWithIcon({
            color: "warning",
            Title: "Warning",
            description: "Candidate not added successfully",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAssignToChange = async (value, row, index) => {
    if (value == 0) {
      let req = {
        req_id: row.req_id,
        emp_id: value,
        username: userName
      };
      await staffingUrl
        .post(`api/StaffMgnt/assignEmployee`, req)
        .then((res) => {
          if (res.data.status === 1) {
            openNotificationWithIcon({
              color: "success",
              Title: "Success",
              description: "Updated successfully",
              duration: 2,
            });
            getDataAfterUpdate();
          } else {
            openNotificationWithIcon({
              color: "warning",
              Title: "Warning",
              description: "Failed to update",
              duration: 2,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let req_status = parseInt(row.req_status);
      let req = {
        req_id: row.req_id,
        emp_id: value,
        req_title: row.req_title,
        assigned_by: accDetails?.name,
        req_priority: row.priority,
        req_status: getLabelByValue(req_status),
        req_customer: row.customer,
        req_department: row.Department,
        assigned_username: userName
      };
      await staffingUrl
        .post(`api/StaffMgnt/assignEmployeewithReminder`, req)
        .then((res) => {
          if (res.data.status === 1) {
            openNotificationWithIcon({
              color: "success",
              Title: "Success",
              description: "Updated successfully",
              duration: 2,
            });
            getDataAfterUpdate();
          } else {
            openNotificationWithIcon({
              color: "warning",
              Title: "Warning",
              description: "Failed to update",
              duration: 2,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setReqDateRange({
        startDate: dateStrings[0],
        endDate: dateStrings[1]
      });
    } else {
      setReqDateRange(initialDateRange);
    }
  };

  const copyText = (req_id) => {
    const input = inputRefs.current[req_id];
    if (input) {
      input.select();
      document.execCommand('copy');
      setCopied((prev) => ({ ...prev, [req_id]: true }));
      setTimeout(() => {
        setCopied({});
      }, 2000);
    }
  };

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs().add(0, "d"), dayjs()],
    },
    {
      label: "Yesterday",
      value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
    },
    {
      label: "This Week",
      value: [dayjs().startOf('week'), dayjs()],
    },
    {
      label: "Last Week",
      value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
    },
    {
      label: "This Month",
      value: [dayjs().startOf('month'), dayjs().endOf('day')],
    },
    {
      label: "Last Month",
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: "This Year",
      value: [dayjs().startOf('year'), dayjs().endOf('day')],
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "s_no",
        header: "S.No",
        enableSorting: false,
        enableColumnFilter: false,
        size: 20,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "created_date",
        header: "Created Date",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        sortingFn: (rowA, rowB) => {
          const dateA = (!!rowA.original.created_date && rowA.original.created_date !== "-")
            ? dayjs(rowA.original.created_date, 'DD-MM-YYYY')
            : "-";
          const dateB = (!!rowB.original.created_date && rowB.original.created_date !== "-")
            ? dayjs(rowB.original.created_date, 'DD-MM-YYYY')
            : "-";
          return dateA - dateB;
        },
        Cell: ({ row }) => {
          return (
            <span>
              {row.original.created_date}
            </span>
          );
        },
      },
      {
        accessorKey: "req_title",
        header: "Job Title",
        size: 200,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          return (
            <>
              <div
                onClick={() => {
                  sessionStorage.setItem("SelectedFilterCard", requirementFilterValue);
                  sessionStorage.setItem("isFilterClicked", isFilterClicked);
                  sessionStorage.setItem('requirementDateRange', JSON.stringify(reqDateRange));
                  navigate(`/requirement/info/${row.original.req_id}`);
                }}
                style={{
                  color: "#6c5295",
                  fontWeight: "500",
                  // cursor:
                  //   row.original.req_status != 3 ? "pointer" : "not-allowed",
                }}
              >
                {row.original.req_title}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "customer",
        header: "Vendor Name",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey:
          requirementFilterValue === "purchase_order_req"
            ? "purchaseorder_count"
            : requirementFilterValue === "interview_req"
              ? "interview_count"
              : requirementFilterValue === "selected_req"
                ? "selected_count"
                : requirementFilterValue === "onboard_req"
                  ? "onboard_count"
                  : "submission_count",
        header:
          requirementFilterValue === "purchase_order_req"
            ? "Purchase Order"
            : requirementFilterValue === "interview_req"
              ? "Interview"
              : requirementFilterValue === "selected_req"
                ? "Selected"
                : requirementFilterValue === "onboard_req"
                  ? "Onboard"
                  : "Submission",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        // Cell: ({ row }) => {
        //   return (
        //     <span>{moment(new Date(row.original.closed_at)).format('DD-MM-YYYY')}</span>
        //   );
        // },
      },
      {
        accessorKey: "closed_at",
        header: "Closing Date",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        sortingFn: (rowA, rowB) => {
          const dateA = (!!rowA.original.closed_at && rowA.original.closed_at !== "-")
            ? dayjs(rowA.original.closed_at, 'DD-MM-YYYY')
            : "-";
          const dateB = (!!rowA.original.closed_at && rowA.original.closed_at !== "-")
            ? dayjs(rowB.original.closed_at, 'DD-MM-YYYY')
            : "-";
          return dateA - dateB;
        },
        Cell: ({ row }) => {
          return (
            <span>
              {row.original.closed_at}
            </span>
          );
        },
      },
      {
        accessorKey: "assigned_to",
        header: "Assigned To",
        size: 180,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        // filterVariant: "select",
        filterSelectOptions: employees.map(item => {
          return (
            {
              label: "Select",
              value: "",
            },
            {
              label: "Unassigned",
              value: "Unassigned",
            },
            {
              label: item.label,
              value: item.label,
            }
          )
        }),
      },
      {
        accessorKey: "req_status",
        header: "Status",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: "select",
        filterSelectOptions: [
          {
            label: "Select",
            value: "",
          },
          {
            label: "Ongoing",
            value: 1,
          },
          {
            label: "Hold",
            value: 2,
          },
          {
            label: "Closed",
            value: 3,
          },
          {
            label: "Yet to Start",
            value: 4,
          },
        ],
        Cell: ({ row }) => (
          // <Select
          //   className="w-full"
          //   size="small"
          //   placeholder="Select Status"
          //   value={row.original.req_status} // Bind value to the req_status of the row
          //   onChange={(value) =>
          //     handleStatusChange(value, row.original, row.index)
          //   } // Bind onChange to handleStatusChange passing the value and row index
          // >
          //   {options.map((option) => (
          //     <Select.Option key={option.value} value={option.value}>
          //       {option.label}
          //     </Select.Option>
          //   ))}
          // </Select>
          <p className="text-normal text-sm">
            {options.map(item => {
              if (item.value === row.original.req_status) {
                return item.label;
              }
            })
            }
          </p>
        ),
      },
      {
        accessorKey: "yoe",
        header: "YOE",
        size: 80,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "Department",
        header: "Department",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: departmentData?.map(item => ({
          label: item,
          value: item,
        }))
      },
      {
        accessorKey: "work_mode",
        header: "Work Mode",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: 'select',
        filterSelectOptions: [
          {
            "label": 'On-Site',
            'value': 'On-Site'
          },
          {
            "label": 'Hybrid',
            'value': 'Hybrid'
          },
          {
            "label": 'Remote',
            'value': 'Remote'
          },
        ]
      },
      {
        accessorKey: "openings",
        header: "Openings",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        // Cell: ({ row }) => {
        //   return (
        //     <span>{moment(new Date(row.original.closed_at)).format('DD-MM-YYYY')}</span>
        //   );
        // },
      },
      {
        accessorKey: "job_duration",
        header: "Job Duration",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "priority",
        header: "Priority",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: "select",
        filterSelectOptions: [
          {
            label: "Select",
            value: "",
          },
          {
            label: "Low",
            value: "Low",
          },
          {
            label: "Medium",
            value: "Medium",
          },
          {
            label: "High",
            value: "High",
          },
        ],

        Cell: ({ row }) => (
          <Chip
            label={row.original.priority}
            size="small"
            sx={{
              fontWeight: 300,
              minWidth: 70,
              fontSize: 11,
              color: "white",
              bgcolor:
                row.original.priority === "Low"
                  ? "rgb(0, 204, 0)"
                  : row.original.priority === "Medium"
                    ? "#ffa500ed"
                    : "rgb(255, 51, 51)",
            }}
            small
          />
        ),
      },
      {
        accessorKey: "country",
        header: "Country",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "state",
        header: "State",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "city",
        header: "City",
        size: 100,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "contactPersonEmail",
        header: "Email",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "contactPersonNo",
        header: "Phone Number",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <p className="text-normal text-sm text-center">
            {row.original.contactPersonNo || "-"}
          </p>
        )
      },
      {
        accessorKey: "req_id",
        header: "Requirement Id",
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 100,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "integrated_with",
        header: "Associated With",
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 100,
        muiTableBodyCellProps: {
          align: "center",
        }
      },
      {
        accessorKey: "req_url",
        header: "URL",
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 100,
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: "select",
        filterSelectOptions: [
          {
            label: "JETZ",
            value: "JETZ",
          },
          {
            label: "RCK",
            value: "RCK",
          },
        ],
        filterFn: (row, id, filterValue) => {
          return row.getValue('integrated_with').startsWith(filterValue)
        },
        Cell: ({ row }) => (
          <div className="flex gap-2 items-center">
            <span className="inline-block w-15">
              {row.original.integrated_with}
            </span>
            {(row.original.integrated_with !== "-" && row.original.req_url !== "-") && (
              copied[row.original.req_id]
                ? (
                  <MdOutlineDoneAll />
                )
                : (
                  <MdContentCopy
                    onClick={() => copyText(row.original.req_id)}
                    className="cursor-pointer"
                  />
                )
            )}
            <input
              type="text"
              value={row.original.req_url}
              style={{ opacity: 0 }}
              ref={el => inputRefs.current[row.original.req_id] = el}
              className="absolute -top-full"
            />
          </div>
        )
      },
      {
        accessorKey: "action",
        header: "Action",
        enableSorting: false,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 100,
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          return (
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <IoMdPersonAdd
                title="Add candidate"
                onClick={() => {
                  sessionStorage.setItem("SelectedFilterCard", requirementFilterValue)
                  if (row.original.req_status != 3) {
                    showModal(row.original);
                  }
                }}
                style={{
                  color: "#00CC00",
                  fontSize: "16px",
                  cursor:
                    row.original.req_status != 3 ? "pointer" : "not-allowed",
                }}
              /> */}
              <Tooltip title="Edit" placement="bottom">
                <MdEditNote
                  onClick={() => {
                    sessionStorage.setItem('requirementDateRange', JSON.stringify(reqDateRange));
                    sessionStorage.setItem("SelectedFilterCard", requirementFilterValue);
                    sessionStorage.setItem("isFilterClicked", isFilterClicked);
                    navigate(`/requirement/${row.original.req_id}`);
                  }}
                  style={{ color: "#3366FF", fontSize: "18px" }}
                />
              </Tooltip>
              <Tooltip title="Delete" placement="bottom">
                <MdDelete
                  onClick={() => {
                    showConfirm(row.original.req_id);
                  }}
                  style={{ color: "#FF3333", fontSize: "16px" }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [dataList, copied]
  );

  const tableHead = [
    <>
      <Button 
      style={{position:'relative',top:'3.5rem'}}
        variant="contained"
        className="toolbutton"
        component="label"
        size="large"
        onClick={() => {
          sessionStorage.setItem("isFilterClicked", isFilterClicked);
          sessionStorage.setItem("SelectedFilterCard", requirementFilterValue)
          sessionStorage.setItem('requirementDateRange', JSON.stringify(reqDateRange));
          navigate("/requirement");
        }}
      >
        <AddCircleOutlineIcon className="icontool" /> Create Requirement
      </Button>
    </>,
  ];

  useEffect(() => {
    if (!!countfromDashboard?.requirement_details
      && !!count?.requirement_details
      && globalDataList?.length > 0
    ) {
      const fltValue = sessionStorage.getItem("SelectedFilterCard");
      if (!!fltValue) {
        setRequirementFilterValue(fltValue);
        if (isFilterClicked
          || sessionStorage.getItem("isFilterClicked") === "true"
        ) {
          filterRequirement(
            count?.requirement_details[fltValue] || "",
            fltValue
          );
        } else {
          if (fltValue == "high_priority_req") {
            console.log(countfromDashboard?.highPriorityDetails[fltValue],
              fltValue);
            filterRequirement(
              countfromDashboard?.highPriorityDetails[fltValue],
              fltValue
            );
          } else {
            filterRequirement(
              countfromDashboard?.requirement_details[fltValue]
              || "",
              fltValue
            );
          }
        }
      } else {
        setRequirementFilterValue("");
        if (requirementFilterValue == "high_priority_req") {
          filterRequirement(
            countfromDashboard?.highPriorityDetails[requirementFilterValue],
            requirementFilterValue
          );
        } else {
          if (isFilterClicked
            || sessionStorage.getItem("isFilterClicked") === "true"
          ) {
            filterRequirement(
              count?.requirement_details[requirementFilterValue] || "",
              requirementFilterValue
            );
          } else {
            filterRequirement(
              countfromDashboard?.requirement_details[requirementFilterValue]
              || "",
              requirementFilterValue
            );
          }
        }
      }
      sessionStorage.removeItem("SelectedFilterCard");
    }
  }, [count, countfromDashboard, globalDataList]);

  useEffect(() => {
    !!filterKey && filterRequirement(filterValue, filterKey);
  }, [filterValue, filterKey]);

  useEffect(() => {
    const { startDate, endDate } = reqDateRange;
    GetTableData(startDate, endDate);
  }, [reqDateRange]);

  useEffect(() => {
    !fromKey
      && filterRequirement(
        !!count?.requirement_details
        && count?.requirement_details[requirementFilterValue] || "",
        (requirementFilterValue === "high_priority_req"
          || requirementFilterValue === "ongoing_req"
          || requirementFilterValue === "hold_count_req"
          || requirementFilterValue === "yettostart_req"
        )
          ? ""
          : requirementFilterValue || ""
      );
  }, [fromKey]);

  return (
    <>
      {contextHolder}
      <div className="text-left ml-5 flex justify-between items-center">
        <div className="flex gap-2">
          <h4 className="text-[17px] font-normal">
            {!!fromKey
              ? (
                <span>
                  <span
                    className="cursor-pointer hover:opacity-50"
                    onClick={() => navigate('/dashboard')}
                  >
                    Dashboard
                  </span>
                  {` > ${fromKey}`}
                </span>
              )
              : "Requirement Dashboard"
            }
          </h4>
        </div>
        <div className="flex" style={{ marginRight: "1em" }}>
          <Space direction="vertical" size={12}>
            <RangePicker
              defaultValue={[null, null]}
              presets={rangePresets}
              onChange={onRangeChange}
              allowClear={false}
              value={[
                !!reqDateRange?.startDate
                  ? dayjs(reqDateRange?.startDate)
                  : null,
                !!reqDateRange?.endDate
                  ? dayjs(reqDateRange?.endDate)
                  : null
              ]}
            // className="rounded-full"
            />
          </Space>
        </div>
      </div>
      {/* <Card sx={{ padding: "1em", paddingBottom: "1em", boxShadow: "none" }}>
        <Grid sx={{ background: "primary.main" }} container spacing={2}>
          <Grid item xs={12} md={2}>
            <Card sx={{ display: "block" }}>
              <Link
                onClick={() => {
                  setIsFilterClicked(true);
                  filterRequirement("", "");
                }}
                style={{ textDecoration: "none" }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    paddingBottom: "16px",
                    backgroundColor:
                      requirementFilterValue === "" ? "rgb(235 223 255)" : "",
                    borderRadius: requirementFilterValue === "" ? "5px" : "",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <Typography
                        component="div"
                        sx={{
                          color: "#53596F",
                          fontSize: "13px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Total Opening
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        width: "60%",
                      }}
                    >
                      <FaListCheck
                        style={{ fontSize: "24px", color: "#0AA699" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#0AA699",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={500}
                        >
                          {isloading === true ? Loader : count?.requirement_details?.req_count || 0}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Link>
            </Card>
          </Grid>
          <Grid item xs={12} md={10}>
            <Card
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignContent: "stretch",
                justifyContent: "space-between",
              }}
            >
              <Link
                to="/candidatedashboard"
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardContent sx={{ flex: "1 0 auto", paddingBottom: "16px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <Typography
                        component="div"
                        sx={{
                          color: "#53596F",
                          fontSize: "13px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Candidates
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        width: "60%",
                      }}
                    >
                      <IoMdPersonAdd
                        style={{ fontSize: "24px", color: "#808080" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#808080",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={500}
                        >
                          {isloading === true
                            ? Loader
                            : countfromDashboard?.candidateDetails?.candidate_count || 0
                          }
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Link>
              <Link
                onClick={() => {
                  setIsFilterClicked(true);
                  filterRequirement(count?.requirement_details.submitted_req, "submitted_req");
                }}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    paddingBottom: "16px",
                    backgroundColor:
                      requirementFilterValue === "submitted_req"
                        ? "rgb(235 223 255)"
                        : "",
                    borderRadius:
                      requirementFilterValue === "submitted_req" ? "5px" : "",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <Typography
                        component="div"
                        sx={{
                          color: "#53596F",
                          fontSize: "13px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Submission
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        width: "60%",
                      }}
                    >
                      <FaPaperPlane
                        style={{ fontSize: "18px", color: "#001F3F" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#001F3F",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={500}
                        >
                          {isloading === true ? Loader : count?.requirement_details?.submission_count || 0}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Link>
              <Link
                onClick={() => {
                  setIsFilterClicked(true);
                  filterRequirement(count?.requirement_details.interview_req, "interview_req");
                }}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    paddingBottom: "16px",
                    backgroundColor:
                      requirementFilterValue === "interview_req"
                        ? "rgb(235 223 255)"
                        : "",
                    borderRadius:
                      requirementFilterValue === "interview_req" ? "5px" : "",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <Typography
                        component="div"
                        sx={{
                          color: "#53596F",
                          fontSize: "13px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Interview
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        width: "60%",
                      }}
                    >
                      <GiDiscussion
                        style={{ fontSize: "24px", color: "#3498db" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#3498db",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={500}
                        >
                          {isloading === true ? Loader : count?.requirement_details?.interview_count || 0}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Link>
              <Link
                onClick={() => {
                  setIsFilterClicked(true);
                  filterRequirement(count?.requirement_details.selected_req, "selected_req");
                }}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    paddingBottom: "16px",
                    backgroundColor:
                      requirementFilterValue === "selected_req"
                        ? "rgb(235 223 255)"
                        : "",
                    borderRadius:
                      requirementFilterValue === "selected_req" ? "5px" : "",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <Typography
                        component="div"
                        sx={{
                          color: "#53596F",
                          fontSize: "13px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Selected
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        width: "60%",
                      }}
                    >
                      <BsFillPersonCheckFill
                        style={{ fontSize: "24px", color: "#27ae60" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#27ae60",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={500}
                        >
                          {isloading === true ? Loader : count?.requirement_details?.selected_count || 0}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Link>
              <Link
                onClick={() => {
                  setIsFilterClicked(true);
                  filterRequirement(count?.requirement_details.onboard_req, "onboard_req");
                }}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    paddingBottom: "16px",
                    backgroundColor:
                      requirementFilterValue === "onboard_req"
                        ? "rgb(235 223 255)"
                        : "",
                    borderRadius:
                      requirementFilterValue === "onboard_req" ? "5px" : "",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <Typography
                        component="div"
                        sx={{
                          color: "#53596F",
                          fontSize: "13px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Onboard
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        width: "60%",
                      }}
                    >
                      <TbDiscountCheckFilled
                        style={{ fontSize: "24px", color: "#9b59b6" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#9b59b6",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={500}
                        >
                          {isloading === true ? Loader : count?.requirement_details?.onboard_count || 0}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Link>
              <Link
                onClick={() => {
                  setIsFilterClicked(true);
                  filterRequirement(
                    count?.requirement_details.purchase_order_req,
                    "purchase_order_req"
                  );
                }}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    paddingBottom: "16px",
                    backgroundColor:
                      requirementFilterValue === "purchase_order_req"
                        ? "rgb(235 223 255)"
                        : "",
                    borderRadius:
                      requirementFilterValue === "purchase_order_req"
                        ? "5px"
                        : "",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <Typography
                        component="div"
                        sx={{
                          color: "#53596F",
                          fontSize: "13px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Purchase Order
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        width: "60%",
                      }}
                    >
                      <BsFillClipboard2DataFill
                        style={{ fontSize: "24px", color: "#f39c12" }}
                      />
                      <div>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{
                            color: "#f39c12",
                            fontSize: "20px",
                            lineHeight: "25px",
                          }}
                          component="h6"
                          fontWeight={500}
                        >
                          {isloading === true
                            ? Loader
                            : count?.requirement_details?.purchase_order_count || 0}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Link>
            </Card>
          </Grid>
        </Grid>
        <Grid sx={{ background: "primary.main" }}>
          <Card
            sx={{ display: "flex", flexDirection: "column", paddingTop: "0em" }}
          ></Card>
        </Grid>
      </Card> */}
      <Card sx={{ padding: "1em", paddingBottom: "2em", boxShadow: "none" }}>
        <CommonTable
          data={dataList}
          columns={columns}
          tableKey={"Requirement_Dashboard"}
          isLoading={isloading}
          customizeHeader={tableHead}
          defaultColumn={columnVisibilityModel}
          pinColumn={[
            "submission_count",
            "interview_count",
            "selected_count",
            "onboard_count",
            "purchaseorder_count",
            "action",
          ]}
        />
      </Card>
      <Modal className="custom-popup"
        title="Select Candidate"
        open={open}
        onOk={handleOk}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" className="btn save-btn" onClick={handleOk}>
            Save
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          form={cadidateMappingForm}
          onFinish={(values) => {
            CandidateMapping(values, reqList);
          }}
          className="w-full px-2 py-2"
        >
          <div className="md:w-full px-3 mb-4">
            <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2">
              Candidate :
            </label>
            <Form.Item
              name="Candidate"
              rules={[
                {
                  required: true,
                  message: "Please select your Candidate",
                },
              ]}
            >
              <Select
                mode="multiple"
                size="large"
                placeholder="Select Candidate"
                options={filterCandidate.map((item) => ({
                  value:
                    item.candidate_id +
                    "-" +
                    item.firstname +
                    " " +
                    item.lastname,
                  label: item.firstname + " " + item.lastname,
                }))}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default RequirementDashboard;
